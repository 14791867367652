import React from 'react';
import {withRouter} from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import {callAPI, userData} from "../../Commen/CommenFunction";
import noDataImg from '../../Assets/images/no-data.png';
import $ from "jquery";
import DataTable from "react-data-table-component";
import {Trans, withTranslation} from "react-i18next";

class CreatorList extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            CreatorList:[],
            shortlist:[],
            page:1,
            countPerPage:10
        };
    }

    async componentDidMount() {
        let shortlist_id = this.props.match.params.shortlist_id;
        if (!shortlist_id) {
            window.location.href = '/shortlist';
        }
       await this.CreatorList()
       $('.rdt_TableHeader').remove();
    }

    async inputChangeHandler(event, key) {
        const value = event.target.value;

        this.setState(state => {
            return {
                ...state,
                [key]: value
            }
        })
    }

    async CreatorList() {
        try {
            const form = new FormData()
            form.set('shortlist_id', this.props.match.params.shortlist_id)

            const response = await callAPI('user/getShortlistCreators', 'post', form, 'application/json');
            if (response.status === true) {
                this.setState({
                    CreatorList: response.data.creatorLists,
                    shortlist: response.data.shortLists,
                });
            } else {
                this.setState({
                    CreatorList: [],
                });
            }
            this.setState({
                loading: false
            });
        } catch (e) {
            this.setState({
                loading: false
            });
        }
    }

    async removeFromList(row) {
        try {
            const form = new FormData()
            form.set('creator_id', row.userlist_id)
            form.set('shortlist_id', row.shortlist_id)

            const response = await callAPI('user/removeFromList', 'post', form, 'application/json');
            if (response.status === true) {
               await this.CreatorList();
            }
            this.setState({
                loading: false
            });
        } catch (e) {
            this.setState({
                loading: false
            });
        }
    }

    render() {
        const {t} = this.props;
        const columns = [
            {
                name: t('Name'),
                selector: 'name',
            },
            {
                name: t('Username'),
                selector: 'username',
            },
            {
                name: ' ',
                cell: row => <div className="icon-tools">
                    <div className="share-icon">
                        <button type="button" className="share-btn" onClick={() => this.removeFromList(row)}><Trans>Remove</Trans></button>
                    </div>
                </div>,
            },
        ];
        return (
           <>
               <Header />
               <div className="content-wrapper">
                   <div className="wrapper-inner">
                       <section className="shortlist-wrapper">
                           <div className="container">
                               <div className="shortlist-head shortlist-card-panel">
                                   <div className="shortlist-head-bread">
                                       <div className="shortlist-head-left">
                                           <div className="brand-back"><a href={'/shortlist'}><i className="fa fa-angle-left"></i></a></div>
                                           <div className="bread-nav">{this.state.shortlist.name}</div>
                                           <div className="shortlist-head-other">{this.state.shortlist.creator_count} <Trans>Creator(s)</Trans></div>
                                       </div>
                                       <div className="shortlist-head-right rst01">
                                           <div className="d-flex" style={{margin:"0px"}}>
                                               <div>
                                                   <Trans>Creation time</Trans>:
                                                   {this.state.shortlist.created_at}
                                               </div>
                                               <div className="shortlist-splitline">|</div>
                                               <div>
                                                   <Trans>Update time</Trans>:
                                                   {this.state.shortlist.updated_at}
                                               </div>
                                               <div className="share-icon ml-2">
                                                   <button type="button" className="share-btn" data-toggle="modal"
                                                           data-target="#share"><i className="fa fa-share-square-o"></i>
                                                   </button>
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                                   <div className="shortlist-edit-info">
                                       <div className="edit-brief-info">
                                           <p>{this.state.shortlist.description}</p>
                                       </div>
                                   </div>
                               </div>
                               {this.state.CreatorList.length && this.state.CreatorList.length > 0 ?
                                   <div className="shortlist-card-panel">
                                       <div className="main-table">
                                           <DataTable
                                               columns={columns}
                                               data={this.state.CreatorList}
                                               highlightOnHover
                                               pagination
                                               paginationServer
                                               paginationTotalRows={this.state.CreatorList.length}
                                               paginationPerPage={this.state.countPerPage}
                                               paginationComponentOptions={{
                                                   noRowsPerPage: true
                                               }}
                                               className='table shortlist-table'
                                               onChangePage={page => this.setPage(page)}
                                           />
                                       </div>
                                   </div>
                                   :
                               <div className="shortlist-card-panel">
                                   <div className="no-data">
                                       <img src={noDataImg} alt="img" />
                                           <p className="desc">
                                               <Trans>This list has no creators. Please go to the Creator Marketplace to search for creators to add.</Trans>
                                           </p>
                                       <a href={'/home'} className="btn orange-btn"><Trans>Go to the Creator Marketplace</Trans></a>
                                   </div>
                               </div>
                                   }

                           </div>
                       </section>
                   </div>
               </div>
               <div className="kurafat-modal">
                   <div className="modal fade" id="share" tabIndex="-1" role="dialog"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                       <div className="modal-dialog" role="document">
                           <div className="modal-content">
                               <div className="modal-header">
                                   <h5 className="modal-title"><Trans>Share shortlist</Trans>ABC</h5>
                                   <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                       <span aria-hidden="true">&times;</span>
                                   </button>
                               </div>
                               <div className="modal-body">
                                   <form action="#!">
                                       <div className="form-group">
                                           <p className="message"><Trans>Share with this Creator Marketplace user</Trans> <span
                                               data-toggle="tooltip" data-placement="bottom"
                                               title={t("The TCM user ID can be found under account settings")}>
                                               <i className="fa fa-question-circle-o"></i></span>
                                           </p>
                                           <input type="text" name="id" id="user-id" className="form-control userid" placeholder={t("Enter user ID")} />
                                       </div>
                                   </form>
                               </div>
                               <div className="modal-footer">
                                   <button type="button" className="btn gray-btn" data-dismiss="modal"><Trans>Cancel</Trans></button>
                                   <button type="button" className="btn orange-btn"><Trans>Share</Trans></button>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>

               <Footer />
           </>
        )
    }
}

export default withTranslation() (withRouter(CreatorList));