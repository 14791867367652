import React from 'react';
import {withRouter} from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import {callAPI, userData} from "../../Commen/CommenFunction";
import shortListImg from '../../Assets/images/shortlist-img.png';
import $ from "jquery";
import DataTable from "react-data-table-component";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {Trans, withTranslation} from "react-i18next";

class Shortlist extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            shortList:[],
            creatorList:[],
            selectedId:null,
            name:'',
            description:'',
            page:1,
            countPerPage:10,
            addCreator:[]
        };
    }

    async componentDidMount() {
       await this.getShortlist(this.state.page)
        $('.rdt_TableHeader').remove();
    }

    async inputChangeHandler(event, key) {
        const value = event.target.value;
        this.setState(state => {
            return {
                ...state,
                [key]: value
            }
        })
    }
    async searchCreatorList(event) {
        const value = event.target.value;
        await this.getCreatorList(value)
    }

    async selectChangeHandler(event, value) {
        this.setState({
            addCreator: value,
        });
    }

    async getCreatorList(username) {
        try {
            const form = new FormData()
            form.set('username', username)

            const response = await callAPI('user/creatorListByUsername', 'post', form, 'application/json');

            if (response.status === true) {
                this.setState({
                    creatorList: response.data,
                });
            } else {
                this.setState({
                    creatorList: [],
                });
            }
            this.setState({
                loading: false
            });
        } catch (e) {
            this.setState({
                loading: false
            });
        }
    }

    async getShortlist(page) {
        try {
            const form = new FormData()
            form.set('page', page)

            const response = await callAPI('user/getShortlist', 'post', form, 'application/json');

            if (response.status === true) {
                this.setState({
                    shortList: response.data,
                });
            } else {
                this.setState({
                    shortList: [],
                });
            }
            this.setState({
                loading: false
            });
        } catch (e) {
            this.setState({
                loading: false
            });
        }
    }

    async submitHandler(event) {
        event.preventDefault()
        var formData =$('.needs-validation')[0];
        if (!formData.checkValidity()) {
            formData.classList.add('was-validated')
            return;
        }

        this.setState({
            loading:true
        });

        const form = new FormData()
        form.set('name', this.state.name)
        form.set('id', this.state.selectedId ? this.state.selectedId : '')
        form.set('description', this.state.description)

        try {
            const response = await callAPI('user/createShortlist', 'post', form,'application/json');
            if (response.status === true) {
                window.location.reload();
            }
            this.setState({
                loading:false
            });
        } catch (e) {
            this.setState({
                loading:false
            });
        }
    }
    async submitCreatorList() {
        if (this.state.creatorList.length == 0) {
            alert('Please select');
            return;
        }
        let user_id = '';
        for (const el of this.state.addCreator) {
            if (!user_id){
                user_id = el.id;
            } else {
                user_id = user_id+','+el.id;
            }
        }


        const form = new FormData()
        form.set('id', this.state.selectedId)
        form.set('user_id', user_id)

        try {
            const response = await callAPI('user/addCreatorToList', 'post', form,'application/json');
            if (response.status === true) {
                await this.getShortlist(1)
                $('.close-list-modal').trigger('click');
            }
            this.setState({
                loading:false
            });
        } catch (e) {
            this.setState({
                loading:false
            });
        }
    }

    async deleteShortlist() {
        this.setState({
            loading:true
        });
        const form = new FormData()
        form.set('id', this.state.selectedId ? this.state.selectedId : '')
        try {
            const response = await callAPI('user/deleteShortlist', 'post', form,'application/json');
            if (response.status === true) {
                await this.getShortlist(1)
                $('.close-list-modal').trigger('click');
            } else {
                alert(response.message)
            }
            this.setState({
                loading:false
            });
        } catch (e) {
            this.setState({
                loading:false
            });
        }
    }

    async setPage(page){
        await this.getShortlist(page)
    }

    async selectedList(list) {
        this.setState({
            selectedId:list.id,
            name:list.name,
            description:list.description,
        });
    }

    async addCreator(list) {
        this.setState({
            selectedId:list.id,
            name:list.name,
            description:list.description,
        });
        await this.getCreatorList('')
    }

    async clearList() {
        this.setState({
            selectedId:null,
            name:'',
            description:'',
        });
    }

    render() {
        const {t} = this.props;
        const columns = [
            {
                name: t('Name'),
                selector: 'name',
            },
            {
                name: t('Description'),
                selector: 'description',
            },
            {
                name: t('Creators'),
                selector: 'creator_count',
            },
            {
                name: t('Update Time'),
                selector: 'updated_at',
            },
            {
                name: t('Create Time'),
                selector: 'created_at',
            },
            {
                name: ' ',
                cell: row => <div className="icon-tools">
                    <div className="share-icon">
                        <button type="button" className="share-btn" onClick={() => this.addCreator(row)} data-toggle="modal" data-target="#share"><i
                            className="fa fa-share-square-o"></i></button>
                    </div>
                    <div className="ellipse-icon dropdown">
                        <a className="nav-link" href="#" role="button" data-toggle="dropdown" aria-haspopup="true"
                           aria-expanded="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                <path
                                    d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                            </svg>
                        </a>
                        <div className="dropdown-menu">
                            <div className="help-container">
                                <ul className="help-list">
                                    <li className="help-item"><a href={'/creatorlist/'+row.id} className="help-link"><Trans>Creator List</Trans></a></li>
                                    <li className="help-item"><a href="#_" onClick={() => this.selectedList(row)} className="help-link" data-toggle="modal"
                                                                 data-target="#newList"><Trans>Edit List</Trans></a></li>
                                    <li className="help-item"><a href="#_" onClick={() => this.selectedList(row)} className="help-link" data-toggle="modal"
                                                                 data-target="#listDelete"><Trans>Delete</Trans></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>,
            },
        ];
        return (
           <>
               <Header />
               <div className="content-wrapper">
                   <div className="wrapper-inner">
                       <section className="shortlist-wrapper">
                           <div className="container">
                               <div className="shortlist-head shortlist-card-panel">
                                   <div className="shortlist-head-bread">
                                       <div className="shortlist-head-left">
                                           <div className="bread-nav"><Trans>Shortlists</Trans></div>
                                           <div className="shortlist-head-other">{this.state.shortList.total_record}</div>
                                       </div>
                                       <div className="shortlist-head-right">
                                           <button type="button" className="btn orange-btn" data-toggle="modal"
                                                   data-target="#newList" onClick={() => this.clearList()}><span className="fa fa-plus"></span> <Trans>Create a new list</Trans>
                                           </button>
                                       </div>
                                   </div>
                               </div>
                               {this.state.shortList.total_record && this.state.shortList.total_record > 0 ?
                                   <div className="shortlist-card-panel">
                                       <div className="main-table">
                                           <DataTable
                                               columns={columns}
                                               data={this.state.shortList.data}
                                               highlightOnHover
                                               pagination
                                               paginationServer
                                               paginationTotalRows={this.state.shortList.total_record}
                                               paginationPerPage={this.state.countPerPage}
                                               paginationComponentOptions={{
                                                   noRowsPerPage: true
                                               }}
                                               className='table shortlist-table'
                                               onChangePage={page => this.setPage(page)}
                                           />
                                       </div>
                                   </div>
                                   :
                                   <div className="shortlist-body shortlist-card-panel">
                                       <div className="left-container">
                                           <div className="left-title"><Trans>Use lists to track and share creators.</Trans></div>
                                           <div className="left-info"><Trans>You can create separate lists for different purposes and activities, and enter notes for each creator. Use lists to track potential collaborators with greater ease and efficiency.</Trans>
                                           </div>
                                           <button type="button" className="btn orange-btn" data-toggle="modal"
                                                   data-target="#newList"><Trans>Create a new list</Trans>
                                           </button>
                                       </div>
                                       <div className="right-container"><img src={shortListImg} alt="img"/></div>
                                   </div>
                               }
                           </div>
                       </section>
                   </div>
               </div>
               <div className="kurafat-modal">
                   <div className="modal fade" id="newList" tabIndex="-1" role="dialog" aria-labelledby="newListLabel"
                        aria-hidden="true">
                       <div className="modal-dialog" role="document">
                           <form method="post" className="create-campaign-form needs-validation" noValidate={true} onSubmit={(event) => this.submitHandler(event)}>
                               <div className="modal-content">
                                   <div className="modal-header">
                                       <h5 className="modal-title"><Trans>New list</Trans></h5>
                                       <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                           <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                       <form action="#!">
                                           <div className="form-group">
                                               <label htmlFor="name"><Trans>List name</Trans></label>
                                               <input type="text" name="name" id="list-name"
                                                      value={this.state.name}
                                                      onChange={(event) => this.inputChangeHandler(event, 'name')}
                                                      required={true}
                                                      className="form-control"
                                                      placeholder={t("Enter a name for the list")} />
                                           </div>
                                           <div className="form-group">
                                               <label htmlFor="name"><Trans>List description</Trans></label>
                                               <input type="text" name="escription" id="escription"
                                                      value={this.state.description}
                                                      onChange={(event) => this.inputChangeHandler(event, 'description')}
                                                      required={true}
                                                      className="form-control"
                                                      placeholder={t("Enter a description for the list")} />
                                           </div>
                                       </form>
                                   </div>
                                   <div className="modal-footer">
                                       <a className="btn gray-btn add-list-modal" data-dismiss="modal"><Trans>Cancel</Trans></a>
                                       <button type="submit" className="btn orange-btn"><Trans>Save</Trans></button>
                                   </div>
                               </div>
                           </form>
                       </div>
                   </div>
               </div>
               <div className="kurafat-modal">
                   <div className="modal fade" id="listDelete" tabIndex="-1" role="dialog"
                        aria-labelledby="listDeleteLabel" aria-hidden="true">
                       <div className="modal-dialog" role="document">
                           <div className="modal-content">
                               <div className="modal-header">
                                   <h5 className="modal-title"><Trans>Are you sure you want to delete this list?</Trans></h5>
                                   <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                       <span aria-hidden="true">&times;</span>
                                   </button>
                               </div>
                               <div className="modal-body">
                                   <p><Trans>Your creator selections will not be saved after you delete this list.</Trans></p>
                               </div>
                               <div className="modal-footer">
                                   <button type="button" className="btn gray-btn close-list-modal" data-dismiss="modal"><Trans>Cancel</Trans></button>
                                   <button type="button" className="btn orange-btn" onClick={() => this.deleteShortlist()}><Trans>Confirm</Trans></button>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>

               <div className="kurafat-modal">
                   <div className="modal fade" id="share" tabIndex="-1" role="dialog"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                       <div className="modal-dialog" role="document">
                           <div className="modal-content">
                               <div className="modal-header">
                                   <h5 className="modal-title"><Trans>Share shortlist</Trans> {this.state.name}</h5>
                                   <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                       <span aria-hidden="true">&times;</span>
                                   </button>
                               </div>
                               <div className="modal-body">
                                   <form action="#!">
                                       <div className="form-group">
                                           <p className="message"><Trans>Share with this Creator Marketplace user</Trans> <span
                                               data-toggle="tooltip" data-placement="bottom"
                                               title={t("The TCM user ID can be found under account settings")}><i
                                               className="fa fa-question-circle-o"></i></span></p>
                                           <Autocomplete
                                               multiple
                                               onChange={(event, value) => this.selectChangeHandler(event, value)}
                                               id="tags-standard"
                                               options={this.state.creatorList}
                                               getOptionLabel={(option) => option.name}
                                               renderInput={(params) => (
                                                   <TextField
                                                       {...params}
                                                       variant="standard"
                                                       label="User Name"
                                                       placeholder={t("Username")}
                                                       onChange={(event) => this.searchCreatorList(event)}
                                                   />
                                               )}
                                           />
                                       </div>
                                   </form>
                               </div>
                               <div className="modal-footer">
                                   <button type="button" className="btn gray-btn close-list-modal" data-dismiss="modal"><Trans>Cancel</Trans></button>
                                   <button type="button" className="btn orange-btn" onClick={() => this.submitCreatorList()}><Trans>Share</Trans></button>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>

               <Footer />
           </>
        )
    }
}

export default withTranslation() (withRouter(Shortlist));