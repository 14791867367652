import React from 'react';
import {withRouter} from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import guideImg from '../../Assets/images/guidence-img.svg';
import marketEmpImg from '../../Assets/images/market-empty-card.png';
import backImg from '../../Assets/images/back-img1.jpeg';
import {callAPI, userData} from "../../Commen/CommenFunction";
import $ from 'jquery';
import {Trans, withTranslation} from "react-i18next";

class Market extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            search: null,
            page: 1,
            not_verified: true,
            next_page: true,
            city_name:'',
            followers:'',
            views:'',
            audience_city:'',
            gender:'',
            age_group:'',
            device:'',
            selected_user:[],
            shortList:[],
            checked_shortlist:[],
            CampaignList:[],
            checked_campaign:[],
        };
    }

    async componentDidMount() {
        await this.getCreatorList(this.state.page)
    }

    async saveCreatorToList(type) {
        try {
            const form = new FormData()
            form.set('user_id', this.state.selected_user.id)
            let response = '';

            if (type == 'shortlist') {
                let shortlist = this.state.checked_shortlist.toString();
                form.set('shortlists', shortlist)
                response = await callAPI('user/addListToCreator', 'post', form, 'application/json');
            } else {
                let campaign = this.state.checked_campaign.toString();
                form.set('campaign', campaign)
                response = await callAPI('user/addCampaignToCreator', 'post', form, 'application/json');
            }

            if (response.status === true) {
                $('.close-list-modal').trigger('click');
            }
            this.setState({
                loading: false
            });
        } catch (e) {
            this.setState({
                loading: false
            });
        }
    }
    async getCreatorList(page) {
        try {
            const form = new FormData()
            form.set('city_name', this.state.city_name)
            form.set('followers', this.state.followers)
            form.set('views', this.state.views)
            form.set('audience_city', this.state.audience_city)
            form.set('gender', this.state.gender)
            form.set('age_group', this.state.age_group)
            form.set('device', this.state.device)
            form.set('search', this.state.search)
            form.set('page', page)

            const response = await callAPI('user/creatorList', 'post', form, 'application/json');

            if (response.status === true) {
                if (this.state.page == 1) {
                    this.state.users = [];
                }
                if (response.data.total_page <= this.state.page || response.data.data.length < 12) {
                    this.setState({
                        next_page: false,
                    });
                } else {
                    this.setState({
                        page: this.state.page + 1,
                        not_verified: response.data.not_verified
                    });
                }

                response.data.data.forEach(el => {
                    this.state.users.push(el)
                });
                this.setState({
                    users: this.state.users,
                    not_verified: response.data.not_verified
                });

            } else {
                this.setState({
                    users: [],
                });
            }
            this.setState({
                loading: false
            });
        } catch (e) {
            console.log(e);
            this.setState({
                loading: false
            });
        }
    }

    async inputChangeHandler(event, key) {
        const value = event.target.value;

        this.setState(state => {
            return {
                ...state,
                [key]: value
            }
        })
        this.setState({
            page: 1
        });
    }

    async checkCheckbox(event, type) {
        const checked = event.target.checked;
        let value = event.target.value;
        if (checked) {
           this.state[type].push(value);
            this.setState({
                [type]: this.state[type]
            });
        } else {
            const newArr = this.state[type].filter(e => e !== value)
            this.setState({
                [type]: newArr
            });
        }
    }

    async filterCreatorList() {
        this.setState({
            page: 1
        });
        await this.getCreatorList(1);
        $('.search-footer-modal').trigger('click');
    }
    async nextPage() {
        await this.getCreatorList(this.state.page)
    }
    async followerFilter(value) {
        this.setState({
            followers:value
        });
    }
    async viewFilter(value) {
        this.setState({
            views:value
        });
    }
    async genderFilter(value) {
        this.setState({
            gender:value
        });
    }
    async ageFilter(value) {
        this.setState({
            age_group:value
        });
    }
    async deviceFilter(value) {
        this.setState({
            device:value
        });
    }
    async addUserToList(user) {
        this.setState({
            selected_user:user
        });
        await this.getShortlist(user.id)
    }
    async addUserToCampaign(user) {
        this.setState({
            selected_user:user
        });
        await this.getCampaignList(user.id)
    }
    async getShortlist(user_id) {
        try {
            const form = new FormData()
            form.set('user_id', user_id)

            const response = await callAPI('user/getShortlistForCreator', 'post', form, 'application/json');

            if (response.status === true) {
                this.setState({
                    shortList: response.data,
                });
            } else {
                this.setState({
                    shortList: [],
                });
            }
            this.setState({
                loading: false
            });
        } catch (e) {
            this.setState({
                loading: false
            });
        }
    }

    async getCampaignList(user_id) {
        try {
            const form = new FormData()
            form.set('user_id', user_id)

            const response = await callAPI('user/getCampaignForCreator', 'post', form, 'application/json');

            if (response.status === true) {
                this.setState({
                    CampaignList: response.data,
                });
            } else {
                this.setState({
                    CampaignList: [],
                });
            }
            this.setState({
                loading: false
            });
        } catch (e) {
            this.setState({
                loading: false
            });
        }
    }


    render() {
        if (userData.status == 0){
           $('.account-activate-modal .modal').show();
        }
        $('.close-activate-modal').on('click', function (){
            $('.account-activate-modal .modal').hide();
        })
        const {t} = this.props;
        return (
            <>
                <Header/>
                <div className="content-wrapper">
                    <div className="wrapper-inner">
                        <section className="market-guidence">
                            <div className="container">
                                <div className="guidence-inner">
                                    <div className="left-container">
                                        <div className="left-title"><Trans>Verify your business</Trans></div>
                                        { userData.status == 0 &&
                                            <>
                                                <p className="left-info"><Trans>Before you create a campaign, you’ll need to verify your business.</Trans></p>
                                                <div className="left-btn"><a href={'/account'}
                                                                             className="btn orange-btn"><Trans>Activate the account</Trans></a></div>
                                            </>
                                        }
                                        { userData.status == 2 &&
                                        <p className="left-info"><Trans>Your Profile is Under Approval</Trans></p>
                                        }
                                        { userData.status == 1 &&
                                        <p className="left-info"><Trans>Your Profile has been Approved</Trans></p>
                                        }
                                    </div>
                                    <div className="right-container"><img src={guideImg} alt="img"/>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="market-search">
                            <div className="container">
                                <div className="search-bar">
                                    <div className="search-bar-inner">
                                        <input type="text" className="form-control input-search" name="search"
                                               id="search"
                                               placeholder={t("Try searching lipstick")}
                                               onChange={(event) => this.inputChangeHandler(event, 'search')}/>
                                        <span className="input-icon"><i className="fa fa-search"></i></span>
                                        <span className="search-btn">
	 <button type="submit" className="btn btn-submit orange-btn"
             onClick={() => this.filterCreatorList()}><Trans>Search</Trans></button>
	</span>
                                    </div>
                                </div>
                                <div className="market-search-filter">
                                    <div className="filter-item">
                                        <select name="city_name" onChange={(event) => this.inputChangeHandler(event, 'city_name')} className="form-control" >
                                            <option value="">{t("City Name")}</option>
                                            <option value="">{t("All")}</option>
                                            <option selected={this.state.city_name == 'Jaipur'} value="Jaipur">Jaipur</option>
                                        </select>
                                    </div>

                                    {/*<div className="filter-item">
                                        <div className="dropdown" id="navbarDropdowntopic" data-toggle="dropdown"
                                             aria-haspopup="true"
                                             aria-expanded="false">
                                            <a className="nav-link dropdown-arrow dropdown-trigger" href="#">
                                                <span className="trigger-placeholder"> Topic </span> <span
                                                className="fa fa-angle-down"></span>
                                            </a>

                                            <div className="dropdown-menu">
                                                <div className="dropdown-title">Topic</div>
                                                <div className="dropdown-topic-list">
                                                    <header className="topic-list-header position-relative">
                                                        <div className="topic-tags-input">
                                                            <ul data-placeholder="Select "
                                                                className="topic-tags-list"></ul>
                                                        </div>
                                                    </header>
                                                    <div className="topic-list-container">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>*/}

                                    <div className="filter-item">
                                        <select name="followers" onChange={(event) => this.inputChangeHandler(event, 'followers')} className="form-control" >
                                            <option value="">{t("Followers")}</option>
                                            <option value="">{t("All")}</option>
                                            <option value="0-10000">0-10k</option>
                                            <option value="10000-100000">10k-100k</option>
                                            <option value="100000-1000000">100k-1m</option>
                                            <option value="1000000-10000000">1m-10m</option>
                                        </select>
                                    </div>

                                    <div className="filter-item">
                                        <select name="views" onChange={(event) => this.inputChangeHandler(event, 'views')} className="form-control" >
                                            <option value="">{t("Average views")}</option>
                                            <option value="">{t("All")}</option>
                                            <option value="0-50000">0-50k</option>
                                            <option value="50000-150000">50k-150k</option>
                                            <option value="150000-500000">150k-500K</option>
                                            <option value="500000-1000000">500k-1m</option>
                                            <option value="1000000-50000000">1m-50m</option>
                                        </select>
                                    </div>

                                    <div className="filter-item">
                                        <select name="audience_city" onChange={(event) => this.inputChangeHandler(event, 'audience_city')} className="form-control" >
                                            <option value="">{t("Audience City")} </option>
                                            <option value="">{t("All")}</option>
                                            <option value="Jaipur">Jaipur</option>
                                        </select>
                                    </div>

                                    <div className="filter-item">
                                        <select name="gender" onChange={(event) => this.inputChangeHandler(event, 'gender')} className="form-control" >
                                            <option value="">{t("Audience Gender")}</option>
                                            <option value="">{t("All")}</option>
                                            <option value="1">{t("Male")}</option>
                                            <option value="2">{t("Female")}</option>
                                            <option value="3">{t("Others")}</option>
                                        </select>
                                    </div>

                                    <div className="filter-item">
                                        <select name="age_group" onChange={(event) => this.inputChangeHandler(event, 'age_group')} className="form-control" >
                                            <option value="">{t("Audience Age")}</option>
                                            <option value="">{t("All")}</option>
                                            <option value="1824">18-24</option>
                                            <option value="2534">25-34</option>
                                            <option value="35">35+</option>
                                        </select>
                                    </div>

                                    <div className="filter-item">
                                        <select name="device" onChange={(event) => this.inputChangeHandler(event, 'device')} className="form-control" >
                                            <option value="">{t("Audience Device")}</option>
                                            <option value="">{t("All")}</option>
                                            <option value="A">{t("Android")}</option>
                                            <option value="I">{t("IOS")}</option>
                                        </select>
                                    </div>

                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">


                                        <div className="sidebar-demo">


                                            <div className="text-center">
                                                <a href="#_" className="searchbar-icon" data-toggle="modal"
                                                   data-target="#myModal">
                                                    <i className="fa fa-bars"></i> <Trans>Search options</Trans>
                                                </a>
                                            </div>

                                            <div className="modal left fade" id="myModal" tabIndex="-1" role="dialog"
                                                 aria-labelledby="myModalLabel">
                                                <div className="modal-dialog" role="document">
                                                    <div className="modal-content">


                                                        <div className="modal-body">
                                                            <div className="market-search-detail">
                                                                <div className="filter-item">
                                                                    <label className="filter-label"><Trans>City</Trans></label>
                                                                    <select name="city_name" onChange={(event) => this.inputChangeHandler(event, 'city_name')} className="form-control" >
                                                                        <option value="">{t("All")}</option>
                                                                        <option value="Jaipur">Jaipur</option>
                                                                    </select>
                                                                </div>
                                                                <div className="filter-item">
                                                                    <label className="filter-label"><Trans>Topic</Trans></label>
                                                                    <div className="dropdown-topic-list">
                                                                        <header
                                                                            className="topic-list-header position-relative">
                                                                            <div className="topic-tags-input m-0">
                                                                                <ul data-placeholder="Select"
                                                                                    className="topic-tags-list"></ul>
                                                                            </div>
                                                                        </header>
                                                                    </div>
                                                                </div>
                                                                <div className="filter-item">
                                                                    <label className="filter-label"><Trans>Followers</Trans></label>
                                                                    <div className="author-reach-select">
                                                                        <div className="btns-group">
                                                                            <div role="button"
                                                                                 className={this.state.followers == '' ? "btn active is-gray-button" : "btn is-gray-button"} onClick={() => this.followerFilter('')}>
                                                                                {t("All")}
                                                                            </div>
                                                                            <div role="button"
                                                                                 className={this.state.followers == '0-10000' ? "btn active is-gray-button" : "btn is-gray-button"} onClick={() => this.followerFilter('0-10000')}>0-10k
                                                                            </div>
                                                                            <div role="button"
                                                                                 className={this.state.followers == '10000-100000' ? "btn active is-gray-button" : "btn is-gray-button"} onClick={() => this.followerFilter('10000-100000')}>10k-100k
                                                                            </div>
                                                                            <div role="button"
                                                                                 className={this.state.followers == '100000-1000000' ? "btn active is-gray-button" : "btn is-gray-button"} onClick={() => this.followerFilter('100000-1000000')}>100k-1m
                                                                            </div>
                                                                            <div role="button"
                                                                                 className={this.state.followers == '1000000-10000000' ? "btn active is-gray-button" : "btn is-gray-button"}  onClick={() => this.followerFilter('1000000-10000000')}>1m-10m
                                                                            </div>
                                                                            <div
                                                                                className="btn is-gray-button">Customize
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="filter-item">
                                                                    <label className="filter-label"><Trans>Average views</Trans></label>
                                                                    <div className="author-reach-select">
                                                                        <div className="btns-group">
                                                                            <div role="button"
                                                                                 className={this.state.views == '' ? "btn active is-gray-button" : "btn is-gray-button"} onClick={() => this.viewFilter('')}>
                                                                                {t("All")}
                                                                            </div>
                                                                            <div role="button"
                                                                                 className={this.state.views == '0-50000' ? "btn active is-gray-button" : "btn is-gray-button"} onClick={() => this.viewFilter('0-50000')}>0k-50k
                                                                            </div>
                                                                            <div role="button"
                                                                                 className={this.state.views == '50000-150000' ? "btn active is-gray-button" : "btn is-gray-button"} onClick={() => this.viewFilter('50000-150000')}>50k-150k
                                                                            </div>
                                                                            <div role="button"
                                                                                 className={this.state.views == '150000-500000' ? "btn active is-gray-button" : "btn is-gray-button"} onClick={() => this.viewFilter('150000-500000')}>
                                                                                150k-500k
                                                                            </div>
                                                                            <div role="button"
                                                                                 className={this.state.views == '500000-1000000' ? "btn active is-gray-button" : "btn is-gray-button"} onClick={() => this.viewFilter('500000-1000000')}>500k-1m
                                                                            </div>
                                                                            <div role="button"
                                                                                 className={this.state.views == '1000000-50000000' ? "btn active is-gray-button" : "btn is-gray-button"} onClick={() => this.viewFilter('1000000-50000000')}>1m-50m
                                                                            </div>
                                                                            <div
                                                                                className="btn is-gray-button">Customize
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="filter-item">
                                                                    <label className="filter-label"><Trans>e-Commerce Anchor</Trans></label>
                                                                </div>
                                                                <div className="filter-item">
                                                                    <label className="filter-label">Audience City</label>
                                                                    <select name="audience_city" onChange={(event) => this.inputChangeHandler(event, 'audience_city')} className="form-control" >
                                                                        <option value="All"> {t("All")}</option>
                                                                        <option value="Jaipur">Jaipur</option>
                                                                    </select>
                                                                </div>
                                                                <div className="filter-item">
                                                                    <label className="filter-label"><Trans>Audience Gender</Trans></label>
                                                                    <div className="author-reach-select">
                                                                        <div className="btns-group">
                                                                            <div role="button"
                                                                                 className={this.state.gender == '' ? "btn active is-gray-button" : "btn is-gray-button"} onClick={() => this.genderFilter('')}>
                                                                                {t("All")}
                                                                            </div>
                                                                            <div role="button"
                                                                                 className={this.state.gender == 1 ? "btn active is-gray-button" : "btn is-gray-button"} onClick={() => this.genderFilter(1)}>{t("Male")}
                                                                            </div>
                                                                            <div role="button"
                                                                                 className={this.state.gender == 2 ? "btn active is-gray-button" : "btn is-gray-button"} onClick={() => this.genderFilter(2)}>{t("Female")}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="filter-item">
                                                                    <label className="filter-label"><Trans>Audience Age</Trans></label>
                                                                    <div className="author-reach-select">
                                                                        <div className="btns-group">
                                                                            <div role="button"
                                                                                 className={this.state.age_group == '' ? "btn active is-gray-button" : "btn is-gray-button"} onClick={() => this.ageFilter('')}>
                                                                                {t("All")}
                                                                            </div>
                                                                            <div role="button"
                                                                                 className={this.state.age_group == '1824' ? "btn active is-gray-button" : "btn is-gray-button"} onClick={() => this.ageFilter('1824')}>18-24
                                                                            </div>
                                                                            <div role="button"
                                                                                 className={this.state.age_group == '2534' ? "btn active is-gray-button" : "btn is-gray-button"} onClick={() => this.ageFilter('2534')}>25-34
                                                                            </div>
                                                                            <div className={this.state.age_group == '35' ? "btn active is-gray-button" : "btn is-gray-button"} onClick={() => this.ageFilter('35')}>35+
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="filter-item">
                                                                    <label className="filter-label"><Trans>Audience Device</Trans></label>
                                                                    <div className="author-reach-select">
                                                                        <div className="btns-group">
                                                                            <div role="button"
                                                                                 className={this.state.device == '' ? "btn active is-gray-button" : "btn is-gray-button"} onClick={() => this.deviceFilter('')}>
                                                                                {t("All")}
                                                                            </div>
                                                                            <div role="button"
                                                                                 className={this.state.device == 'A' ? "btn active is-gray-button" : "btn is-gray-button"} onClick={() => this.deviceFilter('A')}> {t("Android")}
                                                                            </div>
                                                                            <div role="button"
                                                                                 className={this.state.device == 'I' ? "btn active is-gray-button" : "btn is-gray-button"} onClick={() => this.deviceFilter('I')}>{t("IOS")}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="modal-footer market-search-footer">
                                                            <button type="button" className="btn gray-btn search-footer-modal"
                                                                    data-dismiss="modal"
                                                                    aria-label="Close">Cancel
                                                            </button>
                                                            <button type="button" className="btn orange-btn" onClick={() => this.filterCreatorList()}><Trans>Search</Trans>
                                                            </button>
                                                        </div>


                                                    </div>

                                                </div>
                                            </div>


                                        </div>


                                        <div className="clear tool-clear"><a href={'/home'}> <i
                                            className="fa fa-eraser"></i> <Trans>clear</Trans></a></div>
                                    </div>
                                    <div className="view-mode">
                                        <a href="#_" id="list">
                                            <i className="fa fa-bars"></i>
                                        </a>
                                        <a href="#_" id="grid" className="active">
                                            <i className="fa fa-th-large"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </section>


                        <section className="market-content">
                            <div className="container">
                                <div className="content-filter">
                                    <div className="content-category"></div>
                                    <div className="total-and-sort">
                                        <span className="total">10000+<Trans>Creator(s)</Trans> </span>
                                        <div className="sort">
                                            <span className="sort-label"><Trans>Sort by</Trans></span>
                                            <div className="dropdown show">
                                                <a className="nav-link dropdown-arrow" href="#" data-toggle="dropdown"
                                                   aria-expanded="true">
                                                    <Trans>Average views</Trans> <span className="fa fa-angle-down"></span>
                                                </a>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" href="#"><Trans>Followers</Trans></a>
                                                    <a className="dropdown-item" href="#"><Trans>Average views</Trans></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-wrap" id="cardprofile">
                                    <div className="row">
                                        {this.state.users.map((user, index) => (
                                            <div className="col-lg-3 col-md-6 col-sm-12 card-item" key={index}>
                                                <a href={'/profile/'+user.username}> <div className="card-container">
                                                    <div className="card-back-img" style={{backgroundImage: user.profile}}>
                                                        <div className="img-mask"></div>
                                                    </div>
                                                    <div className="avatar card-avatar"
                                                         style={{width: "66px", height: "66px"}}>
                                                        <img src={user.profile} alt="" className="img-avatar"
                                                             style={{opacity: "1", width: "60px", borderWidth: "3px"}}
                                                        />
                                                    </div>
                                                    <div className="add-to-shortlist">
                                                        <div className="grey-send-btn" data-toggle="tooltip"
                                                             data-placement="bottom"
                                                             title="" data-original-title="Add to list" onClick={() => this.addUserToList(user)}>
                                                            <a href="#_"
                                                                                                           data-toggle="modal"
                                                                                                           data-target="#addTolist"><i
                                                            className="fa fa-plus-square"></i></a></div>
                                                    </div>
                                                    <div className="card-info">

                                                        <div className="card-name star-nowrap"><span>{user.name}</span>
                                                        </div>
                                                        <div className="card-handlename">@{user.username}</div>
                                                        <div className="card-city"><i
                                                            className="fa fa-map-marker"></i> {user.city ? user.city : 'United States of America'}
                                                        </div>
                                                        <div className="card-topics">
                                                            <div className="tag-list" style={{fontSize: "12px"}}>
			 <span className="g-tag g-tag-blue">
              Dance
             </span>
                                                                <span className="g-tag g-tag-blue">
              Vlog
             </span>
                                                                <span className="g-tag g-tag-blue">
                 Transition
             </span>
                                                            </div>
                                                        </div>
                                                        <div className="card-numinfo">
                                                            <div className="author-data">
                                                                <div className="numinfo-val">{user.follower_count}</div>
                                                                <div className="numinfo-key"><Trans>Followers</Trans></div>
                                                            </div>
                                                            <div className="author-data">
                                                                <div className="numinfo-val">{user.view_count}</div>
                                                                <div className="numinfo-key"><Trans>Average Views</Trans></div>
                                                            </div>
                                                        </div>
                                                        <div className="author-audience">
                                                            <div className="profile-item">
                                                                <aside className="flag-icon flag-icon-PH"></aside>
                                                                <div className="profile-val">33%</div>
                                                            </div>
                                                            <div className="profile-item">
                                                                <div className="profile-title"><Trans>Male</Trans></div>
                                                                <div className="profile-val">{user.male_percentage}%</div>
                                                            </div>
                                                            <div className="profile-item">
                                                                <div className="profile-title">25-34</div>
                                                                <div className="profile-val">{user.midium_age}%</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div></a>

                                                <div className="opt-box">
                                                    <div className="grey-send-btn" data-toggle="tooltip"
                                                         data-placement="bottom"
                                                         title="invite now"><a href={'/create-campaign'}><i
                                                        className="fa fa-paper-plane"></i></a></div>
                                                    <button type="button" className="btn orange-btn" data-toggle="modal"
                                                            data-target="#addExisting" onClick={() => this.addUserToCampaign(user)}>
                                                        <span><Trans>Add existing campaign</Trans></span>
                                                    </button>

                                                </div>

                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {this.state.not_verified &&
                                <div className="market-placement position-relative">
                                    <div className="img-wrap">
                                        <div className="row">
                                            <div className="col-lg-3 col-md-2 col-sm-12">
                                                <img src={marketEmpImg} alt="img"
                                                     className="placement-pic"/>
                                            </div>
                                            <div className="col-lg-3 col-md-2 col-sm-12">
                                                <img src={marketEmpImg} alt="img"
                                                     className="placement-pic"/>
                                            </div>
                                            <div className="col-lg-3 col-md-2 col-sm-12">
                                                <img src={marketEmpImg} alt="img"
                                                     className="placement-pic"/>
                                            </div>
                                            <div className="col-lg-3 col-md-2 col-sm-12">
                                                <img src={marketEmpImg} alt="img"
                                                     className="placement-pic"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="placement-wrap">
                                        <div className="placement-title"><Trans>Unlock more influences by activating your account first</Trans></div>
                                        <div className="placement-info"><Trans>Before you create a campaign, you’ll need to verify your business.</Trans></div>
                                        <div className="placement-btn">
                                            <a href={'/account'} className="btn orange-btn mt-4"><span><Trans>Go to qualification page</Trans></span></a>
                                        </div>
                                    </div>
                                </div>
                                }
                                {!this.state.not_verified && this.state.next_page &&
                                <div className="show-more-paginatio">
                                    <span className="btn btn-success page-button" onClick={() => this.nextPage()}><Trans>Show more</Trans></span>
                                </div>
                                }


                            </div>
                        </section>
                    </div>
                </div>
                <div className="kurafat-modal">
                    <div className="modal fade" id="addTolist" tabIndex="-1" role="dialog"
                         aria-labelledby="addTolistLabel"
                         aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title"><Trans>Add to list</Trans></h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="search-header">
                                        <div className="search-wrapper">
                                            <div className="search-bar position-relative">
                                                <input type="text" className="form-control input-search" name="search"
                                                       id=""
                                                       placeholder={t("Try searching lipstick")} />
                                                    <span className="input-icon"><i className="fa fa-search"></i></span>
                                            </div>
                                        </div>
                                        <a href={'/shortlist'}><strong>+ </strong> <Trans>Create a list</Trans>
                                        </a>
                                    </div>
                                    <div className="flod-list">
                                        {this.state.shortList.map((list, index) => (
                                            <div className="flod-list-item" key={index}>
                                                <div className="flod-list-item-left">
                                                    <div className="form-group form-check">
                                                        <input type="checkbox" className="form-check-input share-check"
                                                               value={list.id}
                                                               onChange={(event) => this.checkCheckbox(event, 'checked_shortlist')}
                                                               id="sharecheckbox" />
                                                            <label className="form-check-label compu_n"
                                                                   htmlFor="sharecheckbox">{list.name}
                                                            </label>
                                                    </div>

                                                </div>
                                                <div className="flod-list-item-right">
                                                    <div className="flod-total">
                                                        {list.creator_count}
                                                        Creator(s)
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="modal-footer dialog__footer">
                                    <div className="add-author-info">
                                        <div className="base-info-brief">
                                            <div className="avatar" style={{width:"45px", height:"45px"}}><img
                                                src={this.state.selected_user.profile ? this.state.selected_user.profile : backImg}
                                                alt="" className="img-avatar" />
                                            </div>
                                            <div className="base-info star-nowrap">
                                                <div className="name star-nowrap">
                                                    {this.state.selected_user.name}
                                                </div>
                                                <div className="address star-nowrap">
                                                    {this.state.selected_user.city ? this.state.selected_user.city : 'United States of America'}
                                                </div>
                                            </div>
                                        </div>
                                            <div className="add-author-info-content w-100">
                                                <textarea autoComplete="off" spellCheck="false" placeholder="Enter a comment (optional)"
                                                          className="k-textarea"
                                                          style={{minHeight:"60px", height:"60px"}}
                                                          disabled="disabled"></textarea>
                                            </div>
                                    </div>
                                    <button type="button" className="btn gray-btn close-list-modal" data-dismiss="modal"><Trans>Cancel</Trans></button>
                                    <button type="button" className="btn orange-btn" onClick={() => this.saveCreatorToList('shortlist')}><Trans>Save</Trans></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="kurafat-modal">
                    <div className="modal fade" id="addExisting" tabIndex="-1" role="dialog"
                         aria-labelledby="addTolistLabel"
                         aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title"><Trans>Add to existing campaign</Trans></h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="base-info-brief">
                                        <div className="avatar" style={{width:"45px", height:"45px"}}>
                                            <img src={this.state.selected_user.profile ? this.state.selected_user.profile : backImg} alt="" className="img-avatar" /></div>
                                        <div className="base-info star-nowrap">
                                            <div className="name star-nowrap">
                                                {this.state.selected_user.name}
                                            </div>
                                            <div className="address star-nowrap">
                                                {this.state.selected_user.city ? this.state.selected_user.city : 'United States of America'}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sub-title">{this.state.CampaignList.length ? t("Campaign List") : t("You don't have any draft campaign. Create one now.") } </div>
                                    <div className="flod-list">
                                        {this.state.CampaignList.map((campaign, index) => (
                                            <div className="flod-list-item" key={index}>
                                                <div className="flod-list-item-left">
                                                    <div className="form-group form-check">
                                                        <input type="checkbox" className="form-check-input share-check"
                                                               value={campaign.id}
                                                               onChange={(event) => this.checkCheckbox(event, 'checked_campaign')}
                                                               id="sharecheckbox" />
                                                        <label className="form-check-label compu_n"
                                                               htmlFor="sharecheckbox">{campaign.title}
                                                        </label>
                                                    </div>

                                                </div>
                                                <div className="flod-list-item-right">
                                                    <div className="flod-total">
                                                        {campaign.creator_count}
                                                        Creator(s)
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    {!this.state.CampaignList.length ?
                                        <a href={'/create-campaign'} type="button" className="btn orange-btn"><Trans>Create campaign</Trans></a>
                                        :
                                        <>
                                            <button type="button" className="btn gray-btn close-list-modal" data-dismiss="modal"><Trans>Cancel</Trans></button>
                                            <button type="button" className="btn orange-btn" onClick={() => this.saveCreatorToList('campaign')}><Trans>Save</Trans></button>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="account-activate-modal kurafat-modal">
                    <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog"
                         aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Verify your account first</h5>
                                    <button type="button" className="close close-activate-modal" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    Before you create a campaign, you'll need to verify your business
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn gray-btn close-activate-modal" data-dismiss="modal"><Trans>Later</Trans></button>
                                    <a href={"/account"} className="btn orange-btn"><Trans>Activate your account</Trans></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </>
        )
    }
}

export default withTranslation() (withRouter(Market));