import React from 'react';
import {withRouter} from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import $ from "jquery";
import {callAPI, userData} from "../../Commen/CommenFunction";
import creatorListImg from '../../Assets/images/creator-list-img.svg';
import Moment from 'moment';
import { Trans, withTranslation  } from "react-i18next";

class createCampaign extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            title: '',
            contact_name:userData.personal_name,
            contact_email:userData.personal_email,
            contact_number:userData.personal_phone,
            brand_name:'',
            brand_website:'',
            brand_description:'',
            brand_file:'',
            message_file:'',
            product_name:'',
            industry:'',
            campaign_goal:'',
            start_date:'',
            end_date:'',
            price:'',
            message:'',
            whatsapp_username:'',
            instagram_username:'',
            kurafat_username:'',
            chingari_username:'',
            mxtakatak_username:'',
            brand_id:'',
            loading:false,
            categoryList:[],
            brandList:[],
            shortList:[],
            CurrentShortList:[],
            CreatorList:[],
            SelectedCreatorList:[],
            campaign:[],
            campaign_id:'',
            add_new_brand:false,
            standard_temp:"",
            message_temp:"",
        };
        this.fileChange = this.fileChange.bind(this);
    }

    async componentDidMount() {
        try{
            const response = await callAPI('user/getIndustryList', 'post', null, 'application/json');
            if (response.status === true) {
                this.setState({
                    categoryList: response.data,
                });
            }
        } catch (e) {
            console.log(e);
            this.setState({
                loading: false
            });
        }

        try{
            const response = await callAPI('user/getBrandListForCampaign', 'post', null, 'application/json');
            if (response.status === true) {
                this.setState({
                    brandList: response.data,
                });
            }
        } catch (e) {
            console.log(e);
            this.setState({
                loading: false
            });
        }

        await this.getShortlist();
        let campaign_id = this.props.match.params.campaign_id;
        if (campaign_id) {
            this.setState({
                campaign_id: campaign_id
            })
            await this.CampaignDetail();
        }
    }

    async inputChangeHandler(event, key) {
        const value = event.target.value;

        this.setState(state => {
            return {
                ...state,
                [key]: value
            }
        })
    }

    async submitHandler(event) {
        console.log(event)
        event.preventDefault()
        var formData =$('.needs-validation')[0];
        if (!formData.checkValidity()) {
            formData.classList.add('was-validated')
            return;
        }
        await this.postData()
    }

    async postData(draft=null) {
        let user_id = '';
        for (const el of this.state.SelectedCreatorList) {
            if (!user_id){
                user_id = el.id;
            } else {
                user_id = user_id+','+el.id;
            }
        }

        this.setState({
            loading:true
        });
        let campStatus = 0;
        if (draft) {
            campStatus = 4;
        }

        const form = new FormData()
        form.set('title', this.state.title)
        form.set('contact_name', this.state.contact_name)
        form.set('contact_email', this.state.contact_email)
        form.set('contact_number', this.state.contact_number)
        form.set('brand_name', this.state.brand_name)
        form.set('brand_website', this.state.brand_website)
        form.set('brand_description', this.state.brand_description)
        form.set('product_name', this.state.product_name)
        form.set('industry', this.state.industry)
        form.set('brand_id', this.state.brand_id)
        form.set('campaign_goal', this.state.campaign_goal)
        form.set('start_date', this.state.start_date)
        form.set('end_date', this.state.end_date)
        form.set('price', this.state.price)
        form.set('message', this.state.message)
        form.set('whatsapp_username', this.state.whatsapp_username)
        form.set('instagram_username', this.state.instagram_username)
        form.set('kurafat_username', this.state.kurafat_username)
        form.set('chingari_username', this.state.chingari_username)
        form.set('mxtakatak_username', this.state.mxtakatak_username)
        form.set('user_ids', user_id)
        form.set('campaign_id', this.state.campaign_id)
        form.set('status', campStatus)
        form.append(
            "brand_file",
            this.state.brand_file
        )
        form.append(
            "message_file",
            this.state.message_file
        )
        form.append(
            "logo",
            this.state.logo
        )

        try {
            const response = await callAPI('user/createCampaign', 'post', form,'application/json');
            if (response.status === true) {
                if (this.props.match.params.campaign_id) {
                    this.props.history.push({
                        pathname: '/activity',
                    });
                } else {
                    if (draft == 'draft') {
                        alert('Save to Draft')
                        this.setState({
                            campaign_id:response.data
                        });
                    } else {
                        this.props.history.push({
                            pathname: '/campaign-created',
                        });
                    }
                }
            } else {
                this.setState({
                    errorMessage:response.message,
                    opensnack:true
                });
            }
            this.setState({
                loading:false
            });
        } catch (e) {
            this.setState({
                loading:false
            });
        }
    }

    fileChange(event,type) {
        let fileData = event.target.files[0];

        if (type == 'logo') {
            this.setState({
                logo_url: URL.createObjectURL(fileData),
            });
        }

        this.setState({
            [type]: fileData
        });
    }

    addNewBrand() {
        this.setState({
            add_new_brand:true
        });
    }
    selectedBrand() {
        this.setState({
            add_new_brand:false
        });
    }

    async getShortlist() {
        try {
            const form = new FormData()
            const response = await callAPI('user/getShortlistForCreator', 'post', form, 'application/json');

            if (response.status === true) {
                this.setState({
                    shortList: response.data,
                });
            } else {
                this.setState({
                    shortList: [],
                });
            }
            this.setState({
                loading: false
            });
        } catch (e) {
            this.setState({
                loading: false
            });
        }
    }

    async getShortlistCreator(shortlist) {
        try {
            this.setState({
                CurrentShortList: shortlist
            });
            const form = new FormData()
            form.set('shortlist_id', shortlist.id)

            const response = await callAPI('user/getShortlistCreators', 'post', form, 'application/json');
            if (response.status === true) {
                this.setState({
                    CreatorList: response.data.creatorLists
                });
            } else {
                this.setState({
                    CreatorList: [],
                });
            }
            for (const el of this.state.SelectedCreatorList) {
                for (const creatorEl of this.state.CreatorList) {
                    if(creatorEl.id == el.id) {
                        creatorEl.added = true;
                    }
                }
            }
            this.setState({
                CreatorList: this.state.CreatorList,
                loading: false
            });
        } catch (e) {
            this.setState({
                loading: false
            });
        }
    }
    async removeShortList() {
        this.setState({
            CreatorList: [],
            CurrentShortList: []
        });
    }
    async selectedCreator(creator){
        this.state.SelectedCreatorList.push(creator);
        this.setState({
            SelectedCreatorList: this.state.SelectedCreatorList
        });

        for (const el of this.state.SelectedCreatorList) {
            for (const creatorEl of this.state.CreatorList) {
                if(creatorEl.id == el.id) {
                    creatorEl.added = true;
                }
            }
        }
        this.setState({
            CreatorList: this.state.CreatorList
        });
    }
    async removeCreator(key){
        delete this.state.SelectedCreatorList[key]
        let selected_list = [];
        for (const el of this.state.SelectedCreatorList) {
            if(el) {
                selected_list.push(el);
                for (const creatorEl of this.state.CreatorList) {
                    if(creatorEl.id == el.id) {
                        creatorEl.added = true;
                    }
                }
            }
        }
        this.setState({
            SelectedCreatorList: selected_list
        });
        this.setState({
            CreatorList: this.state.CreatorList
        });
    }

    async CampaignDetail() {
        try {
            const form = new FormData()
            form.set('campaign_id', this.props.match.params.campaign_id)

            const response = await callAPI('user/getCampaignDetail', 'post', form, 'application/json');
            if (response.status === true) {
                let campaign = response.data.campaign;
                let start_date = Moment(campaign.start_date).format('YYYY-MM-DD');
                let end_date = Moment(campaign.start_date).format('YYYY-MM-DD');
                this.setState({
                    title: campaign.title,
                    contact_name:campaign.contact_name,
                    contact_email:campaign.contact_number,
                    contact_number:campaign.contact_number,
                    brand_id:campaign.brand_id,
                    product_name:campaign.product_name,
                    campaign_goal:campaign.campaign_goal,
                    start_date:start_date,
                    end_date:end_date,
                    price:campaign.price,
                    message:campaign.message,
                    campaign:campaign
                });
            } else {
                this.setState({
                    CreatorList: [],
                });
            }
            this.setState({
                loading: false
            });
        } catch (e) {
            this.setState({
                loading: false
            });
        }
    }

    useTemplate(value){
        this.setState({
            message:value
        })
    }

    toFillIn(value){
        this.setState({
            message:value
        })
    }
    render() {
        const { t } = this.props;
        let standard_temp = ""+t("Hi! I'm")+" "+userData.name+" from "+userData.company_name+" . "+t("Would you be interested in a paid opportunity on TikTok to promote our product? Let me know if you're interested and I'll share more details about the campaign. Looking forward to hearing from you!")+"";
        let message_temp = ""+t("Hi! I'm")+" "+userData.name+" "+t("reaching out on behalf of")+" "+userData.company_name+". "+t("I've been following your account for some time and we’d love to work with you to promote our")+" "+this.state.product_name+". "+t("Let us know if you’re interested!")+"";

        return (
            <>
                <Header/>
                <div className="content-wrapper">
                    <div className="wrapper-inner">
                        <div className="container">
                            <div className="create-campaign">
                                <div className="campaign-left-wrapper">
                                    <div className="create-card-panel">
                                        <div className="panel-title title-wrapper"><Trans>Create campaign</Trans></div>
                                        {this.props.match.params.campaign_id
                                            ?
                                            <>
                                                {this.state.campaign && this.state.campaign.status == 4 &&
                                                <button className="add_to_draft btn btn-secondery orange-btn" onClick={() =>this.postData('draft')}><Trans>Save Draft</Trans></button>
                                                }
                                            </>
                                            :
                                            <button className="add_to_draft btn btn-secondery orange-btn" onClick={() =>this.postData('draft')}><Trans>Save Draft</Trans></button>
                                        }
                                        <div className="card-panel-body">
                                            <form method="post" className="create-campaign-form needs-validation" noValidate={true} onSubmit={(event) => this.submitHandler(event)}>
                                                <div className="form-group-wrap">
                                                    <div className="breif-item">
                                                        <div className="item-title"><Trans>Campaign name</Trans></div>
                                                        <div className="item-content">
                                                            <div className="form-group">
                                                                <label htmlFor="name"><Trans>Campaign name</Trans></label>
                                                                <div className="input-suffix">
                                                                    <input type="text" name="name" id="c-name"
                                                                           className="form-control"
                                                                           value={this.state.title}
                                                                           onChange={(event) => this.inputChangeHandler(event, 'title')}
                                                                           required={true}
                                                                           placeholder={t('Enter Campaign name')} />
                                                                        <div className="suffix"><span>0/60</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group-wrap">
                                                    <div className="breif-item">
                                                        <div className="item-title"><Trans>Contact information</Trans></div>
                                                        <div className="item-content">
                                                            <div className="form-group">
                                                                <label htmlFor="name"><Trans>Contact name</Trans></label>
                                                                <div className="input-suffix">
                                                                    <input type="text" name="name" id="contact-name"
                                                                           value={this.state.contact_name}
                                                                           onChange={(event) => this.inputChangeHandler(event, 'contact_name')}
                                                                           required={true}
                                                                           className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="name"><Trans>Email</Trans></label>
                                                                <div className="input-suffix">
                                                                    <input type="text" name="email" id="email"
                                                                           className="form-control"
                                                                           value={this.state.contact_email}
                                                                           onChange={(event) => this.inputChangeHandler(event, 'contact_email')}
                                                                           required={true}
                                                                           placeholder={t("Enter email address")} />
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="name" className="compu_n"><Trans>Phone number</Trans></label>
                                                                <div className="input-suffix">
                                                                    <input type="text" name="number" id="number"
                                                                           className="form-control"
                                                                           value={this.state.contact_number}
                                                                           onChange={(event) => this.inputChangeHandler(event, 'contact_number')}
                                                                           required={true}
                                                                           placeholder={t("Enter phone number")} />
                                                                </div>
                                                            </div>
                                                            <div className="more-wrap">
                                                                <a className="collapsed more-arrow"
                                                                   data-toggle="collapse" href="#more"
                                                                   aria-expanded="false" aria-controls="more"><Trans>More</Trans></a>
                                                                <div className="collapse" id="more">
                                                                    <div className="form-group">
                                                                        <label htmlFor="name" className=" compu_n"><Trans>Social media</Trans></label>
                                                                        <div className="form-group">
                                                                            <label htmlFor="name" className=" compu_n"><Trans>Instagram</Trans></label>
                                                                            <div className="input-suffix">
                                                                                <input type="text" name="userename"
                                                                                       id="username"
                                                                                       value={this.state.instagram_username}
                                                                                       onChange={(event) => this.inputChangeHandler(event, 'instagram_username')}
                                                                                       className="form-control"
                                                                                       placeholder={t("Enter username")} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="name" className=" compu_n"><Trans>Whatsapp</Trans></label>
                                                                            <div className="input-suffix">
                                                                                <input type="text" name="userename"
                                                                                       id="username"
                                                                                       value={this.state.whatsapp_username}
                                                                                       onChange={(event) => this.inputChangeHandler(event, 'whatsapp_username')}
                                                                                       className="form-control"
                                                                                       placeholder={t("Enter username")} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="name" className=" compu_n"><Trans>kurafat</Trans></label>
                                                                            <div className="input-suffix">
                                                                                <input type="text" name="userename"
                                                                                       id="username"
                                                                                       value={this.state.kurafat_username}
                                                                                       onChange={(event) => this.inputChangeHandler(event, 'kurafat_username')}
                                                                                       className="form-control"
                                                                                       placeholder={t("Enter username")} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="name" className=" compu_n"><Trans>Chingari</Trans></label>
                                                                            <div className="input-suffix">
                                                                                <input type="text" name="userename"
                                                                                       id="username"
                                                                                       value={this.state.chingari_username}
                                                                                       onChange={(event) => this.inputChangeHandler(event, 'chingari_username')}
                                                                                       className="form-control"
                                                                                       placeholder={t("Enter username")} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="name" className=" compu_n"><Trans>MX Takatak</Trans></label>
                                                                            <div className="input-suffix">
                                                                                <input type="text" name="userename"
                                                                                       id="username"
                                                                                       value={this.state.mxtakatak_username}
                                                                                       onChange={(event) => this.inputChangeHandler(event, 'mxtakatak_username')}
                                                                                       className="form-control"
                                                                                       placeholder={t("Enter username")} />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="form-group form-check">
                                                                <input type="checkbox"
                                                                       className="form-check-input share-check"
                                                                       id="sharecheckbox" />
                                                                    <label className="form-check-label compu_n"
                                                                           htmlFor="sharecheckbox"><Trans>Share contact information with the creators only after they accept your campaign</Trans>
                                                                        <span className="help-icon-tooltip"
                                                                              data-toggle="tooltip"
                                                                              data-placement="bottom" title=""
                                                                              data-original-title={t("Leave this box unchecked if you'd like the creators to see your contact information and reach out to you before they accept your campaign.")}>
                                                                          <i className="fa fa-question-circle-o"></i>
                                                                         </span>
                                                                    </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group-wrap">
                                                    <div className="breif-item">
                                                        <div className="item-title"><Trans>Brand information</Trans></div>
                                                        <div className="item-content">
                                                            {this.state.add_new_brand == false &&
                                                                <div className="form-group">
                                                                    <label htmlFor="name"><Trans>Brand List</Trans></label><a
                                                                    href="#_"
                                                                    className="industry-label" onClick={() => this.addNewBrand()}><Trans>Add New Brand</Trans></a>
                                                                    <select required={true} name="country_code" onChange={(event) => this.inputChangeHandler(event, 'brand_id')} className="form-control" >
                                                                        <option value="">{t("Select")}</option>
                                                                        {this.state.brandList.map((brand, index) => (
                                                                            <option value={brand.id} selected={this.state.brand_id = brand.id}>{brand.name}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            }
                                                            {this.state.add_new_brand &&
                                                            <>
                                                                <div className="form-group">
                                                                    <label htmlFor="name"><Trans>Brand name</Trans></label><a
                                                                    href="#_"
                                                                    className="industry-label" onClick={() => this.selectedBrand()}><Trans>Select Brand from already created</Trans></a>
                                                                    <div className="input-suffix">
                                                                        <input type="text" name="name" id="brandname"
                                                                               className="form-control"
                                                                               value={this.state.brand_name}
                                                                               onChange={(event) => this.inputChangeHandler(event, 'brand_name')}
                                                                               required={true}
                                                                               placeholder={t("Enter the brand to be promoted")}/>
                                                                        <div className="suffix"><span>0/60</span></div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="name" className="compu_n"><Trans>Brand Logo</Trans></label>
                                                                    <div className="file-uploader">
                                                                        <div className="file-upload-btn mt-0"><i
                                                                            className="fa fa-upload"></i> <Trans>Upload file</Trans>
                                                                            <input type="file" name="file"
                                                                                        onChange={(event) => this.fileChange(event, 'logo')}
                                                                                        accept=".png,.jpg,.jpeg"/>
                                                                            {this.state.logo_url &&
                                                                            <img className="file_url_file"
                                                                                 src={this.state.logo_url}
                                                                                 alt="File URL"/>
                                                                            }
                                                                        </div>
                                                                        <div className="file-uploader-info"><Trans>Logos must be JPG or PNG and less than 5 MB Brand industry</Trans>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="brand-link"><a href="#_"><Trans>Add commonly use brands for promotion alongside descriptions, and then be able to access them quickly.</Trans></a></div>
                                                                <div className="form-group">
                                                                    <label htmlFor="name" className="compu_n"><Trans>Brand website</Trans></label>
                                                                    <div className="input-suffix">
                                                                        <input type="text" name="name" id="brandwebsite"
                                                                               value={this.state.brand_website}
                                                                               onChange={(event) => this.inputChangeHandler(event, 'brand_website')}
                                                                               required={true}
                                                                               className="form-control"
                                                                               placeholder={t("Please enter")}/>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="name" className="compu_n"><Trans>Brand Description</Trans></label>
                                                                    <div className="input-suffix">
                                                                    <textarea placeholder={t("Brand Description")}
                                                                              className="textarea-inner"
                                                                              value={this.state.brand_description}
                                                                              onChange={(event) => this.inputChangeHandler(event, 'brand_description')}
                                                                              required={true}
                                                                              style={{
                                                                                  resize: "none",
                                                                                  minHeight: "102px",
                                                                                  height: "102px"
                                                                              }}></textarea>
                                                                        <div className="suffix b-0"><span>0/800</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="file-uploader">
                                                                        <div className="file-upload-btn"><i
                                                                            className="fa fa-upload"></i> <Trans>Upload brand description</Trans><input type="file" name="file"
                                                                                               onChange={(event) => this.fileChange(event, 'brand_file')}
                                                                                               accept=".doc,.docx,.xlsx,.ppt,.pdf,.zip,.rar"/>
                                                                        </div>
                                                                        <div className="file-uploader-info"><Trans>Files must be Word, Excel, PPT, PDF, ZIP or RAR and less than 5 MB</Trans>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="name"><Trans>Brand industry</Trans></label><a
                                                                    href="#_"
                                                                    target="_blank"
                                                                    rel="noopener"
                                                                    className="industry-label"><Trans>View prohibited products and services</Trans></a>
                                                                    <select required={true} name="country_code"
                                                                            onChange={(event) => this.inputChangeHandler(event, 'industry')}
                                                                            className="form-control">
                                                                        <option value="">{t("Select")}</option>
                                                                        {this.state.categoryList.map((category, index) => (
                                                                            <option
                                                                                value={category.title}>{category.title}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </>
                                                            }
                                                            <div className="form-group">
                                                                <label htmlFor="name" className="compu_n"><Trans>Product name</Trans></label>
                                                                <div className="input-suffix">
                                                                    <input type="text" name="name" id="productname"
                                                                           className="form-control"
                                                                           value={this.state.product_name}
                                                                           onChange={(event) => this.inputChangeHandler(event, 'product_name')}
                                                                           required={true}
                                                                           placeholder={t("Enter product name")} />
                                                                    <div className="suffix"><span>0/60</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group-wrap">
                                                    <div className="breif-item">
                                                        <div className="item-title"><Trans>Campaign details</Trans></div>
                                                        <div className="item-content">

                                                            <div className="form-group">
                                                                <label htmlFor="name"><Trans>What is your main goal for this campaign</Trans></label>

                                                                <select required={true} name="country_code" onChange={(event) => this.inputChangeHandler(event, 'campaign_goal')} className="form-control" >
                                                                    <option value="">{t("Select")}</option>
                                                                    <option value="More brand exposure" selected={this.state.campaign_goal = "More brand exposure"}>{t("More brand exposure")}</option>
                                                                    <option value="More website traffic" selected={this.state.campaign_goal = "More website traffic"}>{t("More website traffic")}</option>
                                                                    <option value="More exposure or followers for your TikTok account" selected={this.state.campaign_goal = "More exposure or followers for your TikTok account"}>{t("More exposure or followers for your TikTok account")}</option>
                                                                </select>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="name"><Trans>Campaign Start date</Trans></label>
                                                                <input type="date" name="date" id="date"
                                                                       value={this.state.start_date}
                                                                       onChange={(event) => this.inputChangeHandler(event, 'start_date')}
                                                                       required={true}
                                                                       className="form-control" placeholder={t("Campaign Start date")} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="name"><Trans>Campaign End date</Trans></label>
                                                                <input type="date" name="date" id="date"
                                                                       value="2013-01-08"
                                                                       onChange={(event) => this.inputChangeHandler(event, 'end_date')}
                                                                       required={true}
                                                                       className="form-control" placeholder={t("Campaign End date")} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="name"><Trans>Campaign price</Trans><a href="#_"
                                                                                                        className="help-icon-modal"
                                                                                                        data-toggle="modal"
                                                                                                        data-target="#priceGuidlines"><i
                                                                    className="fa fa-question-circle-o"></i></a></label>
                                                                <input type="text" name="date" id="date"
                                                                       value={this.state.price}
                                                                       onChange={(event) => this.inputChangeHandler(event, 'price')}
                                                                       required={true}
                                                                       className="form-control" placeholder="Price" />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="name" className="compu_n"><Trans>Message to creators</Trans></label>

                                                                <div className="input-suffix">
                                                                    <div className="invitation-template">
                                                                        <a href="#" className="use-btn"
                                                                           data-toggle="modal"
                                                                           data-target="#useTemplate"><i
                                                                            className="fa fa-file"></i> <Trans>Use template</Trans></a>
                                                                    </div>
                                                                    <textarea
                                                                        placeholder={t("Send a message about this campaign directly to creators")}
                                                                        className="textarea-inner"
                                                                        value={this.state.message}
                                                                        onChange={(event) => this.inputChangeHandler(event, 'message')}
                                                                        required={true}
                                                                        style={{resize:"none", minHeight:"102px", height:"102px"}}></textarea>
                                                                    <div className="suffix b-0"><span>0/800</span></div>
                                                                </div>
                                                                <div className="file-uploader">
                                                                    <div className="file-upload-btn"><i
                                                                        className="fa fa-upload"></i> <Trans>Upload message</Trans><input type="file" name="file"
                                                                                      onChange={(event) => this.fileChange(event,'message_file')}
                                                                                      accept=".doc,.docx,.xlsx,.ppt,.pdf,.zip,.rar"/></div>
                                                                    <div className="file-uploader-info"><Trans>Files must be Word, Excel, PPT, PDF, ZIP or RAR and less than 5 MB</Trans>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="submit-wrapper">
                                                    <a href="#_" className="btn gray-btn"><Trans>Cancel</Trans></a>
                                                    <button type="submit" className="btn orange-btn"><Trans>Create</Trans></button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="campaign-right-wrapper">
                                    <div className="create-card-panel">
                                        <div className="panel-title title-wrapper"><Trans>Creator list</Trans></div>
                                        <div call="card-panel-body">
                                            {this.state.SelectedCreatorList.length > 0 ?
                                                <div className="flod-list">
                                                    {this.state.SelectedCreatorList.map((creator, index) => (
                                                        <div className="flod-list-item" key={index}>
                                                            <div className="flod-list-item-left">
                                                                <div className="form-group form-check">
                                                                    <label className="form-check-label compu_n"
                                                                           htmlFor="sharecheckbox">{creator.name}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="flod-list-item-right">
                                                                <div className="flod-total">
                                                                    <a href="#_" onClick={() => this.removeCreator(index)}><i className="fa fa-trash"></i> </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                :
                                                <div className="no-creator-img">
                                                    <img src={creatorListImg} alt="img" />
                                                    <div className="no-creator-desc"><Trans>No creators yet</Trans></div>
                                                </div>
                                            }

                                            <div className="shortlist-cart">
                                                <div className="add-cart text-center"><a href="#_" onClick={() => this.removeShortList()} data-toggle="modal"
                                                                                         data-target="#addTolist"><i
                                                    className="fa fa-plus"></i> <Trans>Add creators from shortlist</Trans></a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="kurafat-modal">
                    <div className="modal fade price-guidlines" id="priceGuidlines" tabIndex="-1" role="dialog" aria-labelledby="priceGuidlines" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title"><Trans>Price setting guidelines</Trans></h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="guidline-message">
                                        <p><Trans>Our creators are a vital part of the TikTok community and our overall industry eco-system. Creative work comes in a lot of shapes and forms, and we want our creators to be paid fairly and equally.</Trans></p>
                                        <p className="mt-3 mb-3"></p>
                                        <p><Trans>Here is a simple guide to help you set the price for your creator</Trans>:</p>
                                        <ul>
                                            <li><Trans>Check the creator's overall social presence: A creator who has a large online or celebrity presence will tend to charge a higher rate.</Trans></li>
                                            <li><Trans>You may also refer to the quotation that creator provided: The price is for reference only and subject to creators' changes.Equality-check your offer: Are you paying creators equal amounts across gender, age, race, ability and sexuality?</Trans></li>
                                            <li><Trans>Consider possible expenses the creator might incur to create your video (buying a product and props, travel, etc.).</Trans></li>
                                            <li><Trans>Consider the timeline that you are asking creators to work towards. If you are asking creators to turn around a video in a few days rather than a few weeks, consider the additional work and expense this could incur for them.</Trans></li>
                                            <li><Trans>Remember that your initial brief is what creators will use to determine their fee. If you add additional edits, amend your brief, or move the deadline for a project, you need to consider you are asking for more work from creators, and their fee should be adjusted accordingly.</Trans></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn orange-btn" data-dismiss="modal"><Trans>Cancel</Trans></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="kurafat-modal">
                    <div className="modal fade price-guidlines" id="useTemplate" tabIndex="-1" role="dialog" aria-labelledby="useTemplate" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title"><Trans>Use Template</Trans></h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="template-body d-flex">
                                        <ul id="tabsJustified" className="nav nav-pills flex-column">
                                            <li className="nav-item"><a href="" data-target="#standardTemplate" data-toggle="tab" className="nav-link active"><Trans>Standard template</Trans></a></li>
                                            <li className="nav-item"><a href="" data-target="#customTemplate" data-toggle="tab" className="nav-link" ><Trans>Messages</Trans></a></li>
                                        </ul>
                                        <div className="tab-content p-3 w-100">
                                            <div id="standardTemplate" className="tab-pane fade  active show">
                                                <div className="template-right-title"><Trans>Standard template</Trans></div>
                                                <div className="template-right-content">
                                                    <p><Trans>Hi! I'm</Trans> <strong>{userData.name}</strong> <Trans>from</Trans> <strong>{userData.company_name} </strong>. <Trans>Would you be interested in a paid opportunity on TikTok to promote our product? Let me know if you're interested and I'll share more details about the campaign. Looking forward to hearing from you!</Trans>
                                                    </p>
                                                </div>
                                                {/*<div className="lang-wrapper mt-3">
                                                    <div className="dropdown"  id="navbardrop1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                        <a className="nav-link dropdown-arrow" href="#"><i className="fa fa-globe mr-1"></i> English <span className="fa fa-angle-down"></span></a>
                                                        <div className="dropdown-menu s_language" aria-labelledby="navbardrop1" x-placement="top-start">
                                                            <a className="dropdown-item" href="#">English</a>
                                                            <a className="dropdown-item" href="#">India</a>
                                                            <a className="dropdown-item" href="#">English</a>
                                                            <a className="dropdown-item" href="#">English</a>
                                                            <a className="dropdown-item" href="#">English</a>
                                                            <a className="dropdown-item" href="#">English</a>
                                                            <a className="dropdown-item" href="#">English</a>
                                                            <a className="dropdown-item" href="#">English</a>
                                                            <a className="dropdown-item" href="#">English</a>
                                                        </div>
                                                    </div>
                                                </div>*/}
                                                <div className="template-tab-footer">
                                                    <button type="button" className="btn gray-btn" data-dismiss="modal"><Trans>Cancel</Trans></button>
                                                    <button type="button" className="btn orange-btn" data-dismiss="modal" onClick={() => this.useTemplate(standard_temp)}><Trans>Use template</Trans></button>
                                                </div>
                                            </div>
                                            <div id="customTemplate" className="tab-pane fade">
                                                <div className="template-right-title"><Trans>Custom template</Trans></div>
                                                <div className="template-right-content">
                                                    <p><Trans>Hi! I’m</Trans> <strong place="name">{userData.name}</strong> <Trans>reaching out on behalf of</Trans> <strong place="brand">{userData.company_name} </strong> . <Trans>I've been following your account for some time and we’d love to work with you to promote our</Trans> <strong place="product" className="product">{this.state.product_name} </strong> . <Trans>Let us know if you’re interested!</Trans></p>
                                                </div>
                                                {/*<div className="lang-wrapper mt-3">
                                                    <div className="dropdown" id="navbardrop2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                        <a className="nav-link dropdown-arrow" href="#"><i className="fa fa-globe mr-1"></i> English <span className="fa fa-angle-down"></span></a>
                                                        <div className="dropdown-menu s_language" aria-labelledby="navbardrop2" x-placement="top-start">
                                                            <a className="dropdown-item" href="#">English</a>
                                                            <a className="dropdown-item" href="#">India</a>
                                                            <a className="dropdown-item" href="#">English</a>
                                                            <a className="dropdown-item" href="#">English</a>
                                                            <a className="dropdown-item" href="#">English</a>
                                                            <a className="dropdown-item" href="#">English</a>
                                                            <a className="dropdown-item" href="#">English</a>
                                                            <a className="dropdown-item" href="#">English</a>
                                                            <a className="dropdown-item" href="#">English</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="template-right-error">  <Trans>Enter a product name</Trans></div>*/}
                                                <div className="template-tab-footer">
                                                    <button type="button" className="btn gray-btn" data-dismiss="modal"><Trans>Cancel</Trans></button>
                                                    <button type="button" className="btn orange-btn" data-dismiss="modal" onClick={() => this.toFillIn(message_temp)}><Trans>To fill in</Trans></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="kurafat-modal">
                    <div className="modal fade" id="addTolist" tabIndex="-1" role="dialog"
                         aria-labelledby="addTolistLabel"
                         aria-hidden="true" >
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    {this.state.CurrentShortList.id ?
                                        <div className="shortlist-head-left-campaign">
                                            <div className="brand-back"><a href="#" onClick={() => this.removeShortList()}><i className="fa fa-angle-left"></i></a></div>
                                            <div className="bread-nav"><strong>{this.state.CurrentShortList.name}</strong></div>
                                            <div className="shortlist-head-other">{this.state.CurrentShortList.creator_count} <Trans>Creator(s)</Trans></div>
                                        </div>
                                        : <h5 className="modal-title"><Trans>Shortlist</Trans></h5>
                                    }
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {this.state.CurrentShortList.id ?
                                        <div className="flod-list">
                                            {this.state.CreatorList.map((creator, index) => (
                                                <div className="flod-list-item" key={index}>
                                                    <div className="flod-list-item-left">
                                                        <div className="form-group form-check">
                                                            <label className="form-check-label compu_n"
                                                                   htmlFor="sharecheckbox">{creator.name}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="flod-list-item-right">
                                                        <div className="flod-total">
                                                            {creator.added ? <button className="btn btn-success"><i className="fa fa-check"></i></button> : <button onClick={() => this.selectedCreator(creator)} className="btn btn-secondary"><i className="fa fa-plus"></i></button>}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                            :
                                        <div className="flod-list">
                                            {this.state.shortList.map((list, index) => (
                                                <div className="flod-list-item" key={index}>
                                                    <div className="flod-list-item-left">
                                                        <div className="form-group form-check">
                                                            <label className="form-check-label compu_n"
                                                                   htmlFor="sharecheckbox">{list.name}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="flod-list-item-right">
                                                        <div className="flod-total">
                                                            {list.creator_count}
                                                            <Trans>Creator(s)</Trans>
                                                        </div>
                                                    </div>
                                                    <div className="flod-list-item-right">
                                                        <div className="flod-total">
                                                            <button onClick={() => this.getShortlistCreator(list)} className="btn btn-primary btn-sm"><Trans>Creator List</Trans></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </>
        )
    }
}

export default withTranslation() (withRouter(createCampaign));