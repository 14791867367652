import axios from 'axios';
var base64 = require('base-64');

//export const baseURL = 'https://localhost:3001/v1/';
export const baseURL = 'https://62.171.137.139:3002/v1/';



export const userData = JSON.parse(localStorage.getItem('user'))

export const callAPI = async (url, method, data = null, content_type = null) => {
    try {
        let token = userData ? userData.jwt : '';
        let response =  await axios[method](baseURL + url, data, {
            headers: {
                'Content-Type': content_type,
                'Authorization': token
            }
        });
        return response.data;
    } catch(err) {
        throw err;
    }
}