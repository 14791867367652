'use strict'
import React from 'react';
import logoImg from '../../Assets/images/logo.png';
import signUpImg from '../../Assets/images/signup-img.svg';
import {callAPI} from "../../Commen/CommenFunction";
import $ from 'jquery';
import { withRouter } from "react-router-dom";
import {Trans, withTranslation} from "react-i18next";
import Language from "../Layout/Language";

class SignupLocation extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            state: '',
            loading:false,
        };
    }

    async inputChangeHandler(event, key) {
        const value = event.target.value;

        this.setState(state => {
            return {
                ...state,
                [key]: value
            }
        })
    }

    async componentDidMount() {

    }

    async submitHandler(event) {
        event.preventDefault()
        var formData =$('.needs-validation')[0];
        if (!formData.checkValidity()) {
            formData.classList.add('was-validated')
            return;
        }

        this.setState({
            loading:true
        });

        const form = new FormData()
        form.set('state', this.state.state)

        try {
            const response = await callAPI('auth/updateLocation', 'post', form,'application/json');

            if (response.status === true) {
                this.props.history.push({
                    pathname: '/signup_company',
                });
            } else {
                alert(response.message);
            }
            this.setState({
                loading:false
            });
        } catch (e) {
            console.log(e);
            this.setState({
                loading:false
            });
        }
    }

    render() {
        const {t} = this.props;
        return (
            <>
                <div className="signup-sec">
                    <div className="signup-left">
                        <div className="left-header"><a href="#_"><img src={logoImg} className="logo"
                                                                       alt="logo" /></a></div>
                        <div className="left-body">
                            <div className="left-body-title"><Trans>Find the right creators in Kurafat Creator Marketplace</Trans>
                            </div>
                            <div className="left-body-info"><Trans>The official platform for brand and creator collaborations on Kurafat</Trans>
                            </div>
                            <div className="left-body-img"><img src={signUpImg} className="register-logo"
                                                                alt="" /></div>
                        </div>
                        <div className="left-footer">
                            <div className="left-footer-main">@2021 Kurafat</div>
                            <div className="left-footer-tags">
                                <div className="tag"><a href="#_" target="_blank"><Trans>Brand Terms</Trans></a></div>
                                <div className="tag-split"></div>
                                <div className="tag"><a href="#_" target="_blank"><Trans>Privacy Policy</Trans></a></div>
                            </div>
                        </div>
                    </div>
                    <div className="signup-right">
                        <div className="signup-right-header">
                            <a href="login.html" className="login-text">Log In</a>
                            <Language />
                            <a href="#" className="help"><i className="fa fa-question-circle-o"></i></a>
                        </div>
                        <div className="signup-form">
                            <h5 className="form-title"><Trans>Business location</Trans></h5>
                            <form method="post" className="needs-validation" noValidate={true} onSubmit={(event) => this.submitHandler(event)}>
                                <div className="form-group">
                                    <label htmlFor="name"><Trans>Business country/region</Trans></label>

                                    <select name="country_code" onChange={(event) => this.inputChangeHandler(event, 'state')} className="form-control" >
                                        <option value="">-- {t("Option")} --</option>
                                        <option value="Rajasthan">Rajasthan</option>
                                        <option value="Hariyana">Hariyana</option>
                                        <option value="Delhi">Delhi</option>
                                        <option value="Punjab">Punjab</option>
                                    </select>
                                </div>
                                <button type="submit" className="btn orange-btn"><Trans>Next step</Trans></button>

                            </form>
                        </div>
                    </div>

                </div>
            </>
        )
    }
}

export default withTranslation() (withRouter(SignupLocation));