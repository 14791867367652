import React from 'react';
import {withRouter} from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import {callAPI, userData} from "../../Commen/CommenFunction";
import noDataImg from '../../Assets/images/no-data.png';
import $ from "jquery";
import DataTable from "react-data-table-component";
import {Trans, withTranslation} from "react-i18next";

class CampaignDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            CreatorList:[],
            campaign:[],
            page:1,
            countPerPage:10
        };
    }

    async componentDidMount() {
        let campaign_id = this.props.match.params.campaign_id;
        if (!campaign_id) {
            window.location.href = '/activity';
        }
       await this.CampaignDetail()
       $('.rdt_TableHeader').remove();
    }

    async inputChangeHandler(event, key) {
        const value = event.target.value;

        this.setState(state => {
            return {
                ...state,
                [key]: value
            }
        })
    }

    async CampaignDetail() {
        try {
            const form = new FormData()
            form.set('campaign_id', this.props.match.params.campaign_id)

            const response = await callAPI('user/getCampaignDetail', 'post', form, 'application/json');
            if (response.status === true) {
                this.setState({
                    CreatorList: response.data.creatorLists,
                    campaign: response.data.campaign,
                });
            } else {
                this.setState({
                    CreatorList: [],
                });
            }
            this.setState({
                loading: false
            });
        } catch (e) {
            this.setState({
                loading: false
            });
        }
    }

    async removeFromList(row) {
        try {
            const form = new FormData()
            form.set('creator_id', row.userlist_id)
            form.set('campaign_id', row.campaign_id)

            const response = await callAPI('user/removeFromCampaign', 'post', form, 'application/json');
            if (response.status === true) {
               window.location.reload();
            }
            this.setState({
                loading: false
            });
        } catch (e) {
            this.setState({
                loading: false
            });
        }
    }

    render() {
        const {t} = this.props;
        const columns = [
            {
                name: t('Name'),
                selector: 'name',
            },
            {
                name: t('Username'),
                selector: 'username',
            },
            {
                name: t('Status'),
                cell: row =>  row.status == 1 ? t('Pending') : t('Accept'),
            },
            {
                name: ' ',
                cell: row => <div className="icon-tools">
                    <div className="share-icon">
                        <button type="button" className="share-btn" onClick={() => this.removeFromList(row)}><Trans>Remove</Trans></button>
                    </div>
                </div>,
            },
        ];
        return (
           <>
               <Header />
               <div className="content-wrapper">
                   <div className="wrapper-inner">
                       <div className="container">
                           <div className="account d-flex">
                               <div className="tab-content w-100">
                                   <div id="campaignmanagement" className="tab-pane tab-content-box fade pt-2 active show">
                                       <div className="item-wrapper header-wrap">
                                           <div className="header-search">
                                               <div className="b-number-wrap">
                                                   <ul id="tabs" className="nav nav-pills">
                                                       <li className="nav-item"><a href="" data-target="#detail"
                                                                                   data-toggle="tab"
                                                                                   className="nav-link active"><Trans>Detail</Trans></a>
                                                       </li>
                                                       <li className="nav-item"><a href="" data-target="#creator"
                                                                                   data-toggle="tab"
                                                                                   className="nav-link"><Trans>Creator</Trans> <span
                                                           className="badge-number">{this.state.CreatorList.length}</span></a></li>
                                                   </ul>
                                               </div>
                                           </div>
                                           <div className="tab-content">
                                               <div id="detail" className="tab-pane fade active show header-filter">
                                                   <div className="campaign-detail">
                                                       <div className="campaign-data">
                                                           <div className="campaign-item">
                                                               <div className="item-title"><strong><Trans>Title</Trans>:</strong> </div>
                                                               <div className="item-data">{this.state.campaign.title}</div>
                                                           </div>
                                                           <div className="campaign-item">
                                                               <div className="item-title"><strong><Trans>Contact Name</Trans>:</strong> </div>
                                                               <div className="item-data">{this.state.campaign.contact_name}</div>
                                                           </div>
                                                           <div className="campaign-item">
                                                               <div className="item-title"><strong><Trans>Contact Email</Trans>:</strong> </div>
                                                               <div className="item-data">{this.state.campaign.contact_email}</div>
                                                           </div>
                                                           <div className="campaign-item">
                                                               <div className="item-title"><strong><Trans>Contact Number</Trans>:</strong> </div>
                                                               <div className="item-data">{this.state.campaign.contact_number}</div>
                                                           </div>
                                                           <div className="campaign-item">
                                                               <div className="item-title"><strong><Trans>Product Name</Trans>:</strong> </div>
                                                               <div className="item-data">{this.state.campaign.product_name}</div>
                                                           </div>
                                                           <div className="campaign-item">
                                                               <div className="item-title"><strong><Trans>Goal</Trans>:</strong> </div>
                                                               <div className="item-data">{this.state.campaign.goal}</div>
                                                           </div>
                                                           <div className="campaign-item">
                                                               <div className="item-title"><strong><Trans>Start Date</Trans>:</strong> </div>
                                                               <div className="item-data">{this.state.campaign.start_date}</div>
                                                           </div>
                                                           <div className="campaign-item">
                                                               <div className="item-title"><strong><Trans>End Date</Trans>:</strong> </div>
                                                               <div className="item-data">{this.state.campaign.end_date}</div>
                                                           </div>
                                                           <div className="campaign-item">
                                                               <div className="item-title"><strong><Trans>Price</Trans>:</strong> </div>
                                                               <div className="item-data">{this.state.campaign.price}</div>
                                                           </div>
                                                           <div className="campaign-item">
                                                               <div className="item-title"><strong><Trans>Message</Trans>:</strong> </div>
                                                               <div className="item-data">{this.state.campaign.message}</div>
                                                           </div>
                                                       </div>
                                                   </div>
                                               </div>
                                               <div id="creator" className="tab-pane fade header-filter">
                                                   {this.state.CreatorList.length && this.state.CreatorList.length > 0  ?
                                                       <DataTable
                                                           title={t("Draft Campaign List")}
                                                           columns={columns}
                                                           data={this.state.CreatorList}
                                                           highlightOnHover
                                                           pagination
                                                           paginationServer
                                                           paginationTotalRows={this.state.CreatorList.length}
                                                           paginationPerPage={this.state.countPerPage}
                                                           paginationComponentOptions={{
                                                               noRowsPerPage: true
                                                           }}
                                                       /> :
                                                       <div className="no-data">
                                                           <img src={noDataImg} alt="img"/>
                                                           <p className="desc"><Trans>No data available</Trans></p>
                                                       </div>
                                                   }
                                       .        </div>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
               <Footer />
           </>
        )
    }
}

export default withTranslation() (withRouter(CampaignDetails));