import React from 'react';
import {withRouter} from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import {callAPI, userData} from "../../Commen/CommenFunction";
import bannerImg from '../../Assets/images/banner-img.JPG';
import noDataImg from '../../Assets/images/no-data.png';
import videoIcon from '../../Assets/images/video-icon.png';
import uIcon from '../../Assets/images/uicon.png';
import DataTable from "react-data-table-component";
import $ from "jquery";
import {Trans, withTranslation} from "react-i18next";

class Activity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            campaignList: [],
            draftCampaignList: [],
            campaignReporting: [],
            pageData: [],
            username: '',
            status: '',
            page: 1,
            selectedId: 1,
            countPerPage: 10
        };
    }

    async componentDidMount() {
        await this.getCampaign(this.state.page, this.state.username, this.state.status)
        await this.getDraftCampaign();
        await this.getCampaignReporting();
    }

    async inputChangeHandler(event, key) {
        const value = event.target.value;

        this.setState(state => {
            return {
                ...state,
                [key]: value
            }
        })

        if (key == 'username') {
            await this.getCampaign(this.state.page, value, this.state.status)
        }
        if (key == 'status') {
            await this.getCampaign(this.state.page, this.state.username, value)
        }
    }

    async getCampaign(page, username, status) {
        try {
            const form = new FormData()
            form.set('page', page)
            form.set('username', username)
            form.set('status', status)

            const response = await callAPI('user/getCampaignList', 'post', form, 'application/json');

            if (response.status === true) {
                this.setState({
                    campaignList: response.data,
                });
            } else {
                this.setState({
                    campaignList: [],
                });
            }
            this.setState({
                loading: false
            });
        } catch (e) {
            this.setState({
                loading: false
            });
        }
    }

    async getDraftCampaign() {
        try {
            const form = new FormData()
            const response = await callAPI('user/getDraftCampaignList', 'post', form, 'application/json');

            if (response.status === true) {
                this.setState({
                    draftCampaignList: response.data,
                });
            } else {
                this.setState({
                    draftCampaignList: [],
                });
            }
            this.setState({
                loading: false
            });
        } catch (e) {
            this.setState({
                loading: false
            });
        }
    }

    async getCampaignReporting() {
        try {
            const form = new FormData()
            const response = await callAPI('user/campaignReporting', 'post', form, 'application/json');

            if (response.status === true) {
                this.setState({
                    campaignReporting: response.data,
                });
            } else {
                this.setState({
                    campaignReporting: [],
                });
            }
            this.setState({
                loading: false
            });
        } catch (e) {
            this.setState({
                loading: false
            });
        }
    }

    async setPage(page) {
        await this.getCampaign(page, this.state.username, this.state.status)
    }

    async deleteCampaign() {
        this.setState({
            loading: true
        });
        const form = new FormData()
        form.set('id', this.state.selectedId ? this.state.selectedId : '')
        try {
            const response = await callAPI('user/deleteCampaign', 'post', form, 'application/json');
            if (response.status === true) {
                await this.getCampaign(1, this.state.username, this.state.status)
                $('.close-list-modal').trigger('click');
            } else {
                alert(response.message)
            }
            this.setState({
                loading: false
            });
        } catch (e) {
            this.setState({
                loading: false
            });
        }
    }

    async selectedList(list) {
        this.setState({
            selectedId: list.id,
        });
    }

    render() {
        const {t} = this.props;

        const columns = [
            {
                name: t('title'),
                selector: 'title',
            },
            {
                name: t('Contact Name'),
                selector: 'contact_name',
            },
            {
                name: t('Contact Email'),
                selector: 'contact_email',
            },
            {
                name: t('Contact Number'),
                selector: 'contact_number',
            },
            {
                name: t('Creator'),
                selector: 'creator_count',
            },
            {
                name: t('Product Name'),
                selector: 'product_name',
            },
            {
                name: t('Price'),
                selector: 'price',
            },
            {
                name: '',
                cell: row => <div className="icon-tools">
                    <div className="ellipse-icon dropdown">
                        <a className="nav-link" href="#" role="button" data-toggle="dropdown" aria-haspopup="true"
                           aria-expanded="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                <path
                                    d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                            </svg>
                        </a>
                        <div className="dropdown-menu">
                            <div className="help-container">
                                <ul className="help-list">
                                    <li className="help-item"><a href={'/campaignDetail/' + row.id}
                                                                 className="help-link"><Trans>Detail</Trans></a></li>
                                    <li className="help-item"><a href={'/updateCampaign/' + row.id}
                                                                 className="help-link"><Trans>Edit</Trans></a></li>
                                    <li className="help-item"><a href="#_" onClick={() => this.selectedList(row)}
                                                                 className="help-link" data-toggle="modal"
                                                                 data-target="#listDelete"><Trans>Delete</Trans></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>,
            },
        ];

        const draftColumns = [
            {
                name: t('Title'),
                selector: 'title',
            },
            {
                name: t('Contact Name'),
                selector: 'contact_name',
            },
            {
                name: t('Contact Email'),
                selector: 'contact_email',
            },
            {
                name: t('Contact Number'),
                selector: 'contact_number',
            },
            {
                name: t('Product Name'),
                selector: 'product_name',
            },
            {
                name: t('Price'),
                selector: 'price',
            },
            {
                name: '',
                cell: row => <div className="icon-tools">
                    <div className="ellipse-icon dropdown">
                        <a className="nav-link" href="#" role="button" data-toggle="dropdown" aria-haspopup="true"
                           aria-expanded="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                <path
                                    d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                            </svg>
                        </a>
                        <div className="dropdown-menu">
                            <div className="help-container">
                                <ul className="help-list">
                                    <li className="help-item"><a href={'/campaignDetail/' + row.id}
                                                                 className="help-link"><Trans>Detail</Trans></a></li>
                                    <li className="help-item"><a href={'/updateCampaign/' + row.id}
                                                                 className="help-link"><Trans>Edit</Trans></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>,
            },
        ];
        return (
            <>
                <Header/>
                <div className="content-wrapper">
                    <div className="wrapper-inner">
                        <div className="container">
                            <div className="account d-flex">
                                <div className="star-nav nav-wrapper">
                                    <ul id="tabsJustified" className="nav nav-pills flex-column">
                                        <li className="nav-item"><a href="" data-target="#dashboard" data-toggle="tab"
                                                                    className="nav-link active"><Trans>Dashboard</Trans></a>
                                        </li>
                                        <li className="nav-item"><a href="" data-target="#campaignmanagement"
                                                                    data-toggle="tab" className="nav-link"><Trans>Campaign
                                            Management</Trans></a></li>
                                        <li className="nav-item"><a href="" data-target="#campaignreporting"
                                                                    data-toggle="tab" className="nav-link"><Trans>Campaign
                                            Reporting</Trans></a></li>
                                    </ul>
                                </div>
                                <div className="tab-content w-100">
                                    <div id="dashboard" className="tab-pane fade active show">
                                        <div className="dashboard-section tab-content-box">
                                            <div className="dashboard-username"><Trans>Hi</Trans>, {userData.name}</div>
                                            <div className="dashboard-info"><Trans>User ID</Trans>: {userData.user_uuid}
                                            </div>
                                        </div>
                                        <div className="dashboard-section">
                                            <div id="carouselExampleIndicators"
                                                 className="carousel slide operation-banner" data-ride="carousel">
                                                <ol className="carousel-indicators">
                                                    <li data-target="#carouselExampleIndicators" data-slide-to="0"
                                                        className="active"></li>
                                                </ol>
                                                <div className="carousel-inner">
                                                    <div className="carousel-item active">
                                                        <img className="d-block w-100" src={bannerImg}
                                                             alt="First slide"/>
                                                    </div>
                                                </div>
                                                <a className="carousel-control-prev" href="#carouselExampleIndicators"
                                                   role="button" data-slide="prev">
                                                   <span className="carousel-control-prev-icon"
                                                         aria-hidden="true"></span>
                                                    <span className="sr-only"><Trans>Previous</Trans></span>
                                                </a>
                                                <a className="carousel-control-next" href="#carouselExampleIndicators"
                                                   role="button" data-slide="next">
                                                   <span className="carousel-control-next-icon"
                                                         aria-hidden="true"></span>
                                                    <span className="sr-only"><Trans>Next</Trans></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="campaignmanagement" className="tab-pane tab-content-box fade pt-2">
                                        <div className="item-wrapper header-wrap">
                                            <div className="header-search">
                                                <div className="b-number-wrap">
                                                    <ul id="tabs" className="nav nav-pills">
                                                        <li className="nav-item"><a href="" data-target="#public"
                                                                                    data-toggle="tab"
                                                                                    className="nav-link active"><Trans>Public</Trans></a>
                                                        </li>
                                                        <li className="nav-item"><a href="" data-target="#draft"
                                                                                    data-toggle="tab"
                                                                                    className="nav-link"><Trans>Draft</Trans>
                                                            <span
                                                                className="badge-number">{this.state.draftCampaignList.length}</span></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="tab-content">
                                                <div id="public" className="tab-pane fade active show header-filter">
                                                    <div className="demand-dot-intro">
                                                        <div className="status-item status-pending">
                                                            <div className="status-dot status-pending"></div>
                                                            <div className="status-content"><Trans>Pending</Trans></div>
                                                        </div>
                                                        <div className="status-item status-active">
                                                            <div className="status-dot status-active"></div>
                                                            <div className="status-content"><Trans>Verified</Trans>
                                                            </div>
                                                        </div>
                                                        <div className="status-item status-complete">
                                                            <div className="status-dot status-complete"></div>
                                                            <div className="status-content"><Trans>Completed</Trans>
                                                            </div>
                                                        </div>
                                                        <div className="status-item status-close">
                                                            <div className="status-dot status-close"></div>
                                                            <div className="status-content"><Trans>Canceled</Trans>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="public-inner">
                                                            <div className="public-inner-creators">
                                                                <div className="form-group">
                                                                    <div className="dropwithinput">
                                                                        <div className="input-suffix">
                                                                            <div className="select-media dropdown">
                                                                                <a className="media-option" href="#"
                                                                                   id="dropdownMenuButton"
                                                                                   data-toggle="dropdown"
                                                                                   aria-haspopup="true"
                                                                                   aria-expanded="false">
                                                                                   <span
                                                                                       className="trigger-placeholder"><Trans>Creator(s)</Trans></span>
                                                                                    <i className="fa fa-angle-down"></i>
                                                                                </a>
                                                                                <div className="dropdown-menu"
                                                                                     aria-labelledby="dropdownMenuButton">
                                                                                    <a className="dropdown-item"
                                                                                       href="#">Creator(s)</a>
                                                                                    <a className="dropdown-item"
                                                                                       href="#"><Trans>Campaign
                                                                                        name</Trans></a>
                                                                                    <a className="dropdown-item"
                                                                                       href="#"><Trans>Campaign
                                                                                        ID</Trans></a>
                                                                                    <a className="dropdown-item"
                                                                                       href="#"><Trans>Order ID</Trans></a>
                                                                                </div>
                                                                            </div>
                                                                            {/*<select name="order-status" onChange={(event) => this.inputChangeHandler(event, 'order_status')} className="form-control" >
                                                                               <option value="0">Creator(s)</option>
                                                                               <option value="1">Campaign name</option>
                                                                               <option value="2">Campaign ID</option>
                                                                               <option value="3">Order ID</option>
                                                                           </select>*/}
                                                                            <input type="text" name="userename"
                                                                                   id="username"
                                                                                   className="form-control media-user"
                                                                                   placeholder="Search"
                                                                                   value={this.state.username}
                                                                                   onChange={(event) => this.inputChangeHandler(event, 'username')}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="order-status">
                                                                <select name="order-status"
                                                                        onChange={(event) => this.inputChangeHandler(event, 'status')}
                                                                        className="form-control">
                                                                    <option value="">Order Status</option>
                                                                    <option value="0">{t("Pending")}</option>
                                                                    <option value="1">{t("Confirmed")}</option>
                                                                    <option value="2">{t("Completed")}</option>
                                                                    <option value="3">{t("Cancelled")}</option>
                                                                </select>
                                                            </div>
                                                            <a href="#_" className="clear"><i
                                                                className="fa fa-eraser"></i><Trans>clear</Trans></a>
                                                        </div>
                                                        {this.state.campaignList.total_record && this.state.campaignList.total_record > 0 ?
                                                            <DataTable
                                                                title={t("Campaign List")}
                                                                columns={columns}
                                                                data={this.state.campaignList.data}
                                                                highlightOnHover
                                                                pagination
                                                                paginationServer
                                                                paginationTotalRows={this.state.campaignList.total_record}
                                                                paginationPerPage={this.state.countPerPage}
                                                                paginationComponentOptions={{
                                                                    noRowsPerPage: true
                                                                }}
                                                                onChangePage={page => this.setPage(page)}
                                                            /> :
                                                            <div className="no-data">
                                                                <img src={noDataImg} alt="img"/>
                                                                <p className="desc"><Trans>No data available</Trans></p>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div id="draft" className="tab-pane fade header-filter">
                                                    <div className="draft-inner">
                                                        <div className="search-bar-inner">
                                                            <input type="text" className="form-control input-search"
                                                                   name="search" id="search"
                                                                   placeholder="Campaign name"/>
                                                            <span className="input-icon"><i
                                                                className="fa fa-search"></i></span>
                                                        </div>

                                                    </div>
                                                    {this.state.draftCampaignList.length && this.state.draftCampaignList.length > 0 ?
                                                        <DataTable
                                                            title={t("Draft Campaign List")}
                                                            columns={draftColumns}
                                                            data={this.state.draftCampaignList}
                                                            highlightOnHover
                                                            pagination
                                                            paginationServer
                                                            paginationTotalRows={this.state.campaignList.length}
                                                            paginationPerPage={this.state.countPerPage}
                                                            paginationComponentOptions={{
                                                                noRowsPerPage: true
                                                            }}
                                                        /> :
                                                        <div className="no-data">
                                                            <img src={noDataImg} alt="img"/>
                                                            <p className="desc"><Trans>No data available</Trans></p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="campaignreporting" className="tab-pane fade">
                                        <div className="campaign-reporting tab-content-box">
                                            <div className="title-wrapper panel-title"><Trans>Campaign Reporting</Trans>
                                                <span class="reporting-sub">Updated as of 06/22/2021(UTC)，Data is updated daily</span>
                                            </div>
                                            <div className="reporting-inner">
                                                <div className="form-group">
                                                    <div className="dropwithinput">
                                                        <div className="input-suffix">
                                                            <div className="select-media dropdown">
                                                                <a className="media-option" href="#"
                                                                   id="dropdownMenuButtonnew" data-toggle="dropdown"
                                                                   aria-haspopup="true" aria-expanded="false">
                                                                   <span
                                                                       className="trigger-placeholder"><Trans>Campaign name</Trans></span>
                                                                    <i className="fa fa-angle-down"></i>
                                                                </a>
                                                                <div className="dropdown-menu"
                                                                     aria-labelledby="dropdownMenuButton">
                                                                    <a className="dropdown-item"
                                                                       href="#"><Trans>Campaign name</Trans></a>
                                                                    <a className="dropdown-item"
                                                                       href="#"><Trans>Campaign ID</Trans></a>
                                                                    <a className="dropdown-item"
                                                                       href="#"><Trans>Creator Name</Trans></a>
                                                                </div>
                                                            </div>
                                                            <input type="text" name="userename" id="searchinput"
                                                                   className="form-control media-user"
                                                                   placeholder="Enter your campaign name"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.campaignReporting.map((reporting, index) => (
                                            <div className="tab-content-box k-campaign-list">
                                                    <div className="title-wrapper panel-title">{reporting.title}
                                                    <span className="reporting-sub">Campaign ID:6881516778124476417<span className="k-time-info">{reporting.cfreated_at}</span></span>
                                                    <div className="card-panel-body">
                                                        <div className="k-campaign-stat">
                                                            <table>
                                                                <thead>
                                                                <tr>
                                                                    <th className="views">{reporting.view_count}</th>
                                                                    <th className="likes">{reporting.like_count}</th>
                                                                    <th className="comments">{reporting.comment_count}</th>
                                                                    <th className="shares">{reporting.share_count}</th>
                                                                    <th className="avg">1.83%</th>
                                                                    <th width="36px"></th>
                                                                    <th className="videos">{reporting.video_count}</th>
                                                                    <th width="36px"></th>
                                                                    <th className="creators">{reporting.creator_count}</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                <tr>
                                                                    <td>Total Views</td>
                                                                    <td>Likes</td>
                                                                    <td>Comments</td>
                                                                    <td>Shares</td>
                                                                    <td>Average Engagement Rate</td>
                                                                    <td><img width="24" height="24"
                                                                             src={videoIcon}
                                                                             className="place-image" alt="icon" /></td>
                                                                    <td>Number of videos</td>
                                                                    <td><img width="24" height="24" src={uIcon}
                                                                             className="place-image" alt="" /></td>
                                                                    <td>Number of creators</td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <div className="tab-content-box k-pagination">
                                            <div className="r-pagination">
                                                <nav aria-label="Page navigation example">
                                                    <ul className="pagination justify-content-center">
                                                        <li className="page-item disabled">
                                                            <a className="page-link" href="#" tabIndex="-1">Previous</a>
                                                        </li>
                                                        <li className="page-item"><a className="page-link"
                                                                                     href="#">1</a></li>
                                                        <li className="page-item"><a className="page-link"
                                                                                     href="#">2</a></li>
                                                        <li className="page-item">
                                                            <a className="page-link" href="#">Next</a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="kurafat-modal">
                    <div className="modal fade" id="listDelete" tabIndex="-1" role="dialog"
                         aria-labelledby="listDeleteLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title"><Trans>Are you sure you want to delete this
                                        list?</Trans></h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p><Trans>Your creator selections will not be saved after you delete this
                                        list.</Trans></p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn gray-btn close-list-modal"
                                            data-dismiss="modal"><Trans>Cancel</Trans></button>
                                    <button type="button" className="btn orange-btn"
                                            onClick={() => this.deleteCampaign()}><Trans>Confirm</Trans></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </>
        )
    }
}

export default withTranslation()(withRouter(Activity));
