import React from 'react';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import './Assets/css/bootstrap.min.css';
import './Assets/js/custom';
import './Assets/css/font-awesome.min.css';
import './Assets/css/style.css';

import Routes from "./Commen/Routes";
import withClearCache from "./ClearCache";
import { messaging } from "./init-fcm";
import i18next from "i18next";

const ClearCacheComponent = withClearCache(MainApp);

const App =() =>{
  return <ClearCacheComponent />;

};

function MainApp() {
    let lang_shortname = "en";
    let language =  JSON.parse(localStorage.getItem('language'));
    if (language) {
        lang_shortname = language.value;
    }
    i18next.changeLanguage(lang_shortname)
    if (messaging) {
        messaging.requestPermission()
            .then(async function() {
                const token = await messaging.getToken();
                localStorage.setItem('fcm-token', token);
            })
            .catch(function(err) {
                console.log("Unable to get permission to notify.", err);
            });
        navigator.serviceWorker.addEventListener("message", (message) => console.log(message));

        messaging.onMessage((payload) => console.log('Message received. ', payload));
    }

  return(
      <Routes/>
  );
}

export default App;