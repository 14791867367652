import React from 'react';
import { withRouter } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import logoImg from '../../Assets/images/logo.png';
import shape1Img from '../../Assets/images/shape1.png';
import shape2Img from '../../Assets/images/shape2.png';
import shape3Img from '../../Assets/images/shape3.png';
import shape4Img from '../../Assets/images/shape4.png';
import bgImg from '../../Assets/images/bg-img.png';
import playIcon1 from '../../Assets/images/play-icon1.png';
import playIcon2 from '../../Assets/images/play-icon2.png';
import playIcon3 from '../../Assets/images/play-icon3.png';
import playIcon4 from '../../Assets/images/play-icon4.png';
import playIcon5 from '../../Assets/images/play-icon5.png';
import boyIcon from '../../Assets/images/boy-icon.png';
import smilyIcon from '../../Assets/images/smily-icon.png';
import thumbIcon from '../../Assets/images/thumb-icon.png';
import img1 from '../../Assets/images/img1.jpg';
import blueCircleIcon from '../../Assets/images/blue-circle.png';
import fillBlueIcon from '../../Assets/images/fill-blue.png';
import fillYellowIcon from '../../Assets/images/fill-yellow.png';
import itemIcon1 from '../../Assets/images/item-icon.png';
import itemIcon2 from '../../Assets/images/item-icon2.png';
import itemIcon3 from '../../Assets/images/item-icon3.png';
import video1 from '../../Assets/images/video1.mp4';
import playSvg from '../../Assets/images/play.svg';
import heartSvg from '../../Assets/images/heart.svg';
import playButton from '../../Assets/images/play-button.png';
import videoPlayAnimate from "../../Assets/images/video'.mp4"
import {callAPI} from "../../Commen/CommenFunction";
import { Trans } from "react-i18next";

class Home extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            users: [],
            videos1:[],
            videos2:[],
            home:[],
        };
    }

    async componentDidMount() {
        try {
            const response = await callAPI('user/getUsersForHomePage', 'get', null,'application/json');
            if (response.status === true) {
                this.setState({
                    users:response.data.users,
                    videos1:response.data.videos.splice(-4, 4),
                    videos2:response.data.videos.splice(0, 4),
                    home:response.data
                });
            } else {
                this.setState({
                    users:[],
                    videos:[],
                });
            }
            this.setState({
                loading:false
            });
        } catch (e) {
            console.log(e);
            this.setState({
                loading:false
            });
        }
    }

    render() {
        return (
            <>
                <Header />
                <div className="banner">
                    <div className="container">
                        <div className="banner-text">
                            <h3 className="title">
                                <Trans>Find the right creators</Trans>
                            </h3>
                            <a href="market-after-activate.html" className="btn btn-banner"><Trans>Find now</Trans></a>
                        </div>
                    </div>
                </div>
                <section className="k_wrap k_wrap-01">  `0
                    <div className="container">
                        <video preload="auto" autoPlay="autoplay" muted="muted" src={videoPlayAnimate}></video>
                        <div className="content">
                            <div className="logo"><img src={logoImg} alt="logo" className="logo-img" /></div>
                            <h3 className="title">
                                <Trans>The official platform for brand and creator collaborations on Kurafat</Trans>
                            </h3>
                        </div>
                        <img className="shape1" src={shape1Img} alt="shape" />
                        <img className="shape2" src={shape2Img} alt="shape" />
                        <img className="shape3" src={shape3Img} alt="shape" />
                        <img className="shape4" src={shape4Img} alt="shape"/>
                    </div>
                </section>
                <section className="k_wrap k_wrap-02">
                    <div className="container">
                        <img className="bgimg" src={bgImg} alt="Image" />
                            <div className="content">
                                <h3 className="title">
                                    <Trans>Choose the best partners based on data</Trans>
                                </h3>
                                <p className="text">
                                    <Trans>Tap Description.</Trans>
                                </p>
                            </div>
                            <div className="views">
                                <div className="top-wrap">
                                    <div className="top-item">
                                        <img src={playIcon1} alt="icon" className="icon"/>
                                        <span className="num">{this.state.home.view_count}</span>
                                    </div>
                                    <div className="top-item">
                                        <img src={playIcon2} alt="icon" className="icon"/>
                                        <span className="num">{this.state.home.like_count}</span>
                                    </div>
                                    <div className="top-item">
                                        <img src={playIcon3} alt="icon" className="icon"/>
                                        <span className="num">{this.state.home.comment_count}</span>
                                    </div>
                                    <div className="top-item">
                                        <img src={playIcon4} alt="icon" className="icon"/>
                                        <span className="num">{this.state.home.share_count}</span>
                                    </div>
                                    <div className="top-item">
                                        <img src={playIcon5} alt="icon" className="icon"/>
                                        <span className="num">4.5%</span>
                                    </div>
                                </div>
                                <div className="charts">

                                    <div className="chart-item chart-item-01">
                                        <div className="chart-item-wrap">
                                            <div className="chart-01">
                                                <div className="svg-item">
                                                    <svg width="100%" height="100%" viewBox="0 0 40 40" className="donut">
                                                        <circle className="donut-hole" cx="20" cy="20" r="15.91549430918954"
                                                                fill="#fff"></circle>
                                                        <circle className="donut-ring" cx="20" cy="20" r="15.91549430918954"
                                                                fill="transparent" strokeWidth="3.5"></circle>
                                                        <circle className="donut-segment donut-segment-2" cx="20" cy="20"
                                                                r="15.91549430918954" fill="transparent" strokeWidth="3.5"
                                                                strokeDasharray="69 31" strokeDashoffset="25"></circle>
                                                    </svg>
                                                </div>
                                                <img className="icon" src={boyIcon} alt="icon"/>
                                                <div className="data"><span className="blue">67.7%</span><span
                                                    className="red">32.3%</span></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="chart-item chart-item-02">
                                        <div className="chart-item-wrap">
                                            <div className="chart-02">
                                                <svg data-v-ee7b971e="" xmlns="http://www.w3.org/2000/svg" version="1.1"
                                                     baseProfile="full" width="280" height="240" style={{pointerEvents:"none"}}>
                                                    <path data-v-ee7b971e="" d="M 28 180.5 L 252 180.5" fill="none"
                                                          stroke="#F5F5F5" strokeWidth="1" paintOrder="fill"
                                                          strokeOpacity="1" strokeDasharray="" strokeLinecap="butt"
                                                          strokeLinejoin="miter" strokeMiterlimit="10"
                                                          clipPath="none"></path>
                                                    <path data-v-ee7b971e="" d="M 28 150.5 L 252 150.5" fill="none"
                                                          stroke="#F5F5F5" strokeWidth="1" paintOrder="fill"
                                                          strokeOpacity="1" strokeDasharray="" strokeLinecap="butt"
                                                          strokeLinejoin="miter" strokeMiterlimit="10"
                                                          clipPath="none"></path>
                                                    <path data-v-ee7b971e="" d="M 28 120.5 L 252 120.5" fill="none"
                                                          stroke="#F5F5F5" strokeWidth="1" paintOrder="fill"
                                                          strokeOpacity="1" strokeDasharray="" strokeLinecap="butt"
                                                          strokeLinejoin="miter" strokeMiterlimit="10"
                                                          clipPath="none"></path>
                                                    <path data-v-ee7b971e="" d="M 28 90.5 L 252 90.5" fill="none"
                                                          stroke="#F5F5F5" strokeWidth="1" paintOrder="fill"
                                                          strokeOpacity="1" strokeDasharray="" strokeLinecap="butt"
                                                          strokeLinejoin="miter" strokeMiterlimit="10"
                                                          clipPath="none"></path>
                                                    <path data-v-ee7b971e="" d="M 28 60.5 L 252 60.5" fill="none"
                                                          stroke="#F5F5F5" strokeWidth="1" paintOrder="fill"
                                                          strokeOpacity="1" strokeDasharray="" strokeLinecap="butt"
                                                          strokeLinejoin="miter" strokeMiterlimit="10"
                                                          clipPath="none"></path>
                                                    <path data-v-ee7b971e=""
                                                          d="M 46.6667 140 L 46.6667 140 A 7 7 0 0 1 53.6667 147 L 53.6667 173 A 7 7 0 0 1 46.6667 180 L 46.6667 180 A 7 7 0 0 1 39.6667 173 L 39.6667 147 A 7 7 0 0 1 46.6667 140 Z"
                                                          fill="#5783FC" stroke="none" clipPath="none"
                                                          className="bar-item go-live"></path>
                                                    <path data-v-ee7b971e=""
                                                          d="M 84 62 A 7 7 0 0 1 91 69 L 91 173 A 7 7 0 0 1 84 180 L 84 180 A 7 7 0 0 1 77 173 L 77 69 A 7 7 0 0 1 84 62 Z"
                                                          stroke="none" clipPath="none" fill="url(#zr2322-gradient-0)"
                                                          className="bar-item go-live"></path>
                                                    <path data-v-ee7b971e=""
                                                          d="M 121.3333 116 L 121.3333 116 A 7 7 0 0 1 128.3333 123 L 128.3333 173 A 7 7 0 0 1 121.3333 180 L 121.3333 180 A 7 7 0 0 1 114.3333 173 L 114.3333 123 A 7 7 0 0 1 121.3333 116 Z"
                                                          fill="#5783FC" stroke="none" clipPath="none"
                                                          className="bar-item go-live"></path>
                                                    <path data-v-ee7b971e=""
                                                          d="M 158.6667 86 L 158.6667 86 A 7 7 0 0 1 165.6667 93 L 165.6667 173 A 7 7 0 0 1 158.6667 180 L 158.6667 180 A 7 7 0 0 1 151.6667 173 L 151.6667 93 A 7 7 0 0 1 158.6667 86 Z"
                                                          fill="#5783FC" stroke="none" clipPath="none"
                                                          className="bar-item go-live"></path>
                                                    <path data-v-ee7b971e=""
                                                          d="M 196 76 L 196 76 A 7 7 0 0 1 203 83 L 203 173 A 7 7 0 0 1 196 180 L 196 180 A 7 7 0 0 1 189 173 L 189 83 A 7 7 0 0 1 196 76 Z"
                                                          fill="#E9EAEF" stroke="none" clipPath="none"
                                                          className="bar-item go-live"></path>
                                                    <path data-v-ee7b971e=""
                                                          d="M 233.3333 129 L 233.3333 129 A 7 7 0 0 1 240.3333 136 L 240.3333 173 A 7 7 0 0 1 233.3333 180 L 233.3333 180 A 7 7 0 0 1 226.3333 173 L 226.3333 136 A 7 7 0 0 1 233.3333 129 Z"
                                                          fill="#E9EAEF" stroke="none" clipPath="none"
                                                          className="bar-item go-live"></path>
                                                    <defs data-v-ee7b971e="">
                                                        <linearGradient data-v-ee7b971e="" id="zr2322-gradient-0" x1="0" y1="0"
                                                                        x2="0" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop data-v-ee7b971e="" offset="0%" stopColor="#ffe425"
                                                                  stopOpacity="1"></stop>
                                                            <stop data-v-ee7b971e="" offset="100%" stopColor="#ffc410"
                                                                  stopOpacity="1"></stop>
                                                        </linearGradient>
                                                    </defs>
                                                </svg>

                                                <div className="bottom-bar"><img src={smilyIcon} className="icon"
                                                                                 alt="icon"/>
                                                    <div className="line">
                                                        <div className="line-01"></div>
                                                        <div className="line-02"></div>
                                                    </div></div>
                                                <div className="tag">13%</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="chart-item chart-item-03">
                                        <div className="chart-item-wrap">
                                            <div className="chart-03">
                                                <svg data-v-60ab8591="" xmlns="http://www.w3.org/2000/svg" version="1.1"
                                                     baseProfile="full" width="280" height="240"
                                                     style={{userSelect:"none", position:"absolute", left:"0px", top:"0px"}}>
                                                    <path data-v-60ab8591=""
                                                          d="M 30 75 L 44.1154 75 A 2 2 0 0 1 46.1154 77 L 46.1154 178 A 2 2 0 0 1 44.1154 180 L 30 180 A 2 2 0 0 1 28 178 L 28 77 A 2 2 0 0 1 30 75 Z"
                                                          fillOpacity="undefined" stroke="none" clipPath="none"
                                                          fill="url(#zr2375-gradient-0)" className="bar-item go-live"></path>
                                                    <path data-v-60ab8591=""
                                                          d="M 44.3462 180 L 63.3462 180 L 63.3462 180 L 44.3462 180 L 44.3462 180 Z"
                                                          fill="#c23531" fillOpacity="undefined" stroke="none" clipPath="none"
                                                          className="bar-item go-live"></path>
                                                    <path data-v-60ab8591=""
                                                          d="M 63.5769 75 L 78.5769 75 A 2 2 0 0 1 80.5769 77 L 80.5769 178 A 2 2 0 0 1 78.5769 180 L 63.5769 180 A 2 2 0 0 1 61.5769 178 L 61.5769 77 A 2 2 0 0 1 63.5769 75 Z"
                                                          fillOpacity="undefined" stroke="none" clipPath="none"
                                                          fill="url(#zr2375-gradient-1)" className="bar-item go-live"></path>
                                                    <path data-v-60ab8591=""
                                                          d="M 78.8077 180 L 97.8077 180 L 97.8077 180 L 78.8077 180 L 78.8077 180 Z"
                                                          fill="#c23531" fillOpacity="undefined" stroke="none" clipPath="none"
                                                          className="bar-item go-live"></path>
                                                    <path data-v-60ab8591=""
                                                          d="M 98.0385 75 L 113.0385 75 A 2 2 0 0 1 115.0385 77 L 115.0385 178 A 2 2 0 0 1 113.0385 180 L 98.0385 180 A 2 2 0 0 1 96.0385 178 L 96.0385 77 A 2 2 0 0 1 98.0385 75 Z"
                                                          fillOpacity="undefined" stroke="none" clipPath="none"
                                                          fill="url(#zr2375-gradient-2)" className="bar-item go-live"></path>
                                                    <path data-v-60ab8591=""
                                                          d="M 113.2692 180 L 132.2692 180 L 132.2692 180 L 113.2692 180 L 113.2692 180 Z"
                                                          fill="#c23531" fillOpacity="undefined" stroke="none" clipPath="none"
                                                          className="bar-item go-live"></path>
                                                    <path data-v-60ab8591=""
                                                          d="M 132.5 75 L 147.5 75 A 2 2 0 0 1 149.5 77 L 149.5 178 A 2 2 0 0 1 147.5 180 L 132.5 180 A 2 2 0 0 1 130.5 178 L 130.5 77 A 2 2 0 0 1 132.5 75 Z"
                                                          fillOpacity="undefined" stroke="none" clipPath="none"
                                                          fill="url(#zr2375-gradient-3)" className="bar-item go-live"></path>
                                                    <path data-v-60ab8591=""
                                                          d="M 147.7308 180 L 166.7308 180 L 166.7308 180 L 147.7308 180 L 147.7308 180 Z"
                                                          fill="#c23531" fillOpacity="undefined" stroke="none" clipPath="none"
                                                          className="bar-item go-live"></path>
                                                    <path data-v-60ab8591=""
                                                          d="M 166.9615 75 L 181.9615 75 A 2 2 0 0 1 183.9615 77 L 183.9615 178 A 2 2 0 0 1 181.9615 180 L 166.9615 180 A 2 2 0 0 1 164.9615 178 L 164.9615 77 A 2 2 0 0 1 166.9615 75 Z"
                                                          fillOpacity="undefined" stroke="none" clipPath="none"
                                                          fill="url(#zr2375-gradient-4)" className="bar-item go-live"></path>
                                                    <path data-v-60ab8591=""
                                                          d="M 182.1923 180 L 201.1923 180 L 201.1923 180 L 182.1923 180 L 182.1923 180 Z"
                                                          fill="#c23531" fillOpacity="undefined" stroke="none" clipPath="none"
                                                          className="bar-item go-live"></path>
                                                    <path data-v-60ab8591=""
                                                          d="M 201.4231 75 L 216.4231 75 A 2 2 0 0 1 218.4231 77 L 218.4231 178 A 2 2 0 0 1 216.4231 180 L 201.4231 180 A 2 2 0 0 1 199.4231 178 L 199.4231 77 A 2 2 0 0 1 201.4231 75 Z"
                                                          fillOpacity="undefined" stroke="none" clipPath="none"
                                                          fill="url(#zr2375-gradient-5)" className="bar-item go-live"></path>
                                                    <path data-v-60ab8591=""
                                                          d="M 216.6538 180 L 235.6538 180 L 235.6538 180 L 216.6538 180 L 216.6538 180 Z"
                                                          fill="#c23531" fillOpacity="undefined" stroke="none" clipPath="none"
                                                          className="bar-item go-live"></path>
                                                    <path data-v-60ab8591=""
                                                          d="M 235.8846 75 L 250 75 A 2 2 0 0 1 252 77 L 252 178 A 2 2 0 0 1 250 180 L 235.8846 180 A 2 2 0 0 1 233.8846 178 L 233.8846 77 A 2 2 0 0 1 235.8846 75 Z"
                                                          fillOpacity="undefined" stroke="none" clipPath="none"
                                                          fill="url(#zr2375-gradient-6)" className="bar-item go-live"></path>
                                                    <path data-v-60ab8591=""
                                                          d="M 36.6154 145 C 36.6154 145 44.0212 134.5573 53.8462 128 C 61.252 123.0573 63.4259 126.6623 71.0769 122 C 80.6567 116.1623 79.2141 108.0555 88.3077 107 C 96.4449 106.0555 96.7064 112.8743 105.5385 118 C 113.9372 122.8743 114.7252 128.1671 122.7692 127 C 131.956 125.6671 130.41 118.009 140 113 C 147.6408 109.009 148.5667 109.2514 157.2308 109 C 165.7974 108.7514 166.5168 113.8443 174.4615 112 C 183.7475 109.8443 182.3496 103.711 191.6923 101 C 199.5804 98.711 201.7508 105.33 208.9231 102 C 218.9816 97.33 216.3289 91.5573 226.1538 85 C 233.5597 80.0573 243.3846 79 243.3846 79"
                                                          fill="none" stroke="#5783FC" strokeWidth="2" paintOrder="fill"
                                                          strokeOpacity="1" strokeLinecap="butt" strokeLinejoin="bevel"
                                                          strokeMiterlimit="10" clipPath="url(#zr2375-clip-0)"
                                                          className="curve-item go-live"></path>
                                                    <defs data-v-60ab8591="">
                                                        <linearGradient data-v-60ab8591="" id="zr2375-gradient-0" x1="0" y1="0"
                                                                        x2="0" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop data-v-60ab8591="" offset="0%" stopColor="#ffffff"
                                                                  stopOpacity="0.3"></stop>
                                                            <stop data-v-60ab8591="" offset="100%" stopColor="#5783fc"
                                                                  stopOpacity="0.3"></stop>
                                                        </linearGradient>
                                                        <linearGradient data-v-60ab8591="" id="zr2375-gradient-1" x1="0" y1="0"
                                                                        x2="0" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop data-v-60ab8591="" offset="0%" stopColor="#ffffff"
                                                                  stopOpacity="0.3"></stop>
                                                            <stop data-v-60ab8591="" offset="100%" stopColor="#5783fc"
                                                                  stopOpacity="0.3"></stop>
                                                        </linearGradient>
                                                        <linearGradient data-v-60ab8591="" id="zr2375-gradient-2" x1="0" y1="0"
                                                                        x2="0" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop data-v-60ab8591="" offset="0%" stopColor="#ffffff"
                                                                  stopOpacity="0.3"></stop>
                                                            <stop data-v-60ab8591="" offset="100%" stopColor="#5783fc"
                                                                  stopOpacity="0.3"></stop>
                                                        </linearGradient>
                                                        <linearGradient data-v-60ab8591="" id="zr2375-gradient-3" x1="0" y1="0"
                                                                        x2="0" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop data-v-60ab8591="" offset="0%" stopColor="#ffffff"
                                                                  stopOpacity="0.3"></stop>
                                                            <stop data-v-60ab8591="" offset="100%" stopColor="#5783fc"
                                                                  stopOpacity="0.3"></stop>
                                                        </linearGradient>
                                                        <linearGradient data-v-60ab8591="" id="zr2375-gradient-4" x1="0" y1="0"
                                                                        x2="0" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop data-v-60ab8591="" offset="0%" stopColor="#ffffff"
                                                                  stopOpacity="0.3"></stop>
                                                            <stop data-v-60ab8591="" offset="100%" stopColor="#5783fc"
                                                                  stopOpacity="0.3"></stop>
                                                        </linearGradient>
                                                        <linearGradient data-v-60ab8591="" id="zr2375-gradient-5" x1="0" y1="0"
                                                                        x2="0" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop data-v-60ab8591="" offset="0%" stopColor="#ffffff"
                                                                  stopOpacity="1"></stop>
                                                            <stop data-v-60ab8591="" offset="100%" stopColor="#5783fc"
                                                                  stopOpacity="1"></stop>
                                                        </linearGradient>
                                                        <linearGradient data-v-60ab8591="" id="zr2375-gradient-6" x1="0" y1="0"
                                                                        x2="0" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop data-v-60ab8591="" offset="0%" stopColor="#ffffff"
                                                                  stopOpacity="0.3"></stop>
                                                            <stop data-v-60ab8591="" offset="100%" stopColor="#5783fc"
                                                                  stopOpacity="0.3"></stop>
                                                        </linearGradient>
                                                        <clipPath data-v-60ab8591="" id="zr2375-clip-0">
                                                            <path data-v-60ab8591=""
                                                                  d="M 27 59 L 253 59 L 253 181 L 27 181 L 27 59 Z" fill="#000"
                                                                  fillOpacity="1" stroke="none"></path>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <div className="bottom-bar">
                                                    <div className="data">30%</div>
                                                    <div className="line">
                                                        <div className="line-01"></div>
                                                        <div className="line-02"></div>
                                                    </div>
                                                </div>
                                                <div className="tag"><img src="images/thumb-icon.png" className="icon"
                                                                          alt="icon"/><span className="tag-num">4890</span></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="chart-item chart-item-04">
                                        <div className="chart-item-wrap">
                                            <div className="chart-04">
                                                <svg data-v-30f01134="" xmlns="http://www.w3.org/2000/svg" version="1.1"
                                                     baseProfile="full" width="280" height="260"
                                                     style={{userSelect:"none", position:"absolute", left:"0px", top:"0px"}}>
                                                    <path data-v-30f01134=""
                                                          d="M 75.5561 121.5158 L 42.0452 117.104 A 98.8 98.8 0 0 1 109.9615 35.877 L 120.2379 68.077 A 65 65 0 0 0 75.5561 121.5158 Z"
                                                          fill="#5783FC" fillOpacity="1" stroke="#ffffff" strokeWidth="2"
                                                          paintOrder="fill" strokeOpacity="1" strokeDasharray=""
                                                          strokeLinecap="butt" strokeLinejoin="bevel" strokeMiterlimit="10"
                                                          clipPath="none"></path>
                                                    <path data-v-30f01134=""
                                                          d="M 120.2379 68.077 L 109.9615 35.877 A 98.8 98.8 0 0 1 237.1456 111.9951 L 203.9116 118.1547 A 65 65 0 0 0 120.2379 68.077 Z"
                                                          fill="#F4F5F8" fillOpacity="1" stroke="#ffffff" strokeWidth="2"
                                                          paintOrder="fill" strokeOpacity="1" strokeDasharray=""
                                                          strokeLinecap="butt" strokeLinejoin="bevel" strokeMiterlimit="10"
                                                          clipPath="none"></path>
                                                    <circle data-v-30f01134="" fill="transparent" r="80" cx="140" cy="130"
                                                            className="circle-path go-live"></circle>
                                                </svg>
                                                <div className="chart-04-num">875</div>
                                                <div className="bottom-bar"><img src={thumbIcon} className="icon"
                                                                                 alt="icon"/>
                                                    <div className="line">
                                                        <div className="line-01"></div>
                                                        <div className="line-02"></div>
                                                    </div></div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                    </div>
                </section>
                <section className="k_wrap k_wrap-03">
                    <div className="container" data-src="images/map-img.png">
                        <div className="users">
                            {this.state.users.length  &&
                                <div className="circle-01">
                                    <img className="avatar" src={this.state.users[0].profile} style={{top:"47px", left:"377px"}} alt="img"/>
                                    <img className="avatar" src={this.state.users[1].profile} style={{top:"222px", left:"459px"}} alt="img"/>
                                    <img className="avatar" src={this.state.users[2].profile} style={{top:"366px", left:"408px"}}
                                    alt="img"/>
                                    <img className="avatar" src={this.state.users[3].profile} style={{top:"454px", left:"149px"}}
                                    alt="img" />
                                </div>
                            }
                            {this.state.users.length &&
                                <div className="circle-02">
                                    <img className="avatar reverse" src={this.state.users[0].profile} style={{top: "31px", left: "40px"}}
                                         alt="img"/>
                                    <img className="avatar reverse" src={this.state.users[1].profile} style={{top: "217px", left: "-11px"}}
                                         alt="img"/>
                                    <img className="avatar reverse" src={this.state.users[2].profile} style={{top: "338px", left: "227px"}}
                                         alt="img"/>
                                    <img className="avatar reverse" src={this.state.users[3].profile}
                                         style={{top: "130px", left: "341px"}} alt="img"/>
                                    <img className="dot-green" src={blueCircleIcon}
                                         style={{top: "-14px", left: "159px"}} alt="img"/>
                                    <img className="dot-blue" src={fillBlueIcon}
                                         style={{top: "312px", left: "56px"}} alt="img"/>
                                    <img className="dot-yellow" src={fillYellowIcon}
                                         style={{top: "254px", left: "336px"}} alt="img"/>
                                </div>
                            }
                            <div className="circle-03"></div>
                            <div className="circle-04"></div>
                        </div>
                        <div className="content">
                            <h3 className="title">
                                <Trans>Meet the most popular creators here.</Trans>
                            </h3>
                            <p className="text">
                                <Trans>Browse Description</Trans>
                            </p>
                        </div>
                    </div>
                </section>
                <section className="k_wrap k_wrap-04" data-src="images/bg-img1.png">
                    <div className="container">
                        <div className="content">
                            <h3 className="title">
                                <Trans>About Kurafat</Trans>
                            </h3>
                            <p className="text">
                                <Trans>About Description</Trans>
                            </p>
                        </div>
                    </div>
                </section>
                <section className="k_wrap k_wrap-05">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="item">
                                    <div className="item-icon"><img src={itemIcon1} alt="icon"/></div>
                                    <h3 className="number">150</h3>
                                    <p className="text"><Trans>Kurafat is now available in over</Trans> 150 <Trans>markets</Trans>.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="item">
                                    <div className="item-icon"><img src={itemIcon2} alt="icon"/></div>
                                    <h3 className="number">40+</h3>
                                    <p className="text"><Trans>One of the most downloaded apps in</Trans> 40+ <Trans>countries</Trans>.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="item">
                                    <div className="item-icon"><img src={itemIcon3} alt="icon"/></div>
                                    <h3 className="number">75</h3>
                                    <p className="text"><Trans>Kurafat is available in</Trans> 75 <Trans>languages</Trans>.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="k_wrap k_wrap-06">
                    <div className="container">
                        <div className="bgimg" data-src="images/bg-img2.png">
                            <div className="content">
                                <h3 className="title text-white"><Trans>Featured videos</Trans></h3>
                                <p className="text">
                                    <Trans>Obtain high-quality video content from world-class creators</Trans>
                                </p></div>
                        </div>
                        <div id="myCarousel" className="carousel slide" data-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active is-animating">
                                    <div className="d-flex justify-content-center">
                                        {this.state.videos1.map((video, index) => (
                                            <div className="card card-01">
                                                <div className="card-video-wrap">
                                                    <video loop="loop" data-play="hover" muted="muted" preload="auto"
                                                           src={video.file_url} className="card-video"></video>
                                                    <img className="video-control" src={playButton} alt=""/>
                                                </div>
                                                <h3 className="card-title">{video.description}</h3>
                                                <p className="card-name"> {video.user.name}</p>
                                                <div className="card-data">
                                                    <img src={playSvg} className="card-icon" alt=""/><span
                                                    className="card-num">{video.view_count}</span>
                                                    <img src={heartSvg} className="card-icon" alt=""/><span
                                                    className="card-num">{video.like_count}</span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="carousel-item is-animating">
                                    <div className="d-flex justify-content-center">
                                        {this.state.videos2.map((video, index) => (
                                            <div className="card card-01">
                                                <div className="card-video-wrap">
                                                    <video loop="loop" data-play="hover" muted="muted" preload="auto"
                                                           src={video.file_url} className="card-video"></video>
                                                    <img className="video-control" src={playButton} alt=""/>
                                                </div>
                                                <h3 className="card-title">{video.description}</h3>
                                                <p className="card-name"> {video.user.name}</p>
                                                <div className="card-data">
                                                    <img src={playSvg} className="card-icon" alt=""/><span
                                                    className="card-num">{video.view_count}</span>
                                                    <img src={heartSvg} className="card-icon" alt=""/><span
                                                    className="card-num">{video.like_count}</span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
    )
    }
}

export default withRouter(Home);