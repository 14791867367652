import React from 'react';
import Header from "../Layout/Header";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import {callAPI} from "../../Commen/CommenFunction";
import { Trans, withTranslation } from "react-i18next";

class Login extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            email: '',
            password:'',
            loading:false
        };
    }

    async inputChangeHandler(event, key) {
        const value = event.target.value;

        this.setState(state => {
            return {
                ...state,
                [key]: value
            }
        })
    }

    async submitHandler(event) {
        event.preventDefault()
        var formData =$('.needs-validation')[0];
        if (!formData.checkValidity()) {
            formData.classList.add('was-validated')
            return;
        }

        this.setState({
            loading:true
        });

        const form = new FormData()
        form.set('email', this.state.email)
        form.set('password', this.state.password)

        try {
            const response = await callAPI('auth/login', 'post', form,'application/json');

            if (response.status === true) {
                localStorage.setItem("user", JSON.stringify(response.data));
                window.location.href = '/home';
            } else {
                alert(response.message)
                this.setState({
                    errorMessage:response.message,
                    opensnack:true
                });
            }
            this.setState({
                loading:false
            });
        } catch (e) {
            console.log(e);
            this.setState({
                loading:false
            });
        }
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <Header />
                <section className="login-sec">
                    <div className="container">
                        <div className="login-sec-inner">
                            <div className="login-text">
                                <div className="welcome"> <Trans>Welcome to</Trans></div>
                                <div className="brand-name"><Trans>Kurafat Creator</Trans> <br/> <Trans>Marketplace</Trans></div>
                                <div className="detail">
                                    <Trans>The official platform for brand and creator collaborations on Kurafat</Trans>
                                </div>
                            </div>
                            <div className="login-form">
                                <h5 className="form-title"><Trans>Log In</Trans></h5>
                                <form method="post" className="needs-validation" noValidate={true} onSubmit={(event) => this.submitHandler(event)}>
                                    <div className="form-group">
                                        <label htmlFor="email"><Trans>Email Address</Trans></label>
                                        <input type="email" name="email" id="email"
                                               value={this.state.email}
                                               onChange={(event) => this.inputChangeHandler(event, 'email')}
                                               className="form-control"
                                               required={true}
                                               placeholder={t("Email Address")} />
                                    </div>
                                    <div className="form-group mb-4">
                                        <label htmlFor="password"><Trans>Password</Trans></label>
                                        <input type="password" name="password" id="password"
                                               value={this.state.password}
                                               onChange={(event) => this.inputChangeHandler(event, 'password')}
                                               className="form-control"
                                               required={true}
                                               placeholder={t("Email Address")} />
                                    </div>
                                    <button className="btn btn-block login-btn" type="submit"><Trans>Log In</Trans></button>
                                        <div className="signup-forgot">
                                            <a type="submit" className="menu-item"><Trans>Sign Up</Trans></a>
                                            <div className="divider"></div>
                                            <a href="#_" className="menu-item"> <Trans>Forgot Password</Trans></a>
                                        </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default withTranslation() (withRouter(Login));