import React from 'react';
import logoImg from '../../Assets/images/logo.png';
import { withRouter } from "react-router-dom";
import { Trans } from "react-i18next";

class Footer extends React.Component{

    render() {
        return (
            <footer className="footer">
                <div className="container">
                    <div className="f_menu line1">
                        <span className="f_main"><Trans>Our Products</Trans></span>
                        <span className="tag"><a href="#_"><Trans>Kurafat</Trans></a></span>
                        <span className="tag"><a href="#_"><Trans>TopBuzz/Buzz Video</Trans></a></span>
                        <span className="tag"><a href="#_"><Trans>News Republic</Trans></a></span>
                        <span className="tag"><a href="#_"><Trans>Kurafat Ads</Trans></a></span>
                    </div>
                    <div className="f_menu line2">
                        <span className="f_main">@2021 Kurafat</span>
                        <span className="tag"><a href="#_"><Trans>Brand Terms</Trans></a></span>
                        <span className="tag"><a href="#_"><Trans>privacy Policy</Trans></a></span>
                    </div>
                    <img src={logoImg} alt="logo" className="logo"/>
                </div>
            </footer>
        )
    }
}

export default withRouter(Footer);