import React from 'react';
import {withRouter} from "react-router-dom";
import $ from "jquery";
import {callAPI, userData} from "../../Commen/CommenFunction";
import {Trans, withTranslation} from "react-i18next";

class addBrand extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            brand_name:'',
            brand_website:'',
            brand_description:'',
            logo:'',
            industry:'',
            loading:false,
            logo_url:null,
            categoryList:[]
        };
        this.fileChange = this.fileChange.bind(this);
    }

    async componentDidMount() {
        try{
            const response = await callAPI('user/getIndustryList', 'post', null, 'application/json');
            if (response.status === true) {
                this.setState({
                    categoryList: response.data,
                });
            }
        } catch (e) {
            console.log(e);
            this.setState({
                loading: false
            });
        }
    }

    async inputChangeHandler(event, key) {
        const value = event.target.value;

        this.setState(state => {
            return {
                ...state,
                [key]: value
            }
        })
    }

    async submitHandler(event) {
        console.log(event)
        event.preventDefault()
        var formData =$('.needs-validation')[0];
        if (!formData.checkValidity()) {
            formData.classList.add('was-validated')
            return;
        }

        this.setState({
            loading:true
        });

        const form = new FormData()
        form.set('brand_name', this.state.brand_name)
        form.set('brand_website', this.state.brand_website)
        form.set('brand_description', this.state.brand_description)
        form.set('product_name', this.state.product_name)
        form.set('industry', this.state.industry)
        form.append(
            "logo",
            this.state.logo
        )
        form.append(
            "description_file",
            this.state.brand_file
        )

        try {
            const response = await callAPI('user/addBrand', 'post', form,'application/json');
            if (response.status === true) {
                window.location.reload();
            }
            this.setState({
                loading:false
            });
        } catch (e) {
            this.setState({
                loading:false
            });
        }
    }

    fileChange(event,type) {
        let fileData = event.target.files[0];

        if (type == 'logo') {
            this.setState({
                logo_url: URL.createObjectURL(fileData),
            });
        }

        this.setState({
            [type]: fileData
        });
    }



    render() {
        const {t} = this.props;
        if(this.props.brand.length) {
            this.setState({
                brand_name:this.props.brand.name,
                brand_website: this.props.brand.website,
                brand_description:this.props.brand.description,
                industry:this.props.brand.industry,
            })
        }
        return (
            <div className="kurafat-modal">
                <div className="modal fade" id="addBrand" tabIndex="-1" role="dialog"
                     aria-labelledby="addBrandLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title"><Trans>Add brand</Trans></h5>
                                <button type="button" className="close" data-dismiss="modal"
                                        aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form method="post" className="create-campaign-form needs-validation" noValidate={true} onSubmit={(event) => this.submitHandler(event)}>
                                    <div className="form-group">
                                        <label htmlFor="name"><Trans>Brand name</Trans></label>
                                        <div className="input-suffix">
                                            <input type="text" name="name" id="brandname"
                                                   className="form-control"
                                                   value={this.state.brand_name}
                                                   onChange={(event) => this.inputChangeHandler(event, 'brand_name')}
                                                   required={true}
                                                   placeholder={t("Enter the brand to be promoted")} />
                                            <div className="suffix"><span>0/60</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name" className="compu_n"><Trans>Brand website</Trans></label>
                                        <div className="input-suffix">
                                            <input type="text" name="name" id="brandwebsite"
                                                   className="form-control"
                                                   value={this.state.brand_website}
                                                   onChange={(event) => this.inputChangeHandler(event, 'brand_website')}
                                                   required={true}
                                                   placeholder={t("Please enter")} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name" className="compu_n"><Trans>Brand Logo</Trans></label>
                                        <div className="file-uploader">
                                            <div className="file-upload-btn mt-0"><i
                                                className="fa fa-upload"></i> <Trans>Upload</Trans>
                                                file <input type="file" name="file"
                                                            onChange={(event) => this.fileChange(event,'logo')}
                                                            accept=".png,.jpg,.jpeg"/>
                                                {this.state.logo_url &&
                                                <img className="file_url_file"
                                                     src={this.state.logo_url} alt="File URL"/>
                                                }
                                            </div>
                                            <div className="file-uploader-info"><Trans>Logos must be JPG or PNG and less than 5 MB Brand industry</Trans>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name"><Trans>Brand industry</Trans></label><a
                                        href="#_" target="_blank" rel="noopener"
                                        className="industry-label"><Trans>View prohibited products and services</Trans></a>
                                        <select required={true}
                                                name="country_code"
                                                onChange={(event) => this.inputChangeHandler(event, 'industry')}
                                                className="form-control" >
                                            <option value=""><Trans>Select</Trans></option>
                                            {this.state.categoryList.map((category, index) => (
                                                <option value={category.title}>{category.title}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name" className="compu_n"><Trans>Brand Description</Trans></label>
                                        <div className="input-suffix">
                                        <textarea placeholder={t("Brand Description")}
                                                  className="textarea-inner"
                                                  value={this.state.brand_description}
                                                  onChange={(event) => this.inputChangeHandler(event, 'brand_description')}
                                                  required={true}
                                                  style={{resize:"none", minHeight:"102px", height:"102px"}}></textarea>
                                            <div className="suffix b-0"><span>0/800</span>
                                            </div>
                                        </div>
                                        <div className="file-uploader">
                                            <div className="file-upload-btn"><i
                                                className="fa fa-upload"></i> <Trans>Upload brand description</Trans> <input type="file" name="file"
                                                                   onChange={(event) => this.fileChange(event,'brand_file')}
                                                                   accept=".doc,.docx,.xlsx,.ppt,.pdf,.zip,.rar"/>
                                            </div>
                                            <div className="file-uploader-info"><Trans>Files must be Word, Excel, PPT, PDF, ZIP or RAR and less than 5 MB</Trans>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" className="btn gray-btn"
                                            data-dismiss="modal"><Trans>Cancel</Trans>
                                    </button>
                                    <button type="submit" className="btn orange-btn"><Trans>Add Brand</Trans>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation() (withRouter(addBrand));