'use strict'
import React from 'react';
import logoImg from '../../Assets/images/logo.png';
import signUpImg from '../../Assets/images/signup-img.svg';
import {callAPI} from "../../Commen/CommenFunction";
import $ from 'jquery';
import { withRouter } from "react-router-dom";
import SnackBarComponent from "../Layout/SnackBarComponent";
import { Trans, withTranslation } from "react-i18next";
import Language from "../Layout/Language";

class Signup extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            email: '',
            password:'',
            confirm_password:'',
            otp:'',
            loading:false,
            errorMessage:'',
            opensnack:false,
            register_button:false
        };
    }

    async inputChangeHandler(event, key) {
        const value = event.target.value;

        this.setState(state => {
            return {
                ...state,
                [key]: value
            }
        })
    }

    async verifyEmail(event, key) {
        this.setState({
            loading:true
        });

        const form = new FormData()
        form.set('email', this.state.email)

        try {
          let vResponse = await callAPI('auth/verifyEmail', 'post', form,'application/json');

          if (vResponse.status) {
              this.setState({
                  register_button:true
              });
          } else {
              alert(vResponse.message);
              this.setState({
                  email:'',
                  register_button:false
              });
          }
        } catch (e) {
            console.log(e);
            this.setState({
                loading:false
            });
        }
    }

    async componentDidMount() {

    }

    async submitHandler(event) {
        event.preventDefault()
        var formData =$('.needs-validation')[0];
        if (!formData.checkValidity()) {
            formData.classList.add('was-validated')
            return;
        }

        this.setState({
            errorMessage:'',
            opensnack:false
        });

        if (this.state.password != this.state.confirm_password){
            $('#re-password').addClass('is-invalid');
            $('#re-password-feedback').text("Password and confirm password does not match")
            return
        }

        this.setState({
            loading:true
        });

        const form = new FormData()
        form.set('email', this.state.email)
        form.set('otp', this.state.otp)
        form.set('password', this.state.password)

        try {
            const response = await callAPI('auth/register', 'post', form,'application/json');

            if (response.status === true) {
                localStorage.setItem("user", JSON.stringify(response.data));
                window.location.href = '/signup_location';
            } else {
                this.setState({
                    errorMessage:response.message,
                    opensnack:true
                });

            }
            this.setState({
                loading:false
            });
        } catch (e) {
            console.log(e);
            this.setState({
                loading:false
            });
        }
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <div className="signup-sec">
                    <div className="signup-left">
                        <div className="left-header"><a href="#_"><img src={logoImg} className="logo"
                                                                       alt="logo" /></a></div>
                        <div className="left-body">
                            <div className="left-body-title"><Trans>Find the right creators in Kurafat Creator Marketplace</Trans>
                            </div>
                            <div className="left-body-info"><Trans>The official platform for brand and creator collaborations on Kurafat</Trans>
                            </div>
                            <div className="left-body-img"><img src={signUpImg} className="register-logo"
                                                                alt="" /></div>
                        </div>
                        <div className="left-footer">
                            <div className="left-footer-main">@2021 Kurafat</div>
                            <div className="left-footer-tags">
                                <div className="tag"><a href="#_" target="_blank"><Trans>Brand Terms</Trans></a></div>
                                <div className="tag-split"></div>
                                <div className="tag"><a href="#_" target="_blank"><Trans>Privacy Policy</Trans></a></div>
                            </div>
                        </div>
                    </div>
                    <div className="signup-right">
                        <div className="signup-right-header">
                            <a href={"/login"} className="login-text"><Trans>Log In</Trans></a>
                            <Language />
                            <a href="#" className="help"><i className="fa fa-question-circle-o"></i></a>
                        </div>
                        <div className="signup-form">
                            <h5 className="form-title"><Trans>Business location</Trans></h5>
                            <form method="post" className="needs-validation" noValidate={true} onSubmit={(event) => this.submitHandler(event)}>
                                <div className="form-group">
                                    <label htmlFor="email"><Trans>Business email</Trans></label>
                                    <input type="email" name="email" id="email"
                                           value={this.state.email}
                                           onChange={(event) => this.inputChangeHandler(event, 'email')}
                                           className="form-control"
                                           placeholder={t("Enter email address")} required={true} />
                                    <div className="invalid-feedback">
                                        <Trans>Please enter a valid email</Trans>
                                    </div>
                                </div>

                                <div className="form-group vf">
                                    <label htmlFor="verification code"><Trans>Verification code</Trans></label>
                                    <div className="position-relative">
                                        <input type="text" name="verification code" id="Verification"
                                               value={this.state.otp}
                                               onChange={(event) => this.inputChangeHandler(event, 'otp')}
                                               required={true}
                                               className="form-control" placeholder={t("Enter 6-digit code")} />
                                            <a href="#_" onClick={() => this.verifyEmail()} className="send_code"><Trans>Send code</Trans> </a>
                                    </div>
                                </div>

                                <a href="#" className="code-tip" data-toggle="tooltip" data-placement="bottom"
                                   title="Try checking your spam folder. If you still didn't receive our email, you can request a new code after 60 seconds"><Trans>Didn't get a code?</Trans></a>

                                <div className="form-group">
                                    <label htmlFor="verification code"><Trans>Password</Trans></label>
                                    <input type="password" name="password" id="password"
                                           value={this.state.password}
                                           onChange={(event) => this.inputChangeHandler(event, 'password')}
                                           className="form-control"
                                           placeholder={t("Enter Password")} required={true} />
                                    <div className="invalid-feedback">
                                        <Trans>Please enter a password</Trans>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="verification code"><Trans>Re-enter Password</Trans></label>
                                    <input type="password" name="re-enter password" id="re-password"
                                           value={this.state.confirm_password}
                                           onChange={(event) => this.inputChangeHandler(event, 'confirm_password')}
                                           className="form-control" placeholder={t("Enter Password")} required={true} />
                                    <div id="re-password-feedback" className="invalid-feedback">
                                        <Trans>Please enter a confirm password</Trans>
                                    </div>
                                </div>
                                {this.state.register_button &&
                                    <button type="submit" className="btn orange-btn"><Trans>Create account</Trans></button>
                                }
                            </form>
                        </div>
                    </div>

                </div>
                <SnackBarComponent message={this.state.errorMessage} opensnack={this.state.opensnack}/>
            </>
        )
    }
}

export default withTranslation() (withRouter(Signup));