import React from 'react';
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { withRouter } from "react-router-dom";

class SnackBarComponent extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            open: false,
            vertical: 'top',
            horizontal: 'center',
            message:''
        };
    }

    handleClose(){
        this.setState({
            open:false
        });
    }

    render() {
        console.log(this.props)
        this.state.open = this.props.opensnack;
        this.state.message = this.props.message;
        return (
            <Snackbar open={this.state.open} autoHideDuration={6000} onClose={() => this.handleClose()}>
                <MuiAlert onClose={() => this.handleClose()} severity="error">
                    {this.state.message}
                </MuiAlert>
            </Snackbar>
        )
    }
}

export default withRouter(SnackBarComponent);