import React from 'react';
import { withRouter } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import videoImg1 from '../../Assets/images/video-img1.png';
import playIcon1 from '../../Assets/images/play-icon1.png';
import playIcon2 from '../../Assets/images/play-icon2.png';
import playIcon3 from '../../Assets/images/play-icon3.png';
import playIcon4 from '../../Assets/images/play-icon4.png';
import playIcon5 from '../../Assets/images/play-icon5.png';
import femaleIcon from '../../Assets/images/female-icon.png';
import maleIcon from '../../Assets/images/male-icon.png';
import mapChart from '../../Assets/images/map-chart.JPG';
import stateMap from '../../Assets/images/state-map.JPG';
import followerChart from '../../Assets/images/followers-chart.JPG';
import videoChart from '../../Assets/images/videos-chart.JPG';
import enggChart from '../../Assets/images/engg-chart.JPG';
import {callAPI} from "../../Commen/CommenFunction";
import CanvasJSReact from '../../Assets/js/canvasjs.react';
import $ from 'jquery';
import {Trans, withTranslation} from "react-i18next";
import backImg from "../../Assets/images/back-img1.jpeg";



const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;


class Profile extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            user: [],
            shortList: [],
            selected_user: [],
            CampaignList: [],
            playUrl:null
        };
    }

    async componentDidMount() {
        try {

            let username = this.props.match.params.username;
            if (!username) {
                window.location.href = '/home';
            }
            const form = new FormData()
            form.set('username', username);
            const response = await callAPI('user/getUserDetail', 'post', form,'application/json');

            if (response.status === true) {
                this.setState({
                    user:response.data
                });
            } else {
                this.setState({
                    user:[],
                });
            }
            this.setState({
                loading:false
            });
        } catch (e) {
            console.log(e);
            this.setState({
                loading:false
            });
        }
        $('.canvasjs-chart-credit').remove();
    }

    async playVideo(url) {
        console.log(url);
        this.setState({
            playUrl:url
        });
    }

    render() {
        const {t} = this.props;
        const genderOptions = {
            animationEnabled: true,
            data: [{
                type: "doughnut",
                yValueFormatString: "#,###'%'",
                dataPoints: [
                    { name: t("Male"), y: this.state.user.male_percentage, color:'#5783fc'},
                    { name: t("Female"), y: this.state.user.female_percentage, color: '#f45778' },
                    { name: t("Other"), y: this.state.user.other_percentage },
                    { name: t("Not Selected"), y: this.state.user.not_percentage },
                ]
            }]
        }
        const ageOptions = {
            animationEnabled: true,
            data: [
                {
                    type: "column",
                    dataPoints: [
                        { label: "18-24",  y: this.state.user.teen_age  },
                        { label: "24-34", y: this.state.user.midium_age  },
                        { label: "35+", y: this.state.user.old_age  },
                    ]
                }
            ]
        }
        const usageOptions = {
            animationEnabled: true,
            data: [{
                type: "doughnut",
                indexLabel: "{name}: {y}",
                yValueFormatString: "#,###'%'",
                dataPoints: [
                    { name: t("Inactive"), y: 42, color:'#70b6fe' },
                    { name: t("Active"), y: 58, color:'#60ded8' },
                ]
            }]
        }
        const deviceOptions = {
            animationEnabled: true,
            data: [{
                type: "doughnut",
                indexLabel: "{name}: {y}",
                yValueFormatString: "#,###'%'",
                dataPoints: [
                    { name: t("IOS"), y: this.state.user.ios_device, color:'#70b6fe' },
                    { name: t("Android"), y: this.state.user.android_device, color:'#60ded8' },
                ]
            }]
        }
        return (
            <>
                <Header />
                <div className="content-wrapper">
                    <div className="wrapper-inner">
                        <div className="container">
                            <div className="account-info-detail">
                                <div className="base-info-author">
                                    <div className="card-back-img"
                                         style={{backgroundImage: this.state.user.profile}}>
                                        <div className="img-mask"></div>
                                    </div>
                                    <div className="avatar card-avatar" style={{width:"72px", height:"72px;"}}>
                                        <img src={this.state.user.profile} alt="" className="img-avatar"
                                             style={{opacity:"1", width:"66px", height:"66px", borderWidth:"3px"}} />
                                    </div>
                                    <div className="card-info">
                                        <div className="card-name star-nowrap">{this.state.user.name}</div>
                                        <div className="user-id"><span
                                            className="card-handlename"><span>@</span><span>{this.state.user.username}</span></span>
                                        </div>
                                        <div className="card-city"><i className="fa fa-map-marker"></i> United States of
                                            America
                                        </div>
                                        <div className="card-topics">
                                            <div className="tag-list" style={{fontSize:"12px"}}>
			 <span className="g-tag g-tag-blue" style={{backgroundColor:"rgba(87, 131, 252, 0.1);", color:"rgb(87, 131, 252)", paddingLeft:"6px",paddingRight:"6px"}}>
              Dance
             </span>
                                                <span className="g-tag g-tag-blue"
                                                      style={{backgroundColor:"rgba(87, 131, 252, 0.1);", color:"rgb(87, 131, 252)", paddingLeft:"6px",paddingRight:"6px"}}>
              Vlog
             </span>
                                                <span className="g-tag g-tag-blue"
                                                      style={{backgroundColor:"rgba(87, 131, 252, 0.1);", color:"rgb(87, 131, 252)", paddingLeft:"6px",paddingRight:"6px"}}>
                 Transition
             </span>
                                            </div>
                                        </div>
                                        <div className="card-numinfo">
                                            <div className="author-data">
                                                <div className="numinfo-val">{this.state.user.follower_count}</div>
                                                <div className="numinfo-key"><Trans>Followers</Trans></div>
                                            </div>
                                            <div className="author-data">
                                                <div className="numinfo-val">{this.state.user.view_count}</div>
                                                <div className="numinfo-key"><Trans>Average Views</Trans></div>
                                            </div>
                                        </div>
                                        <div className="desc"> <Trans>OBSESSED out on all platforms.</Trans></div>
                                        <div className="card-splitline"></div>
                                        <div className="card-profiles-title"><Trans>Top Audience Segments</Trans></div>
                                        <div className="author-audience">
                                            <div className="profile-item">
                                                <aside className="flag-icon flag-icon-PH"></aside>
                                                <div className="profile-val">33%</div>
                                            </div>
                                            <div className="profile-item">
                                                <div className="profile-title"><Trans>Male</Trans></div>
                                                <div className="profile-val">{this.state.user.male_percentage}%</div>
                                            </div>
                                            <div className="profile-item">
                                                <div className="profile-title">25-34</div>
                                                <div className="profile-val">{this.state.user.midium_age}%</div>
                                            </div>
                                        </div>
                                        <div className="invite-wrap">
                                            <div className="grey-send-btn" data-toggle="tooltip" data-placement="bottom"
                                                 title={t("Add to list")}><a href="#_" data-toggle="modal"
                                                                        data-target="#addTolist"><i
                                                className="fa fa-plus-square"></i></a></div>
                                            <div className="grey-send-btn" data-toggle="tooltip" data-placement="bottom"
                                                 title={t("invite now")}><a href={'/create-campaign'}><i
                                                className="fa fa-paper-plane"></i></a></div>
                                            <button type="button" className="btn orange-btn" data-toggle="modal"
                                                    data-target="#addExisting"><span><Trans>Add existing campaign</Trans></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="account-stats-container">
                                <nav className="fix-bar" id="desktop-nav">
                                    <div className="quick-bar-panel">
                                        <div className="quick-bar">
                                            <div className="item active"><a href="#coremetrics"><Trans>Core Metrics</Trans></a></div>
                                            <div className="item"><a href="#samplevideos"><Trans>Sample Videos</Trans></a></div>
                                            <div className="item "><a href="#audience"><Trans>Audience Demographics</Trans></a></div>
                                            <div className="item "><a href="#trends"><Trans>Performance Trends</Trans></a></div>
                                        </div>
                                    </div>
                                </nav>
                                <div className="content-stat">
                                    <div className="tab-content-box stats-item-container" id="coremetrics">
                                        <div className="card-panel-body">
                                            <div className="core-stats-head"><Trans>Core Metrics</Trans><span
                                                className="duration-tip-info"><Trans>Average performance of the most recent 30 videos</Trans></span>
                                            </div>
                                            <div className="indicator-list">
		 <span className="indicator indicator-item">
		  <div className="icon-img"><img src={playIcon1} alt="icon" /></div>
		  <div className="value">{this.state.user.view_count}</div>
		  <div className="text">Average Views </div>
		 </span>
                                                <span className="indicator indicator-item">
		  <div className="icon-img"><img src={playIcon2} alt="icon" /></div>
		  <div className="value">{this.state.user.like_count}</div>
		  <div className="text">Likes</div>
		 </span>
                                                <span className="indicator indicator-item">
		  <div className="icon-img"><img src={playIcon3} alt="icon" /></div>
		  <div className="value">{this.state.user.comment_count}</div>
		  <div className="text">Comments</div>
		 </span>
                                                <span className="indicator indicator-item">
		  <div className="icon-img"><img src={playIcon4} alt="icon" /></div>
		  <div className="value">{this.state.user.share_count}</div>
		  <div className="text">Shares</div>
		 </span>
                                                <div className="indicator-split"></div>
                                                <span className="indicator indicator-item">
		  <div className="icon-img"><img src={playIcon5} alt="icon" /></div>
		  <div className="value pink-color">13.96%</div>
		  <div className="text">
		   <div className="question-wrap">
			  Engagement Rate
			 <span className="help-icon-tooltip" data-toggle="tooltip" data-placement="bottom" title=""
                   data-original-title={t("Calculated as the sum of likes, comments and shares divided by total views.")}>
				  <i className="fa fa-question-circle-o"></i>
		    </span>
		   </div>
		  </div>
		 </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-content-box stats-item-container author-work" id="samplevideos">
                                        <div className="title-wrapper panel-title"><Trans>Sample Videos</Trans></div>
                                        <div className="title-tabs d-flex justify-content-between align-items-center">
                                            <ul className="nav nav-tabs">
                                                <li className="nav-item"><a className="nav-link active"
                                                                            data-toggle="tab" href="#videos"><Trans>Videos</Trans></a>
                                                </li>
                                                <li className="nav-item"><a className="nav-link" data-toggle="tab"
                                                                            href="#sponsoredvideos"><Trans>Sponsored videos</Trans></a>
                                                </li>
                                            </ul>
                                            <a href="#_" className="see-more"><Trans>see more</Trans></a>
                                        </div>
                                        <div className="card-panel-body">
                                            <div className="tab-content">

                                                <div id="videos" className="tab-pane active">
                                                    <div className="container position-relative">
                                                        <div id="demo" className="carousel slide position-relative"
                                                             data-ride="carousel">

                                                            <div className="carousel-inner">
                                                                <div className="carousel-item active">
                                                                    <div className="d-flex">
                                                                        {this.state.user.videos &&
                                                                        this.state.user.videos.map((video, index) => (
                                                                            <div className="col-lg-4 col-sm-12 col-12 d-flex flex-column ">
                                                                                <div
                                                                                    className="author-video-wrap position-relative">
                                                                                    <a href="#"
                                                                                       className="work-video video-btn"
                                                                                       data-toggle="modal"
                                                                                       data-src={video.file_url}
                                                                                       data-target="#myVideo">
                                                                                        <div className="video-player-zoom" onClick={() => this.playVideo(video.file_url)}>
                                                                                            <div
                                                                                                className="video-player-poster"
                                                                                                style={{backgroundImage:"url("+video.thumb_url+")"}}></div>
                                                                                            <div
                                                                                                className="video-model-wrap"></div>
                                                                                            <span className="start-btn"><i
                                                                                                className="icon-play-inner"></i>
													 <div className="background"
                                                          style={{backgroundImage:"url("+video.thumb_url+")"}}></div>
													</span>
                                                                                            <div className="video-icons">
                                                                                            <span><i
                                                                                                className="fa fa-play-circle"></i></span>
                                                                                                <aside>{video.view_count}</aside>
                                                                                                <span><i
                                                                                                    className="fa fa-heart"></i></span>
                                                                                                <aside>{video.like_count}</aside>
                                                                                                <span><i
                                                                                                    className="fa fa-comment"></i></span>
                                                                                                <aside>{video.comment_count}</aside>
                                                                                                <span><i
                                                                                                    className="fa fa-share"></i></span>
                                                                                                <aside>{video.share_count}</aside>
                                                                                            </div>
                                                                                        </div>
                                                                                    </a>
                                                                                    <div className="video-des-inner">
                                                                                        <div
                                                                                            className="video-des-inner-item">
                                                                                            <img src={playIcon1}
                                                                                                 width="16" height="16"
                                                                                                 alt="icon" />
                                                                                            <span
                                                                                                className="engagement-percent">{video.view_count}</span>
                                                                                        </div>
                                                                                        <div
                                                                                            className="video-des-inner-item">
                                                                                            <img src={playIcon5}
                                                                                                 width="16" height="16"
                                                                                                 alt="icon" />
                                                                                            <span
                                                                                                className="engagement-percent">17.3%</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="video-des">
                                                                                        <div className="video-title">{video.description}
                                                                                        </div>
                                                                                        <div className="release-time">4 days ago </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                                <div className="carousel-item">
                                                                    <div className="d-flex">
                                                                        {this.state.user.videos &&
                                                                        this.state.user.videos.reverse().map((video, index) => (
                                                                            <div className="col-lg-4 col-sm-12 col-12 d-flex flex-column ">
                                                                                <div
                                                                                    className="author-video-wrap position-relative">
                                                                                    <a href="#"
                                                                                       className="work-video video-btn"
                                                                                       data-toggle="modal"
                                                                                       data-src={video.file_url}
                                                                                       data-target="#myVideo">
                                                                                        <div className="video-player-zoom" onClick={() => this.playVideo(video.file_url)}>
                                                                                            <div
                                                                                                className="video-player-poster"
                                                                                                style={{backgroundImage:"url("+video.thumb_url+")"}}></div>
                                                                                            <div
                                                                                                className="video-model-wrap"></div>
                                                                                            <span className="start-btn"><i
                                                                                                className="icon-play-inner"></i>
													 <div className="background"
                                                          style={{backgroundImage:"url("+video.thumb_url+")"}}></div>
													</span>
                                                                                            <div className="video-icons">
                                                                                            <span><i
                                                                                                className="fa fa-play-circle"></i></span>
                                                                                                <aside>{video.view_count}</aside>
                                                                                                <span><i
                                                                                                    className="fa fa-heart"></i></span>
                                                                                                <aside>{video.like_count}</aside>
                                                                                                <span><i
                                                                                                    className="fa fa-comment"></i></span>
                                                                                                <aside>{video.comment_count}</aside>
                                                                                                <span><i
                                                                                                    className="fa fa-share"></i></span>
                                                                                                <aside>{video.share_count}</aside>
                                                                                            </div>
                                                                                        </div>
                                                                                    </a>
                                                                                    <div className="video-des-inner">
                                                                                        <div
                                                                                            className="video-des-inner-item">
                                                                                            <img src={playIcon1}
                                                                                                 width="16" height="16"
                                                                                                 alt="icon" />
                                                                                            <span
                                                                                                className="engagement-percent">{video.view_count}</span>
                                                                                        </div>
                                                                                        <div
                                                                                            className="video-des-inner-item">
                                                                                            <img src={playIcon5}
                                                                                                 width="16" height="16"
                                                                                                 alt="icon" />
                                                                                            <span
                                                                                                className="engagement-percent">17.3%</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="video-des">
                                                                                        <div className="video-title">{video.description}
                                                                                        </div>
                                                                                        <div className="release-time">4 days
                                                                                            ago
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <a className="carousel-control-prev" href="#demo"
                                                               data-slide="prev" style={{opacity:"1", left:"-10%"}} >
                                                                <i className="fa fa-angle-left"
                                                                   style={{color:"#000", fontSize:"30px"}}></i>
                                                            </a>
                                                            <a className="carousel-control-next" href="#demo"
                                                               data-slide="next" style={{opacity:"1", right:"-10%"}}>
                                                                <i className="fa fa-angle-right"
                                                                   style={{color:"#000", fontSize:"30px"}}></i>
                                                            </a>


                                                        </div>


                                                    </div>
                                                </div>


                                                <div id="sponsoredvideos" className="tab-pane fade">
                                                    <div className="container position-relative">
                                                        <div id="demo1" className="carousel slide position-relative"
                                                             data-ride="carousel">

                                                            <div className="carousel-inner">
                                                                <div className="carousel-item active">
                                                                    <div className="d-flex">
                                                                        <div
                                                                            className="col-lg-4 col-sm-12 col-12 d-flex flex-column ">
                                                                            <div
                                                                                className="author-video-wrap position-relative">
                                                                                <a href="#"
                                                                                   className="work-video video-btn"
                                                                                   data-toggle="modal"
                                                                                   data-src="images/video1.mp4"
                                                                                   data-target="#myVideo">
                                                                                    <div className="video-player-zoom">
                                                                                        <div
                                                                                            className="video-player-poster"
                                                                                            style={{backgroundImage:videoImg1}}>
                                                                                        </div>
                                                                                        <div
                                                                                            className="video-model-wrap"></div>
                                                                                        <span className="start-btn"><i
                                                                                            className="icon-play-inner"></i>
													 <div className="background" style={{backgroundImage:videoImg1}}></div>
													</span>
                                                                                        <div className="video-icons">
                                                                                            <span><i
                                                                                                className="fa fa-play-circle"></i></span>
                                                                                            <aside>16m</aside>
                                                                                            <span><i
                                                                                                className="fa fa-heart"></i></span>
                                                                                            <aside>2.6m</aside>
                                                                                            <span><i
                                                                                                className="fa fa-comment"></i></span>
                                                                                            <aside>94.8k</aside>
                                                                                            <span><i
                                                                                                className="fa fa-share"></i></span>
                                                                                            <aside>24.8k</aside>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                                <div className="video-des-inner">
                                                                                    <div
                                                                                        className="video-des-inner-item">
                                                                                        <img src={playIcon1}
                                                                                             width="16" height="16"
                                                                                             alt="icon" />
                                                                                            <span
                                                                                                className="engagement-percent">15.8m</span>
                                                                                    </div>
                                                                                    <div
                                                                                        className="video-des-inner-item">
                                                                                        <img src={playIcon5}
                                                                                             width="16" height="16"
                                                                                             alt="icon" />
                                                                                            <span
                                                                                                className="engagement-percent">17.3%</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="video-des">
                                                                                    <div className="video-title">my
                                                                                        mirror is so dirty
                                                                                    </div>
                                                                                    <div className="release-time">4 days
                                                                                        ago
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </div>

                                                                </div>

                                                            </div>

                                                            <a className="carousel-control-prev" href="#demo"
                                                               data-slide="prev" style={{opacity:"1", left:"-10%"}}>
                                                                <i className="fa fa-angle-left"
                                                                   style={{color:"#000", fontSize:"30px"}}></i>
                                                            </a>
                                                            <a className="carousel-control-next" href="#demo"
                                                               data-slide="next" style={{opacity:"1", right:"-10%"}}>
                                                                <i className="fa fa-angle-right"
                                                                   style={{color:"#000", fontSize:"30px"}}></i>
                                                            </a>


                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-content-box stats-item-container audience" id="audience">
                                        <div className="title-wrapper panel-title"><Trans>Audience Demographics</Trans></div>
                                        <div className="card-panel-body">
                                            <div>
                                                <div className="gender-and-age chart-border">
                                                    <div className="gender-pie gender-chart">
                                                        <div className="chart-pie">
                                                            <div className="chart-title"><Trans>Audience Gender</Trans></div>
                                                            <div className="gender-chart">
                                                                <CanvasJSChart options = {genderOptions}/>
                                                            </div>
                                                        </div>
                                                        <div className="gender-legend">
                                                            <aside className="gender-item Female">
                                                                <img src={femaleIcon} alt="female-icon" />
                                                                    <div className="gender-label female">
                                                                        <div className="name"><Trans>Female</Trans></div>
                                                                        <div className="value">75.52%</div>
                                                                    </div>
                                                            </aside>
                                                            <aside className="gender-item male">
                                                                <img src={maleIcon} alt="female-icon"/>
                                                                    <div className="gender-label male">
                                                                        <div className="name"><Trans>Male</Trans></div>
                                                                        <div className="value">24.48%</div>
                                                                    </div>
                                                            </aside>
                                                        </div>
                                                    </div>
                                                    <div className="gender-pie gender-chart">
                                                        <div className="chart-pie">
                                                            <div className="chart-title"><Trans>Audience Age</Trans></div>
                                                            <div className="age-chart"> <CanvasJSChart options = {ageOptions}/></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <div className="activate-and-device chart-border">
                                                    <div className="chart-pie activate-chart">
                                                        <div className="chart-area">
                                                            <div className="chart-title"><Trans>Kurafat usage</Trans></div>
                                                            <div className="age-chart">
                                                                <CanvasJSChart options = {usageOptions}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="chart-pie device-chart">
                                                        <div className="chart-area">
                                                            <div className="chart-title"><Trans>Device</Trans></div>
                                                            <div className="age-chart">
                                                                <CanvasJSChart options = {deviceOptions}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <div className="country-and-region chart-border">
                                                    <div className="country-map">
                                                        <div className="chart-area">
                                                            <div className="chart-title"><Trans>Country or Region</Trans></div>
                                                            <div className="age-chart"><img src={mapChart}
                                                                                            alt=""/></div>
                                                        </div>
                                                    </div>
                                                    <div className="top-three">
                                                        <h4 className="top-title"><Trans>Top 3 countries or regions</Trans></h4>
                                                        <div className="top-three-list">
                                                            <div className="line-percent top-item">
                                                                <h4>United States of America</h4>
                                                                <div className="line">
                                                                    <aside className="percent-line" style={{width:"27.2838%"}}></aside>
                                                                </div>
                                                                <span className="percent">27.28%</span>
                                                            </div>
                                                            <div className="line-percent top-item">
                                                                <h4>United Kingdom</h4>
                                                                <div className="line">
                                                                    <aside className="percent-line" style={{width:"6.81477%;"}}></aside>
                                                                </div>
                                                                <span className="percent">6.81%</span>
                                                            </div>
                                                            <div className="line-percent top-item">
                                                                <h4>Brazil</h4>
                                                                <div className="line">
                                                                    <aside className="percent-line" style={{width:"4.78968%;"}}></aside>
                                                                </div>
                                                                <span className="percent">4.79%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <div className="country-and-region chart-border">
                                                    <div className="country-map">
                                                        <div className="chart-area">
                                                            <div className="chart-title"><Trans>State</Trans></div>
                                                            <div className="age-chart"><img src={stateMap}
                                                                                            alt=""/></div>
                                                        </div>
                                                    </div>
                                                    <div className="top-three">
                                                        <h4 className="top-title"><Trans>Top 3 states</Trans></h4>
                                                        <div className="top-three-list">
                                                            <div className="line-percent top-item">
                                                                <h4>California</h4>
                                                                <div className="line">
                                                                    <aside className="percent-line" style={{width:"11.7229%"}}></aside>
                                                                </div>
                                                                <span className="percent">11.72%</span>
                                                            </div>
                                                            <div className="line-percent top-item">
                                                                <h4>Texas</h4>
                                                                <div className="line">
                                                                    <aside className="percent-line" style={{width:"11.0221%"}}></aside>
                                                                </div>
                                                                <span className="percent">11.02%</span>
                                                            </div>
                                                            <div className="line-percent top-item">
                                                                <h4>New York</h4>
                                                                <div className="line">
                                                                    <aside className="percent-line" style={{width:"6.91932%"}}></aside>
                                                                </div>
                                                                <span className="percent">6.92%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <div className="stats-item-container analysis" id="trends">
                                                    <div className="title-wrapper panel-title"><Trans>Performance Trends</Trans>
                                                        <div className="performance-tabs  float-right">
                                                            <ul className="nav nav-tabs">
                                                                <li className="nav-item"><a className="nav-link active"
                                                                                            data-toggle="tab"
                                                                                            href="#followers"><Trans>Followers</Trans></a>
                                                                </li>
                                                                <li className="nav-item"><a className="nav-link"
                                                                                            data-toggle="tab"
                                                                                            href="#pvideos"><Trans>Videos</Trans></a>
                                                                </li>
                                                                <li className="nav-item"><a className="nav-link"
                                                                                            data-toggle="tab"
                                                                                            href="#engagement"><Trans>Engagement</Trans></a>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                    </div>
                                                    <div className="card-panel-body">
                                                        <div className="performance-tab-content tab-content">
                                                            <div id="followers" className="tab-pane active">
                                                                <div className="performance-chart-container">
                                                                    <div className="chart-area followers-chart">
                                                                        <div className="chart-title"><Trans>Followers</Trans></div>
                                                                        <div className="followers-img-chart"><img
                                                                            src={followerChart} alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="compared-card">
                                                                        <div className="percent-value"><span
                                                                            className="icon"></span><span>2.55%</span>
                                                                        </div>
                                                                        <div className="des"><Trans>30-day Percentage Growth</Trans>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div id="pvideos" className="tab-pane">
                                                                <div className="performance-chart-container">
                                                                    <div className="chart-area videos-chart">
                                                                        <div className="chart-title"><Trans>Videos</Trans></div>
                                                                        <div className="videos-img-chart"><img
                                                                            src={videoChart} alt="" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div id="engagement" className="tab-pane">
                                                                <div className="performance-chart-container">
                                                                    <div className="chart-area videos-chart">
                                                                        <div className="chart-title"><Trans>Engagement</Trans></div>
                                                                        <div className="videos-img-chart"><img
                                                                           src={enggChart} alt="" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                <div className="modal fade" id="myVideo" tabIndex="-1" role="dialog" aria-labelledby="myVideoLabel"
                     aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div className="embed-responsive embed-responsive-16by9">
                                    <video autoPlay={true} src={this.state.playUrl} id="video"></video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="kurafat-modal">
                    <div className="modal fade" id="addTolist" tabIndex="-1" role="dialog"
                         aria-labelledby="addTolistLabel"
                         aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title"><Trans>Add to list</Trans></h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="search-header">
                                        <div className="search-wrapper">
                                            <div className="search-bar position-relative">
                                                <input type="text" className="form-control input-search" name="search"
                                                       id=""
                                                       placeholder={t("Try searching lipstick")} />
                                                <span className="input-icon"><i className="fa fa-search"></i></span>
                                            </div>
                                        </div>
                                        <a href={'/shortlist'}><strong>+ </strong> <Trans>Create a list</Trans>
                                        </a>
                                    </div>
                                    <div className="flod-list">
                                        {this.state.shortList.map((list, index) => (
                                            <div className="flod-list-item" key={index}>
                                                <div className="flod-list-item-left">
                                                    <div className="form-group form-check">
                                                        <input type="checkbox" className="form-check-input share-check"
                                                               value={list.id}
                                                               onChange={(event) => this.checkCheckbox(event, 'checked_shortlist')}
                                                               id="sharecheckbox" />
                                                        <label className="form-check-label compu_n"
                                                               htmlFor="sharecheckbox">{list.name}
                                                        </label>
                                                    </div>

                                                </div>
                                                <div className="flod-list-item-right">
                                                    <div className="flod-total">
                                                        {list.creator_count}
                                                        Creator(s)
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="modal-footer dialog__footer">
                                    <div className="add-author-info">
                                        <div className="base-info-brief">
                                            <div className="avatar" style={{width:"45px", height:"45px"}}><img
                                                src={this.state.selected_user.profile ? this.state.selected_user.profile : backImg}
                                                alt="" className="img-avatar" />
                                            </div>
                                            <div className="base-info star-nowrap">
                                                <div className="name star-nowrap">
                                                    {this.state.selected_user.name}
                                                </div>
                                                <div className="address star-nowrap">
                                                    {this.state.selected_user.city ? this.state.selected_user.city : 'United States of America'}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="add-author-info-content w-100">
                                                <textarea autoComplete="off" spellCheck="false" placeholder="Enter a comment (optional)"
                                                          className="k-textarea"
                                                          style={{minHeight:"60px", height:"60px"}}
                                                          disabled="disabled"></textarea>
                                        </div>
                                    </div>
                                    <button type="button" className="btn gray-btn close-list-modal" data-dismiss="modal"><Trans>Cancel</Trans></button>
                                    <button type="button" className="btn orange-btn" onClick={() => this.saveCreatorToList('shortlist')}><Trans>Save</Trans></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="kurafat-modal">
                    <div className="modal fade" id="addExisting" tabIndex="-1" role="dialog"
                         aria-labelledby="addTolistLabel"
                         aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title"><Trans>Add to existing campaign</Trans></h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="base-info-brief">
                                        <div className="avatar" style={{width:"45px", height:"45px"}}>
                                            <img src={this.state.selected_user.profile ? this.state.selected_user.profile : backImg} alt="" className="img-avatar" /></div>
                                        <div className="base-info star-nowrap">
                                            <div className="name star-nowrap">
                                                {this.state.selected_user.name}
                                            </div>
                                            <div className="address star-nowrap">
                                                {this.state.selected_user.city ? this.state.selected_user.city : 'United States of America'}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sub-title">{this.state.CampaignList.length ? t("Campaign List") : t("You don't have any draft campaign. Create one now.") } </div>
                                    <div className="flod-list">
                                        {this.state.CampaignList.map((campaign, index) => (
                                            <div className="flod-list-item" key={index}>
                                                <div className="flod-list-item-left">
                                                    <div className="form-group form-check">
                                                        <input type="checkbox" className="form-check-input share-check"
                                                               value={campaign.id}
                                                               onChange={(event) => this.checkCheckbox(event, 'checked_campaign')}
                                                               id="sharecheckbox" />
                                                        <label className="form-check-label compu_n"
                                                               htmlFor="sharecheckbox">{campaign.title}
                                                        </label>
                                                    </div>

                                                </div>
                                                <div className="flod-list-item-right">
                                                    <div className="flod-total">
                                                        {campaign.creator_count}
                                                        Creator(s)
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    {!this.state.CampaignList.length ?
                                        <a href={'/create-campaign'} type="button" className="btn orange-btn"><Trans>Create campaign</Trans></a>
                                        :
                                        <>
                                            <button type="button" className="btn gray-btn close-list-modal" data-dismiss="modal"><Trans>Cancel</Trans></button>
                                            <button type="button" className="btn orange-btn" onClick={() => this.saveCreatorToList('campaign')}><Trans>Save</Trans></button>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
    }
}

export default withTranslation() (withRouter(Profile));