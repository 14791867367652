import React from 'react';
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { withRouter } from "react-router-dom";

class Language extends React.Component{

    async selectLanguage(data) {
        localStorage.setItem("language", JSON.stringify(data));
        window.location.reload();
    }

    render() {
        let lang_name = "English";
        let language =  JSON.parse(localStorage.getItem('language'));
        if (language) {
            lang_name = language.name;
        }
        return (
            <div className="lang-wrapper">
                <div className="dropdown">
                    <a className="nav-link dropdown-arrow" href="#" id="navbardrop"
                       data-toggle="dropdown">
                        {lang_name} <span className="fa fa-angle-down"></span>
                    </a>
                    <div className="dropdown-menu s_language">
                        <a className="dropdown-item" href="#" onClick={() => this.selectLanguage({name:"English", value:"en"})}>English</a>
                        <a className="dropdown-item" href="#" onClick={() => this.selectLanguage({name:"Hindi", value:"hi"})}>Hindi</a>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Language);