import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Home from "../Components/Page/Home";
import Login from "../Components/Auth/Login";
import Signup from "../Components/Auth/Signup";
import SignupLocation from "../Components/Auth/SignupLocation";
import SignupCompany from "../Components/Auth/SignupCompany";
import Market from "../Components/Page/Market";
import Account from "../Components/Page/Account";
import Profile from "../Components/Page/Profile";
import createCampaign from "../Components/Page/createCampaign";
import CampaignCreated from "../Components/Page/CampaignCreated";
import Activity from "../Components/Page/Activity";
import Shortlist from "../Components/Page/Shortlist";
import CreatorList from "../Components/Page/CreatorList";
import CampaignDetail from "../Components/Page/CampaignDetail";
import CampaignReportingDetail from "../Components/Page/CampaignReportingDetail";

function Routes() {
    let routes = (
        <Switch>
            <Route path="/" component={Home} exact/>
            <Route path="/signup" component={Signup}/>
            <Route path="/login" component={Login}/>
            <Route path="/signup_location" component={SignupLocation}/>
            <Route path="/signup_company" component={SignupCompany}/>
            <Redirect to="/" />
        </Switch>
    );

    if(localStorage.getItem("user")) {
        routes = (
            <Switch>
                <Route path="/" component={Home} exact/>
                <Route path="/signup_location" component={SignupLocation} exact/>
                <Route path="/signup_company" component={SignupCompany} exact/>
                <Route path="/home" component={Market} exact/>
                <Route path="/activity" component={Activity} exact/>
                <Route path="/shortlist" component={Shortlist} exact/>
                <Route path="/creatorlist/:shortlist_id" component={CreatorList} exact/>
                <Route path="/campaignDetail/:campaign_id" component={CampaignDetail} exact/>
                <Route path="/reporting/:campaign_id" component={CampaignReportingDetail} exact/>
                <Route path="/account" component={Account} exact/>
                <Route path="/create-campaign" component={createCampaign} exact/>
                <Route path="/updateCampaign/:campaign_id" component={createCampaign} exact/>
                <Route path="/campaign-created" component={CampaignCreated} exact/>
                <Route path="/profile/:username" component={Profile} exact/>
                <Redirect to="/" />
            </Switch>
        );
    }
    return (
        <BrowserRouter>
            <div>
                {routes}
            </div>
        </BrowserRouter>

    );
}

export default Routes;