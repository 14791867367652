import React from 'react';
import {withRouter} from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { Trans } from "react-i18next";

class CampaignCreated extends React.Component {
    render() {
        return (
            <>
                <Header/>
                <div className="content-wrapper">
                    <div className="wrapper-inner">
                        <div className="container">
                            <div className="success-page-wrap private-created">
                                <div className="success-page-icon">
                                    <i className="fa fa-check"></i>
                                </div>
                                <div className="success-page-title"><Trans>Created successfully</Trans></div>
                                <div className="success-page-info"><p><Trans>The campaign has been created as a draft. You can add creators now.</Trans></p></div>
                                <div className="success-page-btn">
                                    <a href={'/activity'} type="button" className="btn gray-btn"><Trans>Campaign List</Trans></a>
                                    <a href={'/home'} className="btn orange-btn"><Trans>Add Creators</Trans></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </>
        )
    }
}

export default withRouter(CampaignCreated);