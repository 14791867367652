'use strict'
import React from 'react';
import logoImg from '../../Assets/images/logo.png';
import signUpImg from '../../Assets/images/signup-img.svg';
import {callAPI} from "../../Commen/CommenFunction";
import $ from 'jquery';
import { withRouter } from "react-router-dom";
import { Trans,withTranslation } from "react-i18next";
import Language from "../Layout/Language";

class SignupCompany extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            name: '',
            personal_name: '',
            personal_email: '',
            personal_phone: '',
            goal: '',
            spend_per_year: '',
            referred_by: '',
            loading:false,
            privacy_check:false,
            type:1
        };
    }

    async inputChangeHandler(event, key) {
        const value = event.target.value;

        this.setState(state => {
            return {
                ...state,
                [key]: value
            }
        })
    }

    async componentDidMount() {

    }

    async submitHandler(event) {
        event.preventDefault()
        let position = this.state.type == 1 ? 0 : 1;
        var formData =$('.needs-validation')[position];
        if (!formData.checkValidity()) {
            formData.classList.add('was-validated')
            return;
        }

        this.setState({
            loading:true
        });

        const form = new FormData()
        form.set('name', this.state.name)
        form.set('personal_name', this.state.personal_name)
        form.set('personal_email', this.state.personal_email)
        form.set('personal_phone', this.state.personal_phone)
        form.set('goal', this.state.goal)
        form.set('spend_per_year', this.state.spend_per_year)
        form.set('referred_by', this.state.referred_by)
        form.set('type', this.state.type)

        try {
            const response = await callAPI('auth/updateCompanyDetail', 'post', form,'application/json');

            if (response.status === true) {
                localStorage.setItem("user", JSON.stringify(response.data));
                window.location.href = '/home';
            } else {
                alert(response.message);
            }
            this.setState({
                loading:false
            });
        } catch (e) {
            console.log(e);
            this.setState({
                loading:false
            });
        }
    }

    registrationType(type){
        this.setState({
            type:type
        });
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <div className="signup-sec">
                    <div className="signup-left">
                        <div className="left-header"><a href="#_"><img src={logoImg} className="logo"
                                                                       alt="logo" /></a></div>
                        <div className="left-body">
                            <div className="left-body-title"><Trans>Find the right creators in Kurafat Creator Marketplace</Trans>
                            </div>
                            <div className="left-body-info"><Trans>The official platform for brand and creator collaborations on Kurafat</Trans>
                            </div>
                            <div className="left-body-img"><img src={signUpImg} className="register-logo"
                                                                alt="" /></div>
                        </div>
                        <div className="left-footer">
                            <div className="left-footer-main">@2021 Kurafat</div>
                            <div className="left-footer-tags">
                                <div className="tag"><a href="#_" target="_blank"><Trans>Brand Terms</Trans></a></div>
                                <div className="tag-split"></div>
                                <div className="tag"><a href="#_" target="_blank"><Trans>Privacy Policy</Trans></a></div>
                            </div>
                        </div>
                    </div>
                    <div className="signup-right">
                        <div className="signup-right-header">
                            <a href="login.html" className="login-text"><Trans>Log In</Trans></a>
                            <Language />
                            <a href="#" className="help"><i className="fa fa-question-circle-o"></i></a>
                        </div>
                        <div className="signup-form mt-5">
                            <h5 className="form-title"><Trans>Make a reservation now</Trans></h5>
                            <div className="step-info">
                                <Trans>kurafat Creator Marketplace is currently not available in your location. You can fill out your contact info and we'll notify you once we launch it in your region.</Trans>
                            </div>
                            <form method="post" className="needs-validation" noValidate={true} onSubmit={(event) => this.submitHandler(event)}>
                                            <div className="form-group">
                                                <label htmlFor="email"><Trans>Contact Name</Trans></label>
                                                <input type="text" name="contact name" id="contact"
                                                       value={this.state.personal_name}
                                                       onChange={(event) => this.inputChangeHandler(event, 'personal_name')}
                                                       className="form-control"
                                                       required={true}
                                                       placeholder={t("Contact name")}/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="email"><Trans>Email</Trans></label>
                                                <input type="email" name="email" id="email"
                                                       value={this.state.personal_email}
                                                       onChange={(event) => this.inputChangeHandler(event, 'personal_email')}
                                                       className="form-control"
                                                       required={true}
                                                       placeholder={t("Email")}/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="email"><Trans>Business name</Trans></label>
                                                <input type="text" name="business" id="business"
                                                       value={this.state.name}
                                                       onChange={(event) => this.inputChangeHandler(event, 'name')}
                                                       className="form-control"
                                                       required={true}
                                                       placeholder={t("Enter business name")}/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="name"><Trans>What is your main goal for this campaign?</Trans></label>

                                                <select required={true} name="country_code" onChange={(event) => this.inputChangeHandler(event, 'goal')} className="form-control" >
                                                    <option value="">{t("Select")}</option>
                                                    <option value="More brand exposure">{t("More brand exposure")}</option>
                                                    <option value="More website traffic">{t("More website traffic")}</option>
                                                    <option value="More exposure or followers for
                                                        your TikTok account">{t("More exposure or followers for your TikTok account")}</option>
                                                    <option value="MEncourage downloads">{t("Encourage downloads")}</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="name" className="compu_n"><Trans>How much do you spend per year working with creators?</Trans></label>
                                                <select name="country_code" onChange={(event) => this.inputChangeHandler(event, 'spend_per_year')} className="form-control" >
                                                    <option value="">{t("Select")}</option>
                                                    <option value="No spending so far">{t("No spending so far")}</option>
                                                    <option value=">0 - 5,000$">>0 - 5,000$</option>
                                                    <option value="5,001 – 10,000$">5,001 – 10,000$</option>
                                                    <option value="10,001 – 30,000$">10,001 – 30,000$</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="name" className="compu_n"><Trans>How did you know about us?</Trans></label>
                                                <select name="country_code" onChange={(event) => this.inputChangeHandler(event, 'referred_by')} className="form-control" >
                                                    <option value="">{t("Select")}</option>
                                                    <option value="Recommended by Kurafat staff">{t("Recommended by Kurafat staff")}</option>
                                                    <option value="Tiktok Ads platform">{t("Tiktok Ads platform")}</option>
                                                    <option value="Recommended by creators">{t("Recommended by creators")}</option>
                                                    <option value="Recommended by agencies">{t("Recommended by agencies")}</option>
                                                </select>
                                            </div>
                                            <div className="s_btn mb-5">
                                                <a href={'/signup_location'} className="btn gray-btn mr-1"><Trans>Back</Trans></a>
                                                <button type="submit" className="btn orange-btn"><Trans>Next</Trans></button>
                                            </div>
                                    </form>
                        </div>
                    </div>

                </div>
            </>
        )
    }
}

export default withTranslation() (withRouter(SignupCompany));