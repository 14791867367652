import React from 'react';
import {withRouter} from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import $ from "jquery";
import {callAPI, userData} from "../../Commen/CommenFunction";
import checkIcon from '../../Assets/images/check-icon.png';
import noDataIcon from '../../Assets/images/no-data.png';
import AddBrand from "./addBrand";
import {Trans, withTranslation} from "react-i18next";

class Account extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            certificate_number: '',
            company_name: '',
            ext_date: '',
            state_name: userData.state,
            address: '',
            zip_code: '',
            file_url: null,
            file: '',
            certificate_file_url: null,
            certificate_file: '',
            current_password: '',
            password: '',
            confirm_password: '',
            page:1,
            next_page:true,
            brands:[],
            selectedBrand:[],
            pageData:[],
            loading: false
        };
        this.fileChange = this.fileChange.bind(this);
    }

    async componentDidMount() {
        await this.getBrands(this.state.page)
    }

    async inputChangeHandler(event, key) {
        const value = event.target.value;

        this.setState(state => {
            return {
                ...state,
                [key]: value
            }
        })
    }

    async submitHandler(event) {
        event.preventDefault()
        var formData = $('.needs-validation')[0];
        if (!formData.checkValidity()) {
            formData.classList.add('was-validated')
            return;
        }

        this.setState({
            loading: true
        });

        const form = new FormData()
        form.set('registration_certificate', this.state.certificate_number)
        form.set('company_name', this.state.company_name)
        form.set('incorporation_date', this.state.ext_date)
        form.set('address', this.state.address)
        form.set('zip_code', this.state.zip_code)
        form.append(
            "file",
            this.state.file
        )
        form.append(
            "certificate_file",
            this.state.certificate_file
        )

        try {
            const response = await callAPI('auth/accountVerification', 'post', form, 'application/json');
            if (response.status === true) {
                localStorage.setItem("user", JSON.stringify(response.data));
                window.location.href = '/home';
            } else {
                this.setState({
                    errorMessage: response.message,
                    opensnack: true
                });
            }
            this.setState({
                loading: false
            });
        } catch (e) {
            this.setState({
                loading: false
            });
        }
    }

    async submitPasswordHandler(event) {
        event.preventDefault()
        var formData = $('.needs-validation-password')[0];
        if (!formData.checkValidity()) {
            formData.classList.add('was-validated')
            return;
        }

        this.setState({
            loading: true
        });

        if (this.state.password != this.state.confirm_password) {
            $('#re-password').addClass('is-invalid');
            $('#re-password-feedback').text("Password and confirm password does not match")
            return
        }

        const form = new FormData()
        form.set('current_password', this.state.current_password)
        form.set('password', this.state.password)
        try {
            const response = await callAPI('auth/changePassword', 'post', form, 'application/json');
            if (response.status === true) {
                this.props.history.push({
                    pathname: '/home',
                });
            } else {
                alert(response.message);
            }
            this.setState({
                loading: false
            });
        } catch (e) {
            this.setState({
                loading: false
            });
        }
    }

    fileChange(event, type = null) {
        let fileData = event.target.files[0];

        if (type) {
            this.setState({
                certificate_file_url: URL.createObjectURL(fileData),
                certificate_file: fileData
            });
        } else {
            this.setState({
                file_url: URL.createObjectURL(fileData),
                file: fileData
            });
        }
    }

    async getBrands(page) {
        try {
            const form = new FormData()
            form.set('page', page)

            const response = await callAPI('user/getBrandList', 'post', form, 'application/json');

            if (response.status === true) {
                this.setState({
                    pageData: response.data,
                    brands: response.data.data,
                });
            } else {
                this.setState({
                    brands: [],
                });
            }
            this.setState({
                loading: false
            });
        } catch (e) {
            console.log(e);
            this.setState({
                loading: false
            });
        }
    }

    async editBrand(brand) {
        this.setState({
            selectedBrand: brand
        });
    }
    async addBrand() {
        this.setState({
            selectedBrand: []
        });
    }
    render() {
        const { t } = this.props;
        return (
            <>
                <Header/>
                <div className="content-wrapper">
                    <div className="wrapper-inner">
                        <div className="container">
                            <div className="account d-flex">
                                <div className="star-nav nav-wrapper">
                                    <ul id="tabsJustified" className="nav nav-pills flex-column"
                                        data-options="deep_linking:true">
                                        <li className="nav-item">
                                            <a href="" data-target="#accountsetting" data-toggle="tab"
                                               className="nav-link active">
                                                <Trans>Account setting</Trans>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="" data-target="#changepassword" data-toggle="tab"
                                               className="nav-link">
                                                <Trans>Change password</Trans>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="" data-target="#verifybusiness" data-toggle="tab"
                                               className="nav-link">
                                                <Trans>Verify business</Trans>
                                            </a>
                                        </li>
                                        {userData.status == 1 &&
                                        <li className="nav-item">
                                            <a href="" data-target="#brand" data-toggle="tab" className="nav-link">
                                                <Trans>My Brand</Trans>
                                            </a>
                                        </li>
                                        }
                                    </ul>
                                </div>
                                <div className="tab-content w-100">
                                    <div id="accountsetting" className="tab-pane tab-content-box fade active show">
                                        <div className="title-wrapper panel-title"><Trans>Account settings</Trans></div>
                                        <div className="card-panel-body">
                                            <div className="card-body-section">
                                                <h4 className="setting-group-title"><Trans>General</Trans></h4>
                                                <div className="setting-item-wrap">
                                                    <div className="setting-item-title"><Trans>Business name</Trans></div>
                                                    <div className="setting-item-config">{userData.name}</div>
                                                    <div className="edit-account-setting"><a href="#_"
                                                                                             data-toggle="modal"
                                                                                             data-target="#businessName"><i
                                                        className="fa fa-pencil"></i></a></div>
                                                </div>
                                                <div className="setting-item-wrap">
                                                    <div className="setting-item-title"><Trans>Contact name</Trans></div>
                                                    <div className="setting-item-config">{userData.personal_name}</div>
                                                    <div className="edit-account-setting"><a href="#_"><i
                                                        className="fa fa-pencil"></i></a></div>
                                                </div>
                                            </div>
                                            <div className="card-body-section">
                                                <h4 className="setting-group-title"><Trans>Contact information</Trans></h4>
                                                <div className="setting-item-wrap">
                                                    <div className="setting-item-title"><Trans>Email</Trans></div>
                                                    <div className="setting-item-config">{userData.personal_email}</div>
                                                    <div className="edit-account-setting"><a href="#_"><i
                                                        className="fa fa-pencil"></i></a></div>
                                                </div>
                                                <div className="setting-item-wrap">
                                                    <div className="setting-item-title"><Trans>Phone number</Trans></div>
                                                    <div className="setting-item-config">{userData.personal_phone}</div>
                                                    <div className="edit-account-setting"><a href="#_"><i
                                                        className="fa fa-pencil"></i></a></div>
                                                </div>
                                                <div className="setting-item-wrap">
                                                    <div className="setting-item-title"><Trans>Contact name</Trans></div>
                                                    <div className="setting-item-config">{userData.personal_name}</div>
                                                    <div className="edit-account-setting"><a href="#_">Copy</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="changepassword" className="tab-pane tab-content-box fade">
                                        <div className="title-wrapper panel-title"><Trans>Change password</Trans></div>
                                        <div className="card-panel-body">
                                            <form method="post" className="needs-validation-password" noValidate={true}
                                                  onSubmit={(event) => this.submitPasswordHandler(event)}>
                                                <div className="form-group">
                                                    <label htmlFor="verification code"><Trans>Current password</Trans></label>
                                                    <input type="password" name="password" id="password"
                                                           value={this.state.current_password}
                                                           onChange={(event) => this.inputChangeHandler(event, 'current_password')}
                                                           required={true}
                                                           className="form-control"
                                                           placeholder={t("Enter your current password")}/>
                                                    <div className="invalid-feedback">
                                                        <Trans>Please enter a current password</Trans>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="verification code"><Trans>New password</Trans></label>
                                                    <input type="password" name="password" id="new-password"
                                                           value={this.state.password}
                                                           onChange={(event) => this.inputChangeHandler(event, 'password')}
                                                           required={true}
                                                           className="form-control" placeholder={t("Enter a new password")}/>
                                                    <div className="invalid-feedback">
                                                        <Trans>Please enter a password</Trans>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="verification code"><Trans>Re-enter password</Trans></label>
                                                    <input type="password" name=" password" id="re-password"
                                                           value={this.state.confirm_password}
                                                           onChange={(event) => this.inputChangeHandler(event, 'confirm_password')}
                                                           required={true}
                                                           className="form-control" placeholder={t("Confirm new Password")}/>
                                                    <div id="re-password-feedback" className="invalid-feedback">
                                                        <Trans>Please enter a confirm password</Trans>
                                                    </div>
                                                </div>
                                                <button className="btn orange-btn" type="submit"><Trans>Change password</Trans>
                                                </button>
                                                <a href="#_" className="forgot_password"><Trans>Forgot password?</Trans></a>
                                            </form>
                                        </div>
                                    </div>
                                    <div id="verifybusiness" className="tab-pane tab-content-box fade">
                                        <div className="qualification-info">
                                            {userData.status == 0 &&
                                            <>
                                                <div className="form-header">
                                                    <div className="title-wrapper panel-title"><Trans>Activate the account</Trans>
                                                    </div>
                                                    <div className="header-name"><Trans>Business name</Trans>:&nbsp;{userData.name}</div>
                                                </div>
                                                <div className="qualification-form-wrapper">
                                                    <form method="post" className="needs-validation" noValidate={true}
                                                          onSubmit={(event) => this.submitHandler(event)}>
                                                        <div className="form-group">
                                                            <label htmlFor="number"><Trans>Business In-Corporation Number</Trans></label>
                                                            <input type="text" name="number" id="number"
                                                                   className="form-control"
                                                                   value={this.state.certificate_number}
                                                                   onChange={(event) => this.inputChangeHandler(event, 'certificate_number')}
                                                                   required={true}
                                                                   placeholder={t("Business In-Corporation Number")}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="certification"><Trans>In-Corporation Credit Code Certificate</Trans></label>
                                                            <div className="file-uploader upload-wrap">
                                                                <div className="upload-view-file">
                                                                    <div className="file-upload-btn">
                                                                        <i className="fa fa-upload"></i>
                                                                        <input type="file" name="file"
                                                                               onChange={this.fileChange}
                                                                               accept=".jpg,.png,.jpeg,.bmp"/>
                                                                    </div>
                                                                    {this.state.file_url &&
                                                                    <img className="file_url_file"
                                                                         src={this.state.file_url} alt="File URL"/>
                                                                    }
                                                                </div>
                                                                <div className="file-uploader-info"><Trans>Please upload a full color scan of valid qualifications Images must be JPG, PNG, or BMP format and less than 1,000KB.</Trans>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="name"><Trans>Company name</Trans></label>
                                                            <input type="text" name="name" id="companyname"
                                                                   className="form-control input-text"
                                                                   value={this.state.company_name}
                                                                   onChange={(event) => this.inputChangeHandler(event, 'company_name')}
                                                                   required={true}
                                                                   placeholder={t("Please fill in your official full name in the industrial and commercial registration")}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="date"><Trans>Registration date</Trans></label>
                                                            <input type="date" name="date" id="date"
                                                                   value={this.state.ext_date}
                                                                   onChange={(event) => this.inputChangeHandler(event, 'ext_date')}
                                                                   required={true}
                                                                   className="form-control"
                                                                   placeholder={t("Registration date")}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="country" className="compu_n"><Trans>Business country/region</Trans></label>
                                                            <input type="text" name="country" id="countryname"
                                                                   className="form-control" placeholder="Abc"
                                                                   value={this.state.state_name}
                                                                   disabled="disabled" autoComplete="off"/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="address"><Trans>Company address</Trans></label>
                                                            <input type="text" name="address" id="companyaddress"
                                                                   className="form-control"
                                                                   value={this.state.address}
                                                                   onChange={(event) => this.inputChangeHandler(event, 'address')}
                                                                   required={true}
                                                                   placeholder={t("Please enter  your company address")}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="code"><Trans>Zip code</Trans></label>
                                                            <input type="text" name="code" id="code"
                                                                   className="form-control"
                                                                   value={this.state.zip_code}
                                                                   onChange={(event) => this.inputChangeHandler(event, 'zip_code')}
                                                                   placeholder={t("Please enter your Zip code")}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="certification"><Trans>Company verification Certificate</Trans></label>
                                                            <div className="file-uploader upload-wrap">
                                                                <div className="upload-view-file">
                                                                    <div className="file-upload-btn">
                                                                        <i className="fa fa-upload"></i>
                                                                        <input type="file" name="file"
                                                                               onChange={(event) => this.fileChange(event, 'certificate')}
                                                                               accept=".jpg,.png,.jpeg,.bmp"/>
                                                                    </div>
                                                                    {this.state.certificate_file_url &&
                                                                    <img className="file_url_file"
                                                                         src={this.state.certificate_file_url}
                                                                         alt="File URL"/>
                                                                    }
                                                                </div>
                                                                <div className="file-uploader-info"><Trans>Upload a color image to verify your company, such as a Business License or Business Tax Registration Certificate (BTRC). Images must be JPG, PNG, or BMP format and less than 1,000KB.</Trans>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-btns">
                                                            <a href="#_" className="btn gray-btn"
                                                               data-dismiss="modal"><Trans>Quit</Trans></a>
                                                            <button type="submit" className="btn orange-btn"><Trans>Submit verification</Trans>
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </>
                                            }
                                            {userData.status == 2 &&
                                            <p><Trans>Your Profile in Under Approval</Trans></p>
                                            }
                                            {userData.status == 1 &&
                                                <>
                                                    <div class="title-wrapper panel-title"><Trans>Qualification management</Trans></div>
                                                <div class="card-panel-body">
                                                    <div class="information-card status-complete">
                                                        <div class="left-container">
                                                            <img src={checkIcon} alt="img" />
                                                        </div>
                                                        <div class="right-container">
                                                            <div class="status-title"><Trans>You're verified!</Trans></div>
                                                        </div>
                                                    </div>
                                                    <div class="qualification-item">
                                                        <div class="item-key"><Trans>Business country/region</Trans></div>
                                                        <div>{userData.state}</div>
                                                    </div>
                                                    <div class="qualification-item">
                                                        <div class="item-key"><Trans>Unified Social Credit Code Certificate Number</Trans></div>
                                                        <div>{userData.incorporation_number}</div>
                                                    </div>
                                                    <div class="qualification-item">
                                                        <div class="item-key"><Trans>Company address</Trans></div>
                                                        <div>{userData.address}</div>
                                                    </div>
                                                    <div class="qualification-item">
                                                        <div class="item-key"><Trans>Zip code</Trans></div>
                                                        <div>{userData.pincode}</div>
                                                    </div>
                                                    <div class="qualification-item">
                                                        <div class="item-key"><Trans>Business name</Trans></div>
                                                        <div>{userData.company_name}</div>
                                                    </div>
                                                    <div class="qualification-item">
                                                        <div class="item-key"><Trans>Expiration date</Trans></div>
                                                        <div>{userData.incorporation_date}</div>
                                                    </div>
                                                    <div class="qualification-item">
                                                        <div class="item-key"><Trans>certificate</Trans></div>
                                                        <div><a href="#_" class="qualification-item-link">{userData.registration_certificate}</a></div>
                                                    </div>

                                                </div>
                                                </>
                                            }

                                        </div>
                                    </div>
                                    <div id="brand" className="tab-pane tab-content-box fade">
                                        <div className="brand-bread tab-content-box">
                                            <div className="brand-bread-title"><Trans>My brands</Trans></div>
                                            <div className="brand-bread-btn">
                                                <button type="button" data-toggle="modal" data-target="#addBrand" onClick={() => this.addBrand()}
                                                        className="btn orange-btn"><Trans>Add Brand</Trans>
                                                </button>
                                            </div>
                                        </div>
                                        {this.state.brands.length  ?
                                            this.state.brands.map((brand, index) => (
                                                <div className="brand-items tab-content-box" key={index}>
                                                    <div className="left">
                                                        <div className="brand-item-name">{brand.name}</div>
                                                    </div>
                                                    <div className="right">
                                                        <a href="#_" className="delete-icon"><i
                                                            className="fa fa-trash-o"></i></a>
                                                        <a href="#_" onClick={() => this.editBrand(brand)} className="edit-icon" data-toggle="modal"
                                                           data-target="#addBrand"><i className="fa fa-pencil"></i></a>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            <div className="tab-content-box">
                                                <div className="no-data">
                                                    <img src={noDataIcon} alt="img" />
                                                    <p className="desc"><Trans>Create your first brand for promotion now</Trans></p>
                                                    <a href="#_" data-toggle="modal" data-target="#addBrand" className="btn orange-btn"><Trans>Add Brand</Trans></a>
                                                </div>
                                            </div>
                                        }
                                        <AddBrand brand={this.state.selectedBrand} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </>
        )
    }
}

export default withTranslation() (withRouter(Account));