import React from 'react';
import logoImg from '../../Assets/images/logo.png';
import {withRouter} from "react-router-dom";
import {userData} from "../../Commen/CommenFunction";
import { Trans } from "react-i18next";
import Language from "./Language";

class Header extends React.Component{

    logout() {
        localStorage.clear();
        window.location.reload();
    }
    render() {
        let pathname = window.location.pathname;
        return (
            <header className={userData ? "header" : "header is-transparent"} id="banner">
                {userData ?
                    <div className="nav-header">
                        <div className="nav-header-inner">
                            <div className="nav-left">
                                <a href={'/'} className="logo"><img src={logoImg} alt="logo"/></a>
                                <div className="header-menu">
                                    <ul className="header-menu-inner">
                                        <li className={pathname == '/activity' ? "header-menu-item active" : "header-menu-item"}>
                                            <div className="nav-label">
                                                <a href={'/activity'}><Trans>My activity</Trans></a>
                                            </div>
                                        </li>
                                        <li className={pathname == '/home' ? "header-menu-item active" : "header-menu-item"}>
                                            <div className="nav-label">
                                                <a href={'/home'}><Trans>Creator search</Trans></a>
                                            </div>
                                        </li>
                                        <li className={pathname == '/shortlist' ? "header-menu-item active" : "header-menu-item"}>
                                            <div className="nav-label">
                                                <a href={'/shortlist'}><Trans>Shortlist</Trans></a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="nav-right">
                                <div className="campaign_btn right-item">
                                    <a href={"/create-campaign"} className="btn orange-btn"><Trans>Create campaign</Trans></a>
                                </div>
                                <div className="lang-wrapper right-item">
                                    <Language />
                                </div>
                                <div className="notific-wrapper right-item">
                                    <div className="nav-item dropdown">
                                        <a className="nav-link" href="#" id="navbarDropdown" role="button"
                                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fa fa-bell-o"></i>
                                        </a>
                                        <div className="dropdown-menu" role="menu">
                                            <div className="notific-container">
                                                <div className="notific-head"><span><Trans>Notifications</Trans></span></div>
                                                <div className="notific-box">
                                                    <img src="images/notification-empty.svg" className="empty-img"
                                                         alt="icon"/>
                                                    <small className="empty-message"><Trans>No new updates</Trans></small>
                                                </div>
                                                <div className="notific-footer"><a href="#_"><Trans>View all</Trans></a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="help-wrapper right-item">
                                    <div className="nav-item dropdown">
                                        <a className="nav-link" href="#" id="navbarDropdownhelp" role="button"
                                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fa fa-question-circle-o"></i>
                                        </a>
                                        <div className="dropdown-menu">
                                            <div className="help-container">
                                                <ul className="help-list">
                                                    <li className="help-item"><a href="#_" className="help-link"><Trans>Help center</Trans></a>
                                                    </li>
                                                    <li className="help-item"><a href="#_" className="help-link"><Trans>Get support</Trans></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="user-wrapper right-item">
                                    <div className="nav-item dropdown">
                                        <a className="nav-link" href="#" id="navbarDropdownhuser" role="button"
                                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                              <span className="avatar avatar-online">
                                                    <img src={logoImg} alt={userData.name}/>
                                              </span>
                                        </a>
                                        <div className="dropdown-menu">
                                            <div className="user-container">
                                                <div className="account-info">
                                                    <div className="account-name">{userData.name}</div>
                                                    <div className="account-active"><Trans>Activate the account</Trans></div>
                                                </div>
                                                <div className="seperator"></div>
                                                <div className="account-item"><a href={'/account'}><Trans>Account
                                                    settings</Trans></a></div>
                                                <div className="account-item"><a
                                                    href={'/account'}><Trans>Qualification</Trans></a></div>
                                                <div className="seperator"></div>
                                                <div className="account-item text-black"><a href="#" onClick={() =>this.logout()}><Trans>Log out</Trans></a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="nav-header">
                        <div className="nav-header-inner">
                            <div className="nav-left">
                                <a href={'/'} className="logo"><img src={logoImg} alt="logo"/></a>
                            </div>
                            <div className="nav-right">
                                <div className="login right-item"><a href={'/login'}><Trans>Log in</Trans></a></div>
                                <div className="create_btn right-item">
                                    <a href={'/signup'} className="btn orange-btn"><Trans>Create account</Trans></a>
                                </div>
                                <Language />
                            </div>
                        </div>
                    </div>
                }

            </header>
        )
    }

}

export default withRouter(Header);