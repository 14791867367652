import React from 'react';
import {withRouter} from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import {callAPI, userData} from "../../Commen/CommenFunction";
import toolTipIcon from '../../Assets/images/tooltip.png';
import $ from "jquery";
import {Trans, withTranslation} from "react-i18next";

class CampaignReportingDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reporting: [],
            posts: []
        };
    }

    async componentDidMount() {
        let campaign_id = this.props.match.params.campaign_id;
        if (!campaign_id) {
            window.location.href = '/activity';
        }
        await this.CampaignDetail()
        $('.rdt_TableHeader').remove();
    }

    async CampaignDetail() {
        try {
            const form = new FormData()
            form.set('id', this.props.match.params.campaign_id)

            const response = await callAPI('user/campaignReportingDetail', 'post', form, 'application/json');
            if (response.status === true) {
                this.setState({
                    reporting: response.data,
                    posts: response.data.posts,
                });
            } else {
                this.setState({
                    reporting: [],
                    posts: [],
                });
            }
            this.setState({
                loading: false
            });
        } catch (e) {
            this.setState({
                loading: false
            });
        }
    }

    render() {
        const {t} = this.props;
        return (
            <>
                <Header/>
                <div className="content-wrapper">
                    <div className="wrapper-inner">
                        <div className="container">
                            <div className="tab-content-box">
                                <div className="title-wrapper panel-title"><Trans>Campaign Reporting</Trans>
                                    <span className="reporting-sub">Updated as of {this.state.reporting.updated_at}</span>
                                </div>
                                <div>
                                  <span className="k-info-item mr-5">
                                      <Trans>Campaign ID</Trans>: 6881516778124476417
                                  </span><span className="k-info-item">
                                    <Trans>Creation time</Trans>: {this.state.reporting.created_at}
                                  </span>
                                </div>
                                <div className="kstat-items">
                                    <div className="kstat-item view">
                                        <h2>{this.state.reporting.view_count}</h2>
                                        <p><Trans>Total Views</Trans></p>
                                    </div>
                                    <div className="kstat-item like">
                                        <h2>{this.state.reporting.like_count}</h2>
                                        <p><Trans>Total Likes</Trans></p>
                                    </div>
                                    <div className="kstat-item comment">
                                        <h2>{this.state.reporting.comment_count}</h2>
                                        <p><Trans>Total Comments</Trans></p>
                                    </div>
                                    <div className="kstat-item share">
                                        <h2>{this.state.reporting.share_count}</h2>
                                        <p><Trans>Total Shares</Trans></p>
                                    </div>
                                    <div className="kstat-item engagement">
                                        <h2>1.83%</h2>
                                        <p><Trans>Average Engagement Rate</Trans></p>
                                    </div>
                                    <div className="kstat-item videos">
                                        <h2>{this.state.reporting.video_count}</h2>
                                        <p><Trans>Number of videos</Trans></p>
                                    </div>
                                    <div className="kstat-item creator">
                                        <h2>{this.state.reporting.creator_count}</h2>
                                        <p><Trans>Number of creators</Trans></p>
                                    </div>
                                </div>
                                <div className="video-detail-tab">
                                    <ul className="nav nav-tabs">
                                        <li className="nav-item"><a className="nav-link active" data-toggle="tab"
                                                                    href="#videos"><Trans>Videos</Trans></a></li>

                                    </ul>
                                    <a className="kexport-data">
                                        <Trans>Export data</Trans>
                                    </a>
                                </div>

                            </div>
                            <div className="kvideo-items">
                                <div id="videos" className="tab-pane active">
                                    <div className="k-table">
                                        <div className="k-table-header">
                                            <table cellSpacing="0" cellPadding="0" border="0" className="k-tableheader"
                                                   style={{width: "1672px;"}}>
                                                <colgroup>
                                                    <col name="k-table-column1" width="126" />
                                                        <col name="k-table-column2" width="164"/>
                                                            <col name="k-table-column3" width="122"/>
                                                                <col name="k-table-column4" width="80"/>
                                                                    <col name="k-table-column5" width="100"/>
                                                                        <col name="k-table-column6" width="80"/>
                                                                            <col name="k-table-column7" width="150"/>
                                                                                <col name="k-table-column8" width="162"/>
                                                                                    <col name="k-table-column9"
                                                                                         width="122"/>
                                                                                        <col name="k-table-column10"
                                                                                             width="122"/>
                                                                                            <col name="k-table-column11"
                                                                                                 width="122"/>
                                                                                                <col
                                                                                                    name="k-table-column12"
                                                                                                    width="162"/>
                                                                                                    <col
                                                                                                        name="k-table-column13"
                                                                                                        width="80"/>
                                                                                                        <col
                                                                                                            name="k-table-column14"
                                                                                                            width="80" />
                                                                                                            <col
                                                                                                                name="gutter"
                                                                                                                width="0"/>
                                                </colgroup>
                                                <thead className="has-gutter">
                                                <tr className="">
                                                    <th colSpan="1" rowSpan="1"
                                                        className="k-table-column1 first-td is-hidden is-leaf">
                                                        <div className="cell"><Trans>Video</Trans></div>
                                                    </th>
                                                    <th colSpan="1" rowSpan="1"
                                                        className="k-table-column2  border-right is-hidden is-leaf">
                                                        <div className="cell"><Trans>Time published</Trans></div>
                                                    </th>
                                                    <th colSpan="1" rowSpan="1"
                                                        className="k-table-column3  first-td  is-leaf">
                                                        <div className="cell"><Trans>Total Views</Trans></div>
                                                    </th>
                                                    <th colSpan="1" rowSpan="1" className="k-table-column4  is-leaf">
                                                        <div className="cell"><Trans>Likes</Trans></div>
                                                    </th>
                                                    <th colSpan="1" rowSpan="1" className="k-table-column5  is-leaf">
                                                        <div className="cell"><Trans>Comments</Trans></div>
                                                    </th>
                                                    <th colSpan="1" rowSpan="1" className="k-table-column6  is-leaf">
                                                        <div className="cell"><Trans>Shares</Trans></div>
                                                    </th>
                                                    <th colSpan="1" rowSpan="1"
                                                        className="k-table-column7 border-right  is-leaf">
                                                        <div className="cell"><Trans>Average Engagement Rate</Trans></div>
                                                    </th>
                                                    <th colSpan="1" rowSpan="1"
                                                        className="k-table-column8   first-td  is-leaf">
                                                        <div className="cell"><Trans>Top market</Trans></div>
                                                    </th>
                                                    <th colSpan="1" rowSpan="1"
                                                        className="k-table-column9   top-item  is-leaf">
                                                        <div className="cell"><Trans>Top gender</Trans></div>
                                                    </th>
                                                    <th colSpan="1" rowSpan="1"
                                                        className="k-table-column10   top-item  is-leaf">
                                                        <div className="cell">
                                                            <div>
                                                                <div className="age-alert-tip">
                                                                    <span><Trans>Top age range</Trans></span>
                                                                    <span>
			  <div className="age-alert-tip-inner" style={{marginLeft:"4px", width:"10px", height:"10px"}}
                   data-toggle="tooltip" data-placement="bottom"
                   title={t("For security reasons, percentage calculation only includes users age 18 and older.")}>
				<img src={toolTipIcon} alt="info" className="age-alert--image"
                     style={{width:"10px", height:"10px"}} />
			  </div>
			</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th colSpan="1" rowSpan="1"
                                                        className="k-table-column11 top-item border-right is-leaf">
                                                        <div className="cell"><Trans>Top device</Trans></div>
                                                    </th>
                                                    <th colSpan="1" rowSpan="1"
                                                        className="k-table-column12 first-td is-leaf">
                                                        <div className="cell"><Trans>Cost</Trans></div>
                                                    </th>
                                                    <th colSpan="1" rowSpan="1"
                                                        className="k-table-column13 is-leaf">
                                                        <div className="cell"><Trans>CPV</Trans></div>
                                                    </th>
                                                    <th colSpan="1" rowSpan="1"
                                                        className="k-table-column14 is-leaf">
                                                        <div className="cell"><Trans>CPE</Trans></div>
                                                    </th>
                                                    <th className="gutter" style={{width:"0px", display:"non                                                                                           e"}}></th>
                                                </tr>
                                                </thead>
                                            </table>
                                        </div>
                                        <div className="k-table-body">
                                            <table cellSpacing="0" cellPadding="0" border="0" className="k-tablebody"
                                                   style={{width:"1672px"}}>
                                                <colgroup>
                                                    <col name="k-table-column1" width="126" />
                                                        <col name="k-table-column2" width="164" />
                                                            <col name="k-table-column3" width="122" />
                                                                <col name="k-table-column4" width="80" />
                                                                    <col name="k-table-column5" width="100" />
                                                                        <col name="k-table-column6" width="80" />
                                                                            <col name="k-table-column7" width="150" />
                                                                                <col name="k-table-column8" width="162" />
                                                                                    <col name="k-table-column9"
                                                                                         width="122" />
                                                                                        <col name="k-table-column10"
                                                                                             width="122" />
                                                                                            <col name="k-table-column11"
                                                                                                 width="122" />
                                                                                                <col
                                                                                                    name="k-table-column12"
                                                                                                    width="162" />
                                                                                                    <col
                                                                                                        name="k-table-column13"
                                                                                                        width="80" />
                                                                                                        <col
                                                                                                            name="k-table-column14"
                                                                                                            width="80" />
                                                </colgroup>
                                                <tbody>
                                                <tr className="k-table-row">
                                                    <td rowSpan="1" colSpan="1"
                                                        className="k-table-column1  first-td is-hidden">
                                                        <div className="cell">
                                                            <a className="video-poster">
                                                                <img src="images/img2.jpg" height="72" width="72"
                                                                     alt="" />
                                                            </a>
                                                        </div>
                                                    </td>
                                                    <td rowSpan="1" colSpan="1"
                                                        className="k-table-column2  border-right is-hidden">
                                                        <div className="cell">
                                                            <div>
                                                                <img width="20" height="20" src="images/img3.jpeg"
                                                                     alt=""
                                                                     className="avator" />
                                                                    <span className="nick-name">Melody Chu</span>
                                                            </div>
                                                            <div className="meta">10/12/2020</div>
                                                        </div>
                                                    </td>
                                                    <td rowSpan="1" colSpan="1" className="k-table-column3 first-td">
                                                        <div className="cell">6.8k</div>
                                                    </td>
                                                    <td rowSpan="1" colSpan="1" className="k-table-column4">
                                                        <div className="cell">118</div>
                                                    </td>
                                                    <td rowSpan="1" colSpan="1" className="k-table-column5">
                                                        <div className="cell">5</div>
                                                    </td>
                                                    <td rowSpan="1" colSpan="1" className="k-table-column6">
                                                        <div className="cell">1</div>
                                                    </td>
                                                    <td rowSpan="1" colSpan="1"
                                                        className="k-table-column7 border-right">
                                                        <div className="cell">1.82%</div>
                                                    </td>
                                                    <td rowSpan="1" colSpan="1" className="k-table-column8  first-td">
                                                        <div className="cell">
			 <span>
			  <span>SA</span> <span className="meta">9.27%</span>
			 </span>
                                                        </div>
                                                    </td>
                                                    <td rowSpan="1" colSpan="1" className="k-table-column9  top-item">
                                                        <div className="cell"><span>
          female
        </span><span className="meta">
          50.40%
        </span></div>
                                                    </td>
                                                    <td rowSpan="1" colSpan="1" className="k-table-column10  top-item">
                                                        <div className="cell"><span>25-34</span><span className="meta">
          44.94%
        </span></div>
                                                    </td>
                                                    <td rowSpan="1" colSpan="1"
                                                        className="k-table-column11  top-item border-right">
                                                        <div className="cell"><span>
          android
        </span><span className="meta">
          83.07%
        </span></div>
                                                    </td>
                                                    <td rowSpan="1" colSpan="1" className="k-table-column12  first-td">
                                                        <div className="cell"><span>
          $
        </span><span className="meta"><span>
            10.00
          </span></span></div>
                                                    </td>
                                                    <td rowSpan="1" colSpan="1" className="k-table-column13  ">
                                                        <div className="cell"><span>
          $
        </span><span className="meta">
          0.00
        </span></div>
                                                    </td>
                                                    <td rowSpan="1" colSpan="1" className="k-table-column14  ">
                                                        <div className="cell"><span>
          $
        </span><span className="meta">
          0.08
        </span></div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-content-box k-pagination">
                                <div className="r-pagination">
                                    <nav aria-label="Page navigation example">
                                        <ul className="pagination justify-content-center">
                                            <li className="page-item disabled">
                                                <a className="page-link" href="#" tabIndex="-1">Previous</a>
                                            </li>
                                            <li className="page-item"><a className="page-link" href="#">1</a></li>
                                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">Next</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </>
    )
    }
    }

    export default withTranslation()(withRouter(CampaignReportingDetail));